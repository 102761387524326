import React from "react";
import { Typography, Container, Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
// import iconFcr from "../assets/icons/icon-fcr.png";
import iconEmploymentOntario from "../assets/icons/icon-employmentOntario.png";
import iconCwb from "../assets/icons/icon-cwb.png";
import iconOntario from "../assets/icons/icon-ontario.png";
import iconCanada from "../assets/icons/Canada_wordmark.svg";
// import goc from "../assets/icons/govOfCanada.png";
import { useTranslation, Trans } from "react-i18next";

const footers = [
  {
    title: "About CWB",
    link: "/about-cwb-welders-canada",
  },
  {
    title: "Contact Us",
    link: "/contact-us",
  },
  // {
  //   title: "FAQ",
  //   link: "/welders-work-canada-faq",
  // },
  {
    title: "Privacy Policy",
    link: "/privacy",
  },
  {
    title: "Terms & Conditions",
    link: "/terms-and-conditions",
  },
];

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const isXS = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
      <footer className={classes.footerContainer}>
        <div className={classes.footerLinksWrapper}>
          <Container
            maxWidth="xl"
            component="footer"
            className={classes.footer}
          >
            {footers.map((footer, idx) => (
              <Link to={footer.link} className={classes.link} key={footer.title}>
                <Typography variant="h5" color="textSecondary">
                  {t(`footer.title${idx + 1}`)}
                </Typography>
              </Link>
            ))}
          </Container>
          <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="body1" color="textSecondary" gutterBottom fontWeight="bold">
              {t("footer.copyrightA")} {new Date().getFullYear()} {t("footer.copyrightB")}
            </Typography>
          </Box>
        </div>
        <Container maxWidth="xl" className={classes.footerGrid}>
          <Grid
            container
            //spacing={2}
            justifyContent="center"
            alignItems="center"
            style={{ margin: 0, width: "100%" }}
            gap={isXS ? 3 : 0}
          >
            <Grid item xs={3} pl={isMobile ? 1 : 5} pr={isMobile ? 1 : 5} display="flex" justifyContent="center" alignItems="center">
              <img
                src={iconCwb}
                alt={t("footer.icontAlt1")}
                className={classes.footerLogo}
                width={isMobile ? "100%" : "80%"}
              ></img>
            </Grid>
            <Grid item xs={3} pl={isMobile ? 1 : 5} pr={isMobile ? 1 : 5} display="flex" justifyContent="center" alignItems="center">
              <img
                src={iconCanada}
                alt={t("footer.iconAlt3")}
                className={classes.footerLogo}
                width={isMobile ? "100%" : "80%"}
              ></img>
            </Grid>
            <Grid item xs={3} pl={isMobile ? 1 : 5} pr={isMobile ? 1 : 5} display="flex" justifyContent="center" alignItems="center">
              <img
                src={iconEmploymentOntario}
                alt={t("footer.iconAlt2")}
                className={classes.footerLogo}
                width={isMobile ? "100%" : "80%"}
              ></img>
            </Grid>
            <Grid item xs={3} pl={isMobile ? 1 : 5} pr={isMobile ? 1 : 5} display="flex" justifyContent="center" alignItems="center">
              <img
                src={iconOntario}
                alt={t("footer.iconAlt3")}
                className={classes.footerLogo}
                width={isMobile ? "100%" : "80%"}
              ></img>
            </Grid>
          </Grid>
        </Container>
      </footer>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  footerLinksWrapper: {
    backgroundColor: theme.palette.secondary.main,
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    borderTop: `1px solid ${theme.palette.divider}`,
    // marginTop: theme.spacing(8),
    // paddingTop: theme.spacing(3),
    // paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      //   paddingTop: theme.spacing(6),
      //   paddingBottom: theme.spacing(6),
    },
  },
  footerContainer: {
    padding: theme.spacing(0, 0, 6, 0),
    // marginBottom: theme.spacing(8),
  },
  link: {
    display: "flex",
    textDecoration: "none",
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0, 2),
    color: "white",
    borderRight: `2px solid ${theme.palette.primary.main}`,
    "&:last-child": {
      borderRight: "none",
    },
    "&:hover": {
      textDecoration: "underline",
      color: theme.palette.primary.light,
    },
  },
  footerGrid: {
    padding: theme.spacing(4, 0, 0, 0)
  },
  footerLogo: {
    height: "auto",
    maxWidth: "100%",
  },
  logoContainer: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}));

export default Footer;
