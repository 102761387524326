import React, { useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm, Form } from "../../components/useForm";
import { useAuth } from "../../components/useAuth";
import { useTheme } from "@mui/material/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  confirmPassword: "",
  province: "",
  gender: "",
  designated_groups: [],
  agreeTerms: false,
  optInEmail: false,
  age: "",
};

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    textTransform: "none",
    borderRadius: "0.8rem",
  },
}));

const SignupForm = (props) => {
  const classes = useStyles();
  const { setOpenPopup, setOpenSignupConfirm } = props;
  const { setShowSetupProfile } = props;
  const { signup } = useAuth();
  const [submitting, setSubmitting] = useState(false);
  const [formError, setFormError] = useState("");
  let location = useLocation();
  let navigate = useNavigate();
  const { t } = useTranslation(["common", "error"]);
  const { values, setValues, errors, setErrors, handleInputChange } =
    useForm(initialValues);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const theme = useTheme();

  const provinceData = [
    {
      id: "CA-AB",
      label: t("loginForm.fields.provinces.AB"),
      code: "CA-AB",
    },
    {
      id: "CA-BC",
      label: t("loginForm.fields.provinces.BC"),
      code: "CA-BC",
    },
    {
      id: "CA-MB",
      label: t("loginForm.fields.provinces.MB"),
      code: "CA-MB",
    },
    {
      id: "CA-NB",
      label: t("loginForm.fields.provinces.NB"),
      code: "CA-NB",
    },
    {
      id: "CA-NL",
      label: t("loginForm.fields.provinces.NL"),
      code: "CA-NL",
    },
    {
      id: "CA-NS",
      label: t("loginForm.fields.provinces.NS"),
      code: "CA-NS",
    },
    {
      id: "CA-NT",
      label: t("loginForm.fields.provinces.NT"),
      code: "CA-NT",
    },
    {
      id: "CA-NU",
      label: t("loginForm.fields.provinces.NU"),
      code: "CA-NU",
    },
    {
      id: "CA-ON",
      label: t("loginForm.fields.provinces.ON"),
      code: "CA-ON",
    },
    {
      id: "CA-PE",
      label: t("loginForm.fields.provinces.PE"),
      code: "CA-PE",
    },
    {
      id: "CA-QC",
      label: t("loginForm.fields.provinces.QC"),
      code: "CA-QC",
    },
    {
      id: "CA-SK",
      label: t("loginForm.fields.provinces.SK"),
      code: "CA-SK",
    },
    {
      id: "CA-YT",
      label: t("loginForm.fields.provinces.YT"),
      code: "CA-YT",
    },
  ];

  const genderData = [
    {
      id: "male",
      label: t("loginForm.fields.gender.male"),
      code: "male",
    },
    {
      id: "female",
      label: t("loginForm.fields.gender.female"),
      code: "female",
    },
    {
      id: "non_binary",
      label: t("loginForm.fields.gender.non_binary"),
      code: "non_binary",
    },
    {
      id: "transgender",
      label: t("loginForm.fields.gender.transgender"),
      code: "transgender",
    },
    {
      id: "other",
      label: t("loginForm.fields.gender.other"),
      code: "other",
    },
    {
      id: "prefer_not",
      label: t("loginForm.fields.gender.prefer_not"),
      code: "prefer_not",
    },
  ];

  const designatedGroupData = [
    {
      id: "first_nations",
      label: t("loginForm.fields.designated_groups.first_nations"),
      code: "first_nations",
    },
    {
      id: "metis",
      label: t("loginForm.fields.designated_groups.metis"),
      code: "metis",
    },
    {
      id: "inuit",
      label: t("loginForm.fields.designated_groups.inuit"),
      code: "inuit",
    },
    {
      id: "francophone",
      label: t("loginForm.fields.designated_groups.francophone"),
      code: "francophone",
    },
    {
      id: "newcomer",
      label: t("loginForm.fields.designated_groups.newcomer"),
      code: "newcomer",
    },
    {
      id: "disability",
      label: t("loginForm.fields.designated_groups.disability"),
      code: "disability",
    },
    {
      id: "racialized",
      label: t("loginForm.fields.designated_groups.racialized"),
      code: "racialized",
    },
    {
      id: "lgbt",
      label: t("loginForm.fields.designated_groups.lgbt"),
      code: "lgbt",
    },
    {
      id: "other_designated_groups",
      label: t("loginForm.fields.designated_groups.other_designated_groups"),
      code: "other_designated_groups",
    },
    {
      id: "prefer_not_dg",
      label: t("loginForm.fields.designated_groups.prefer_not_dg"),
      code: "prefer_not_dg",
    },
  ];

  const validate = () => {
    let temp = {};
    const emailRegex = /\S+@\S+\.\S+/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    temp.first_name = values.first_name ? "" : t("error:fieldRequired");
    temp.last_name = values.last_name ? "" : t("error:fieldRequired");
    temp.email = emailRegex.test(values.email) ? "" : t("error:emailInvalid");
    temp.password = passwordRegex.test(values.password)
      ? ""
      : t("error:weakPassword");
    temp.confirmPassword =
      values.password === values.confirmPassword
        ? ""
        : t("error:passwordMismatch");
    temp.province = values.province ? "" : t("error:fieldRequired");
    temp.gender = values.gender ? "" : t("error:fieldRequired");
    temp.age = values.age ? "" : t("error:fieldRequired");
    temp.designated_groups =
      values.designated_groups.length > 0 ? "" : t("error:fieldRequired");
    temp.agreeTerms = values.agreeTerms ? "" : t("error:fieldRequired");

    setErrors({
      ...temp,
    });

    return Object.keys(temp).every((key) => temp[key] === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError("");

    //console.log("handleSubmit called"); // Debugging log
    //console.log("Form Values:", values); // Debugging log

    if (validate()) {
      setSubmitting(true);

      const payload = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        password: values.password,
        city: values.city,
        province: values.province,
        postcode: values.postcode,
        gender: values.gender,
        age_range: values.age,
        designated_groups: values.designated_groups,
        country: values.country,
        agree: values.agreeTerms,
        qualification_before: values.qualification_before,
        opt_in_email: values.optInEmail,
      };

      //console.log("Payload to be sent:", payload); // Add this line

      signup(payload)
        .then(() => {
          // setSubmitting(false);
          // navigate({
          //   pathname: "/signup-confirm",
          //   state: {
          //     background: {
          //       hash: "",
          //       pathname: location.state.background.pathname,
          //       search: "",
          //       state: undefined,
          //     },
          //   },
          // });
          setOpenSignupConfirm(true);
          setShowSetupProfile(true);
        })
        .catch((err) => {
          //console.log("Signup failed:", err); // Debugging log
          setFormError(t("error:signupFailed"));
          setSubmitting(false);
        });
    } else {
      //console.log("Validation failed"); // Debugging log
    }
  };

  const label = (
    <span>
      {t("loginForm.agreeText")}&nbsp;
      <Link to="/terms-and-conditions" target="_blank">
        {t("loginForm.termsAndConditions")}
      </Link>
    </span>
  );

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleDesignatedGroupsChange = (event) => {
    const { value } = event.target;
    setValues({
      ...values,
      designated_groups: typeof value === "string" ? value.split(",") : value,
    });
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        {formError && (
          <Box my={3} p={1} bgcolor="secondary.light">
            <Typography variant="subtitle2" color="error">
              {formError}
            </Typography>
          </Box>
        )}
        <Grid container columnSpacing={{ xs: 1, md: 2 }}>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              required
              id="first_name_input"
              label={t("loginForm.fields.first_name")}
              variant="outlined"
              value={values.first_name}
              onChange={handleInputChange}
              error={errors.first_name ? true : undefined}
              autoComplete="first_name"
              sx={{
                "& .MuiInputLabel-root": { color: "tertiary.dark" },
                [theme.breakpoints.down(420)]: { minWidth: "26ch" },
              }}
              color="secondary"
              name="first_name"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              required
              id="last_name_input"
              label={t("loginForm.fields.last_name")}
              variant="outlined"
              value={values.last_name}
              onChange={handleInputChange}
              error={errors.last_name ? true : undefined}
              autoComplete="last_name"
              sx={{
                "& .MuiInputLabel-root": { color: "tertiary.dark" },
                [theme.breakpoints.down(420)]: { minWidth: "26ch" },
              }}
              color="secondary"
              name="last_name"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="email_input"
              label={t("loginForm.fields.email")}
              variant="outlined"
              value={values.email}
              onChange={handleInputChange}
              error={errors.email ? true : undefined}
              autoComplete="email"
              sx={{
                "& .MuiInputLabel-root": { color: "tertiary.dark" },
                [theme.breakpoints.down(420)]: { minWidth: "26ch" },
              }}
              color="secondary"
              type="email"
              name="email"
              fullWidth
            />
            <Typography variant="body2" color="error" pl={1}>
              {t("loginForm.fields.passwordDisclaimer")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
              <InputLabel
                htmlFor="outlined-adornment-password"
                sx={{ color: "text.primary" }}
                color="secondary"
                required
              >
                {t("loginForm.fields.password")}
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={t("loginForm.fields.password")}
                name="password"
                value={values.password}
                onChange={handleInputChange}
                error={errors.password ? true : undefined}
                color="secondary"
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormControl
              sx={{ m: 1, color: "secondary.dark" }}
              variant="outlined"
              fullWidth
            >
              <InputLabel
                htmlFor="outlined-adornment-confirm-password"
                sx={{ color: "text.primary" }}
                color="secondary"
                required
              >
                {t("loginForm.fields.confirmPassword")}
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-confirm-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={t("loginForm.fields.confirmPassword")}
                name="confirmPassword"
                value={values.confirmPassword}
                onChange={handleInputChange}
                error={errors.confirmPassword ? true : undefined}
                color="secondary"
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              required
              id="city_input"
              label={t("loginForm.fields.city")}
              variant="outlined"
              value={values.city}
              onChange={handleInputChange}
              error={errors.city ? true : undefined}
              autoComplete="city"
              sx={{
                "& .MuiInputLabel-root": { color: "tertiary.dark" },
                [theme.breakpoints.down(420)]: { minWidth: "26ch" },
              }}
              color="secondary"
              type="text"
              name="city"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              required
              id="province_input"
              label={t("loginForm.fields.selectProvinceLabel")}
              variant="outlined"
              value={values.province}
              onChange={handleInputChange}
              error={errors.province ? true : undefined}
              autoComplete="province"
              sx={{
                "& .MuiInputLabel-root": { color: "tertiary.dark" },
                [theme.breakpoints.down(420)]: { minWidth: "26ch" },
              }}
              color="secondary"
              type="text"
              name="province"
              fullWidth
            />
            {/* <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
              <InputLabel
                id="province-select-label"
                sx={{ color: "tertiary.dark" }}
                color="secondary"
                required
              >
                {t("loginForm.fields.selectProvinceLabel")}
              </InputLabel>
              <Select
                labelId="province-select-label"
                id="province-select"
                name="province"
                value={values.province}
                onChange={handleSelectChange}
                label={t("loginForm.fields.selectProvinceLabel")}
                error={errors.province ? true : undefined}
                color="secondary"
                required
                sx={{ [theme.breakpoints.down(420)]: { minWidth: "26ch" } }}
                inputProps={{
                  MenuProps: {
                    PaperProps: {
                      sx: {
                        "& .Mui-selected": {
                          backgroundColor: "rgba(211, 211, 211, 0.6)",
                        },
                        "& .Mui-selected:hover": {
                          backgroundColor: "rgba(14, 137, 188, 0.3)",
                        },
                      },
                    },
                  },
                }}
              >
                {provinceData.map((province) => (
                  <MenuItem
                    color="secondary"
                    key={province.label}
                    value={province.label}
                  >
                    {province.label}
                  </MenuItem>
                ))}
              </Select>
              {errors.province && (
                <Typography variant="body2" color="error">
                  {errors.province}
                </Typography>
              )}
            </FormControl> */}
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="postcode_input"
              label={t("loginForm.fields.postcode")}
              variant="outlined"
              value={values.postcode}
              onChange={handleInputChange}
              error={errors.postcode ? true : undefined}
              autoComplete="postcode"
              sx={{
                "& .MuiInputLabel-root": { color: "tertiary.dark" },
                [theme.breakpoints.down(420)]: { minWidth: "26ch" },
              }}
              color="secondary"
              name="postcode"
              type="text"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
              <InputLabel
                id="gender-select-label"
                sx={{ color: "tertiary.dark" }}
                required
                color="secondary"
              >
                {t("loginForm.fields.selectGenderLabel")}
              </InputLabel>
              <Select
                labelId="gender-select-label"
                id="gender-select"
                name="gender"
                value={values.gender}
                onChange={handleSelectChange}
                label={t("loginForm.fields.selectGenderLabel")}
                error={errors.gender ? true : undefined}
                required
                color="secondary"
                sx={{ [theme.breakpoints.down(420)]: { minWidth: "26ch" } }}
                inputProps={{
                  MenuProps: {
                    PaperProps: {
                      sx: {
                        "& .Mui-selected": {
                          backgroundColor: "rgba(211, 211, 211, 0.6)",
                        },
                        "& .Mui-selected:hover": {
                          backgroundColor: "rgba(14, 137, 188, 0.3)",
                        },
                      },
                    },
                  },
                }}
              >
                {genderData.map((gender) => (
                  <MenuItem key={gender.code} value={gender.code}>
                    {gender.label}
                  </MenuItem>
                ))}
              </Select>
              {errors.gender && (
                <Typography variant="body2" color="error">
                  {errors.gender}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel
                id="age-range-radio-buttons-group-label"
                sx={{ color: "text.primary" }}
                color="secondary"
                required
              >
                {t("loginForm.fields.age_range_label")}
              </FormLabel>
              <RadioGroup
                aria-labelledby={t("loginForm.fields.age_range_label")}
                row
                required
                onChange={handleInputChange}
                name="age"
                value={values.age}
              >
                <FormControlLabel
                  value="16-29"
                  control={
                    <Radio sx={{ color: "text.primary" }} color="secondary" />
                  }
                  label="16-29"
                />
                <FormControlLabel
                  value="30-39"
                  control={
                    <Radio sx={{ color: "text.primary" }} color="secondary" />
                  }
                  label="30-39"
                />
                <FormControlLabel
                  value="40-49"
                  control={
                    <Radio sx={{ color: "text.primary" }} color="secondary" />
                  }
                  label="40-49"
                />
                <FormControlLabel
                  value="50-59"
                  control={
                    <Radio sx={{ color: "text.primary" }} color="secondary" />
                  }
                  label="50-59"
                />
                <FormControlLabel
                  value="60+"
                  control={
                    <Radio sx={{ color: "text.primary" }} color="secondary" />
                  }
                  label="60+"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormLabel
              id="designated-groups-label"
              sx={{ color: "text.primary", marginBottom: "1em" }}
              fullWidth
            >
              {t("loginForm.fields.designated_groups_label")}
            </FormLabel>
            <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
              <InputLabel
                id="designated-groups-select-label"
                sx={{ color: "tertiary.dark" }}
                required
                color="secondary"
              >
                {t("loginForm.fields.selectDesignatedGroupsLabel")}
              </InputLabel>
              <Select
                labelId="designated-groups-label"
                id="designated-groups"
                multiple
                value={values.designated_groups}
                onChange={handleDesignatedGroupsChange}
                input={
                  <OutlinedInput
                    label={t("loginForm.fields.selectDesignatedGroupsLabel")}
                  />
                }
                renderValue={(selected) => selected.join(", ")}
                color="secondary"
                sx={{
                  textOverflow: "ellipsis",
                  maxWidth: "44ch",
                  [theme.breakpoints.down(550)]: { maxWidth: "35ch" },
                }}
                inputProps={{
                  MenuProps: {
                    PaperProps: {
                      sx: {
                        "& .Mui-selected": {
                          backgroundColor: "rgba(211, 211, 211, 0.6)",
                        },
                        "& .Mui-selected:hover": {
                          backgroundColor: "rgba(14, 137, 188, 0.3)",
                        },
                      },
                    },
                  },
                }}
              >
                {designatedGroupData.map((group) => (
                  <MenuItem key={group.code} value={group.label}>
                    <Checkbox
                      sx={{ color: "text.primary" }}
                      color="secondary"
                      checked={
                        values.designated_groups.indexOf(group.label) > -1
                      }
                    />
                    <ListItemText color="secondary" primary={group.label} />
                  </MenuItem>
                ))}
              </Select>
              {errors.group && (
                <Typography variant="body2" color="error">
                  {errors.group}
                </Typography>
              )}
            </FormControl>
          </Grid>
          {/* <Grid item xs={6}>
            <Select
              label={t("loginForm.fields.selectCountry")}
              name="selectedCountry"
              value={values.selectedCountry}
              onChange={handleInputChange}
              options={Countries}
              error={errors.selectedCountry}
            />
          </Grid> */}
          <Grid item xs={12}>
            <FormControlLabel
              required
              control={
                <Checkbox
                  name="agreeTerms"
                  value={values.agreeTerms}
                  onChange={handleInputChange}
                  error={errors.agreeTerms ? true : undefined}
                  color="secondary"
                  sx={{ color: "text.primary" }}
                />
              }
              label={label}
              fullWidth
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  name="optInEmail"
                  value={values.optInEmail}
                  onChange={handleInputChange}
                  error={errors.optInEmail ? true : undefined}
                  color="secondary"
                  sx={{ color: "text.primary" }}
                  fullWidth
                />
              }
              label={t("loginForm.fields.marketing_label")}
            /> */}
            <Box
              mx={1}
              my={2}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                color="primary"
                size="large"
                variant="contained"
                disableElevation
                className={classes.buttonRoot}
                endIcon={<ArrowForwardIcon />}
                type="submit"
                disabled={submitting}
                sx={{ fontSize: "1em", fontWeight: "bold" }}
              >
                {t("loginForm.signupButton")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};

export default SignupForm;
