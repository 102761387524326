import React, { useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: "100%",
    height: "100%",
    margin: 0,
  },
  dialogRoot: {},
  dialogContent: {
    padding: theme.spacing(3),
  },
}));

export default function StartAssessmentPopup(props) {
  const classes = useStyles();
  const { children, openPopup, setOpenPopup } = props;
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    const handlePopState = (e) => {
      if (openPopup) {
        setOpenPopup(false);
        navigate(location.pathname, { replace: true });
      }
    };

    if (openPopup) {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener("popstate", handlePopState);
    } else {
      window.removeEventListener("popstate", handlePopState);
    }

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [openPopup, navigate, location.pathname, setOpenPopup]);

  const handleClose = (e) => {
    e.stopPropagation();
    setOpenPopup(false);
    if (location.state?.background) {
      navigate(location.state.background.pathname);
    } else {
      navigate("/");
    }
  };

  return (
    <Dialog
      open={openPopup}
      fullScreen
      maxWidth={false}
      classes={{ paper: classes.dialogWrapper, root: classes.dialogRoot }}
      onClose={handleClose}
    >
      <DialogTitle 
        classes={{ root: classes.dialogTitle }}
        style={{ display: "none" }}
      />
      <DialogContent classes={{ root: classes.dialogContent }}>
        {children}
      </DialogContent>
    </Dialog>
  );
}
