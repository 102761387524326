// ./pages/NotFound.js
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Container } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40vh',
    textAlign: 'center',
    backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/home/home-header-mobile.png'})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  title: {
    color: '#000',
    fontWeight: 'bold',
    fontSize: '2rem',
  },
  message: {
    color: '#000',
    fontSize: '1.2rem',
  },
}));

const NotFound = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="xl">
      <div className={classes.root}>
        <h1 className={classes.title}>404 - Page Not Found</h1>
        <p className={classes.message}>The page you are looking for does not exist.</p>
      </div>
    </Container>
  );
}

export default NotFound;