import React, { useState, useLayoutEffect } from "react";
import {
  Paper,
  Grid,
  Typography,
  Box,
  Button,
  useMediaQuery,
  Container,
  ListItem,
  List,
  ListItemText,
  ListItemIcon,
  Link,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CloseIcon from "@mui/icons-material/Close";
import homeHeroDesktop from "../assets/home/home-header-desktop.png";
import homeHeroMobile from "../assets/home/home-header-mobile.png";
import bodyImage1 from "../assets/home/two-men-welding-square-cropped.jpg";
import bodyImage2 from "../assets/home/bodyImage2.jpeg";
import homeImage2 from "../assets/home/image3.png";
import iconAssist from "../assets/icons/1-banner1.png";
import iconWelder from "../assets/icons/2-banner2.png";
import iconInteractive from "../assets/icons/icon-interactive.png";
import iconProcess from "../assets/icons/icon-process.png";
import iconTrend from "../assets/icons/icon-trend.png";
import iconBook from "../assets/icons/icon-book.png";
import weldingImage from "../assets/home/welder-welding-banner.jpg";
import iconWelderatWork from "../assets/home/welder-at-work-icon.svg";
import iconCheckmark from "../assets/home/checkmark-icon.svg";
import flameIcon from "../assets/home/flame-torch-icon.svg";
import manCompIcon from "../assets/home/man-on-computer-icon.svg";
import iconArrow from "../assets/icons/icon-arrow@2x.png";
import { useAuth } from "../components/useAuth";
import { Helmet } from "react-helmet";
import Banner from "../components/Banner";
import { useTranslation, Trans } from "react-i18next";
import Popup from "../components/Popup";
import Signup from "../pages/Signup";
import { display, height, textAlign } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.3)",
  },
  section1Container: {
    //marginBottom: theme.spacing(4),
  },
  image1Styles: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "300px",
    width: "100%",
    margin: "0 auto",
    borderRadius: "1rem",
    [theme.breakpoints.down("md")]: {
      borderRadius: 0,
    },
  },
  image2Styles: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "450px",
    width: "100%",
    margin: "0 auto",
    borderRadius: "1rem",
    [theme.breakpoints.down("md")]: {
      borderRadius: 0,
      height: "300px",
    },
  },
  section2Container: {
    marginBottom: theme.spacing(4),
  },
  section2Text: {
    textAlign: "left",
  },
  banner: {
    position: "relative",
    padding: "0",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "100%",
    flexDirection: "row",
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      //marginBottom: theme.spacing(4),
      flexDirection: "column",
    },
  },
  heroImage: {
    width: "100%",
    maxWidth: "100%",
    minHeight: "300px",
    maxHeight: "300px",
    height: "auto",
  },
  heroContainer: {
    position: "absolute",
    width: "60%",
    top: 0,
    right: 0,
    marginTop: "5%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: "1.5rem",
    },
  },
  heroIcon: {
    height: "100px",
    width: "100px",
    [theme.breakpoints.down("lg")]: {
      height: "50px",
      width: "50px",
    },
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  },
  iconText: {
    paddingLeft: theme.spacing(2),
    fontWeight: "bold",
    maxWidth: "70ch",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0",
      paddingTop: theme.spacing(1),
    },
  },
  heroContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
      padding: theme.spacing(0, 3),
    },
  },
  textContainer: {
    paddingRight: "40px",
  },
  bannerSection: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: "-32px",
    position: "relative",
  },
  bannerContainer: {
    flexWrap: "nowrap",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 3),
    },
  },
  overlayContainer: {
    backgroundColor: "black",
    position: "absolute",
    opacity: "0.9",
    zIndex: 998,
    bottom: 0,
    width: "100%",
    padding: theme.spacing(1, 0),
  },
  overlayWrapper: {
    padding: theme.spacing(1, 4),
    [theme.breakpoints.down("md")]: {
      padding: "0px 8px",
    },
  },
  bannerTextWrapper: {
    padding: theme.spacing(0, 4),
  },
  whiteText: {
    color: "#fff",
  },
  iconFont: {
    fontSize: "4rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  },
  sectionPadding: {
    padding: theme.spacing(2, 4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 3),
    },
  },
  sectionWrapper: {
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  sectionHeader: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1, 0),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1, 3),
    },
  },
  headerTextContainer: {},
  bodyMargin: {
    padding: theme.spacing(4),
    marginLeft: theme.spacing(4),
    border: "1px solid #000",
    [theme.breakpoints.down("md")]: {
      margin: 0,
      //padding: theme.spacing(2, 3),
    },
  },
  bodyTextContainer: {
    padding: theme.spacing(2, 0),
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      margin: 0,
      padding: theme.spacing(2, 3),
    },
  },
  headerIconContainer: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(0, 2),
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginRight: theme.spacing(2),
    },
  },
  headerIcon: {
    height: "40px",
    width: "40px",
  },
  vertical_dotted_line: {
    borderLeft: "4px dotted black",
    height: "100%",
    display: "inline-block",
  },
  vertical_line_wrapper: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    marginLeft: theme.spacing(2),
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0",
    },
  },
  downArrow: {
    height: "40px",
    width: "40px",
    transform: "rotate(90deg)",
  },
  line_container: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  section2IconContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 3),
      flexDirection: "column",
      alignItems: "center",
    },
  },
  timelineContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    listStyle: "none",
    justify: "space-between",
    "&:after": {
      content: "''",
      position: "absolute",
      zIndex: "-1",
      left: "20%",
      width: "70%",
      top: "25%",
      borderTop: `2px dotted ${theme.palette.secondary.main}`,
    },
    [theme.breakpoints.down("xl")]: {
      "&:after": {
        top: "17%",
      },
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      padding: 0,
      "&:after": {
        display: "none",
      },
    },
  },
  timelineListItem: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justify: "center",
    alignItems: "center",
    margin: theme.spacing(1),
    flexBasis: "250px",
    [theme.breakpoints.down("md")]: {
      flexBasis: "0",
    },
  },
  line: {
    position: "relative",
    textAlign: "center",
    display: "inline-block",
  },
  timelineIcon: {
    marginBottom: theme.spacing(1),
    height: "80px",
    width: "80px",
    [theme.breakpoints.down("md")]: {
      height: "50px",
      width: "50px",
    },
  },
  verticalLine: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
      position: "relative",
      zIndex: "-1",
      height: "40px",
      marginLeft: "50%",
      borderLeft: `1px dashed ${theme.palette.secondary.main}`,
    },
  },
  section3Container: {
    padding: theme.spacing(2, 4),
    [theme.breakpoints.down("md")]: {
      paddingBottom: theme.spacing(3),
    },
  },
  section3Image: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "450px",
  },
  section3Text: {
    marginTop: theme.spacing(2),
  },
  closeIcon: {
    color: "white",
    marginLeft: theme.spacing(1),
  },
  bannerIcon: {
    height: "160px",
    width: "160px",
    [theme.breakpoints.down("lg")]: {
      height: "90px",
      width: "90px",
    },
    [theme.breakpoints.down("md")]: {
      height: "70px",
      width: "70px",
    },
  },
  bannerIconMargin: {
    marginRight: theme.spacing(3),
  },
  bannerIconContainer: {
    textAlign: "center",
  },
  buttonRoot: {
    textTransform: "none",
    borderRadius: "0.8rem",
    padding: "4px 24px",
    fontSize: "1.2em",
    fontWeight: "bold",
  },
  scroll: {
    position: "fixed",
    bottom: "0",
  },
  image: {
    width: "100%",
    height: "auto",
    objectFit: "cover",
    objectPosition: "center",
  },

  yellowBand: {
    backgroundColor: theme.palette.primary.main,
    height: "5vh",
    position: "relative",
    zIndex: "2",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
  },

  diagonalContainer: {
    backgroundColor: theme.palette.secondary.main,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: "45%",
    transform: "skew(20deg)",
    zIndex: "1",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  heroImage: {
    width: "100%",
    maxWidth: "100%",
    // minHeight:'300px',
    maxHeight: "300px",
    height: "auto",
  },
  herobannerImage: {
    maxHeight: "350px",
    width: "55vw",
    objectFit: "cover",

    [theme.breakpoints.down("sm")]: {
      width: "100vw",
    },
  },
  imgContainer: {
    //paddingRight: theme.spacing(4),
    display: "flex",
  },
  greyContainer: {
    backgroundColor: "#F7F7F7",
  },
  darkgreyContainer: {
    backgroundColor: "#D9D9D9",
  },
  yellowBox: {
    backgroundColor: theme.palette.primary.main,
    height: "250px",
    width: "100%",
    marginRight: "1em",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      height: "100%",
      marginRight: "0",
    },
    [theme.breakpoints.down("md")]: {
      width: "50vw",
      height: "auto",
      marginRight: "0",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  blueContainer: {
    backgroundColor: theme.palette.secondary.main,
  },
  blueArrow: {
    backgroundColor: theme.palette.secondary.main,
    clipPath: "polygon(0% 0%, 80% 0%, 100% 50%, 80% 100%, 0% 100%)",
    height: "250px",
    width: "100%",

    [theme.breakpoints.down("md")]: {
      clipPath: "polygon(0% 0%, 0% 80%, 50% 100%, 100% 80%, 100% 0%)",
      width: "50vw",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  greyArrow: {
    backgroundColor: "#e3e2e2",
    clipPath:
      "polygon(0% 100%, 79.7% 100%, 100% 50.8%, 79.7% 0%, 0% 0%, 20.1% 50.8%, 0% 100%)",
    height: "250px",
    position: "relative",
    right: "18%",
    width: "100%",

    [theme.breakpoints.down("md")]: {
      right: "0",
      bottom: "50px",
      clipPath:
        "polygon(100% 0%, 100% 79.7%, 50% 100%, 0% 79.7%, 0% 0%, 50% 20.3%, 100% 0%)",
      width: "50vw",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  yellowArrow: {
    backgroundColor: theme.palette.primary.main,
    clipPath:
      "polygon(0% 100%, 79.7% 100%, 100% 50.8%, 79.7% 0%, 0% 0%, 20.1% 50.8%, 0% 100%)",
    height: "250px",
    position: "relative",
    right: "36%",
    width: "100%",

    [theme.breakpoints.down("md")]: {
      right: "0",
      bottom: "100px",
      clipPath:
        "polygon(100% 0%, 100% 79.7%, 50% 100%, 0% 79.7%, 0% 0%, 50% 20.3%, 100% 0%)",
      width: "50vw",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  yellowArrowText: {
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(16),
    },
  },
  arrowText: {
    maxWidth: "27ch",
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
  },
  subArrowText: {
    marginLeft: "4em",
    display: "list-item",
    paddingLeft: "0",
    [theme.breakpoints.down("md")]: {
      marginLeft: "2em",
    },
    // [theme.breakpoints.down("xs")]: {
    //   marginLeft: "0",
    // },
  },
  arrowContainer: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  heroHeadingContainer: {
    [theme.breakpoints.up("xl")]: {
      paddingLeft: "calc(20% - 10px)",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "1em",
    },
  },
  roadmapButton: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const Home = (props) => {
  const classes = useStyles();
  const { t } = useTranslation(["home", "common"]);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const lessThan400 = useMediaQuery("(max-width:400px");
  const [overlay, setOverlay] = React.useState(true);
  const [scrolled, setScrolled] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  let location = useLocation();
  let navigate = useNavigate();
  const { user, token } = useAuth();

  useLayoutEffect(() => {
    const handleScroll = (e) => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const closeOverlay = () => {
    setOverlay(!overlay);
  };

  const handleNavigation = (path) => {
    if (token) {
      navigate({ pathname: path });
    } else {
      setOpenPopup(true);
      navigate("/signup", {
        state: { background: location, goTo: path },
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>{t("metaTitle")}</title>
        <meta name="description" content={t("metaDesc")} />
      </Helmet>
      {/** Banner */}
      <Container maxWidth="full" disableGutters>
        <div className={classes.yellowBand}>
          {/* {t("comingSoon")} */}
        </div>
        <Box className={classes.banner}>
          <div className={classes.diagonalContainer}></div>
          <Container maxWidth="xl">
            <Typography
              variant="h1"
              align="left"
              color="white"
              sx={{
                position: "relative",
                zIndex: "2",
                fontWeight: "bold",
              }}
              className={classes.heroHeadingContainer}
            >
              {t("home:banner")}
              <br />
              {!isMobile && (
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<ArrowForwardIcon />}
                  disableElevation
                  size="medium"
                  onClick={() => handleNavigation("/dashboard")}
                  className={classes.buttonRoot}
                >
                  <Typography variant="h5">
                    {t("common:buttons.startAssessment")}
                  </Typography>
                </Button>
              )}
            </Typography>
          </Container>
          <div className={classes.imgContainer}>
            <img
              src={weldingImage}
              alt={""}
              className={classes.herobannerImage}
            />
          </div>
        </Box>
      </Container>
      <Container maxWidth="xl" className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1" py={6}>
              {t("subBanner.text1")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.iconContainer}>
              <img src={iconWelderatWork} alt="" />
              <Typography className={classes.iconText} variant="body2">
                {t("subBanner.iconGrid.icon1")}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.iconContainer}>
              <img src={flameIcon} alt="" />
              <Typography className={classes.iconText} variant="body2">
                {t("subBanner.iconGrid.icon2")}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.iconContainer}>
              <img src={iconCheckmark} alt="" />
              <Typography variant="body2" className={classes.iconText}>
                {t("subBanner.iconGrid.icon3")}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.iconContainer}>
              <img src={manCompIcon} alt="" />
              <Typography className={classes.iconText} variant="body2">
                {t("subBanner.iconGrid.icon4")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container
        maxWidth="full"
        disableGutters
        className={[classes.container, classes.greyContainer]}
      >
        <Container maxWidth="xl" className={classes.container}>
          {/** Section 1 */}
          <section className={classes.sectionWrapper}>
            <Typography
              variant="h3"
              sx={{ fontWeight: "bold", paddingTop: ".5em" }}
            >
              {t("section1.header")}
            </Typography>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className={classes.section1Container}
              py={4}
            >
              <Grid item xs={12} lg={4} className={classes.arrowContainer}>
                <div className={classes.yellowBox}>
                  <Typography sx={{ fontWeight: "bold", padding: "1.5em" }}>
                    {t("section1.textBox")}
                  </Typography>
                </div>
              </Grid>

              <Grid item xs={12} lg={8}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  className={classes.section1Container}
                  py={4}
                >
                  <Grid item sm={12} md={4} className={classes.arrowContainer}>
                    <div className={classes.blueArrow}>
                      <List
                        component={"ol"}
                        sx={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          listStyleType: "decimal",
                          paddingLeft: "2em",
                        }}
                      >
                        <ListItem
                          className={classes.arrowText}
                          display="flex"
                          alignItems="center"
                          sx={{
                            color: "#fff",
                            display: "list-item",
                            paddingLeft: "0",
                          }}
                        >
                          {t("section1.blueArrow")}
                        </ListItem>
                      </List>
                    </div>
                  </Grid>
                  <Grid item sm={12} md={4} className={classes.arrowContainer}>
                    <div className={classes.greyArrow}>
                      <List
                        component={"ol"}
                        sx={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          listStyleType: "decimal",
                          paddingLeft: "0",
                        }}
                        start="2"
                      >
                        <ListItem
                          className={[classes.arrowText, classes.subArrowText]}
                          sx={{}}
                        >
                          {t("section1.greyArrow")}
                        </ListItem>
                      </List>
                    </div>
                  </Grid>
                  <Grid item sm={12} md={4} className={classes.arrowContainer}>
                    <div className={classes.yellowArrow}>
                      <List
                        component={"ol"}
                        sx={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          listStyleType: "decimal",
                          paddingLeft: "1.5em",
                        }}
                        start="3"
                      >
                        <ListItem
                          className={[
                            classes.arrowText,
                            classes.subArrowText,
                            classes.yellowArrowText,
                          ]}
                        >
                          {t("section1.yellowArrow")}
                        </ListItem>
                      </List>
                    </div>
                  </Grid>
                  <Button
                    variant="contained"
                    color="primary"
                    endIcon={<ArrowForwardIcon />}
                    disableElevation
                    size="large"
                    onClick={() => handleNavigation("/dashboard")}
                    className={[classes.buttonRoot, classes.roadmapButton]}
                    sx={{ marginTop: "-2em" }}
                  >
                    <Typography variant="h5">
                      {t("common:buttons.startAssessment")}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {/** End Section 1 */}
          </section>
        </Container>
      </Container>
      <Container maxWidth="xl" className={classes.container}>
        {/** Section 2 */}
        <section className={classes.sectionWrapper}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: "bold",
              paddingTop: ".5em",
              paddingBottom: "1em",
            }}
          >
            {t("section2.header")}
          </Typography>
          <Grid
            container
            justifyContent="center"
            alignItems="flex-start"
            className={classes.section1Container}
          >
            <Grid item sm={12} md={6}>
              <img className={classes.image} src={bodyImage1} alt=""></img>
            </Grid>
            <Grid item sm={12} md={6}>
              <Box flexDirection="column">
                <Box className={classes.bodyMargin}>
                  <Typography align="left" gutterBottom variant="body1">
                    {t("section2.text1")}
                  </Typography>
                  <List sx={{ listStyleType: "disc", paddingLeft: "20px" }}>
                    <ListItem
                      sx={{
                        display: "list-item",
                        paddingLeft: "0",
                        listStyleType: "inherit",
                      }}
                    >
                      <ListItemText primary={t("section2.listItem1")} />
                    </ListItem>
                    <ListItem
                      sx={{
                        display: "list-item",
                        paddingLeft: "0",
                        listStyleType: "inherit",
                      }}
                    >
                      <ListItemText primary={t("section2.listItem2")} />
                    </ListItem>
                    <ListItem
                      sx={{
                        display: "list-item",
                        paddingLeft: "0",
                        listStyleType: "inherit",
                      }}
                    >
                      <ListItemText primary={t("section2.listItem3")} />
                    </ListItem>
                  </List>
                </Box>
              </Box>
            </Grid>
            <Grid item sm={12} pt={4}>
              <Typography align="left">
                {t("section2.e9y_more_info.listHeading")}
                <List sx={{ listStyleType: "disc", paddingLeft: "20px" }}>
                  <ListItem
                    sx={{
                      display: "list-item",
                      paddingLeft: "0",
                      listStyleType: "inherit",
                    }}
                  >
                    {t("section2.e9y_more_info.listItem1")}
                    <Link
                      href="https://www.cwbgroup.org/education/courses/micro-credential-courses"
                      target="_blank"
                      rel="nofollow"
                      color="secondary"
                      title={t("section2.e9y_more_info.listItem1Link")}
                    >
                      {t("section2.e9y_more_info.listItem1Link")}
                    </Link>
                  </ListItem>
                  <ListItem
                    sx={{
                      display: "list-item",
                      paddingLeft: "0",
                      listStyleType: "inherit",
                    }}
                  >
                    {t("section2.e9y_more_info.listItem2")}
                    <List sx={{ listStyleType: "circle", paddingLeft: "20px", paddingBottom: "0" }}>
                      <ListItem
                        sx={{
                          display: "list-item",
                          paddingLeft: "0",
                          listStyleType: "inherit",
                        }}
                      >
                        {t("section2.e9y_more_info.sublistItem1")}
                        <Link
                          href="https://ljpskills.ca/"
                          target="_blank"
                          rel="nofollow"
                          color="secondary"
                          title={t("section2.e9y_more_info.sublistItem1Link")}
                        >
                          {t("section2.e9y_more_info.sublistItem1Link")}
                        </Link>
                      </ListItem>
                      <ListItem
                        sx={{
                          display: "list-item",
                          paddingLeft: "0",
                          listStyleType: "inherit",
                        }}
                      >
                        {t("section2.e9y_more_info.sublistItem2")}
                        <Link
                          href="https://www.cwbgroup.org/certification-and-qualification"
                          target="_blank"
                          rel="nofollow"
                          color="secondary"
                          title={t("section2.e9y_more_info.sublistItem2Link")}
                        >
                          {t("section2.e9y_more_info.sublistItem2Link")}
                        </Link>
                      </ListItem>
                    </List>
                  </ListItem>
                  <ListItem
                    sx={{
                      display: "list-item",
                      paddingLeft: "0",
                      paddingTop: "0",
                      listStyleType: "inherit",
                    }}
                  >
                    {t("section2.e9y_more_info.listItem3")}
                    <List sx={{ listStyleType: "circle", paddingLeft: "20px" }}>
                      <ListItem
                        sx={{
                          display: "list-item",
                          paddingLeft: "0",
                          listStyleType: "inherit",
                        }}
                      >
                        {t("section2.e9y_more_info.sublistItem3")}
                        <Link
                          href="https://www.learninghub.ca/"
                          target="_blank"
                          rel="nofollow"
                          color="secondary"
                          title={t("section2.e9y_more_info.sublistItem3Link")}
                        >
                          {t("section2.e9y_more_info.sublistItem3Link")}
                        </Link>
                      </ListItem>
                    </List>
                  </ListItem>
                </List>
              </Typography>
            </Grid>
          </Grid>
        </section>
        {/** End Section 2 */}
      </Container>
      <Container
        maxWidth="full"
        className={[classes.container, classes.blueContainer]}
      >
        <Container maxWidth="xl">
          <section>
            <Grid container className={classes.section3Container}>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}>
                <Box py={2} display="flex" justifyContent="center">
                  <Typography variant="h5" color="white">
                    {t("blueBar.text")}
                    <Link
                      target="_blank"
                      href="https://www.cwbgroup.org/about/news-events/news/2024/cwb-group-launches-full-cycle-assessment-and-training-program"
                      rel="nofollow"
                      color="tertiary.main"
                    >
                      {t("blueBar.link")}
                    </Link>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </section>
        </Container>
      </Container>
      <Container
        maxWidth="full"
        className={[classes.container, classes.darkgreyContainer]}
      >
        <Container maxWidth="xl">
          <section>
            <Grid container className={classes.section3Container}>
              <Grid item xs={6} md={9} display="flex" alignItems="center">
                <Box py={2} display="flex" justifyContent="center">
                  <Typography variant="h2" pr={2}>
                    {t("greyBanner.text")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} md={3} display="flex" alignItems="center">
                <Box py={2} display="flex" justifyContent="center">
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    size="medium"
                    component={Link}
                    href={"/contact-us"}
                    className={classes.buttonRoot}
                  >
                    {t("greyBanner.ctaTitle")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </section>
        </Container>
      </Container>
    </>
  );
};

export default Home;
