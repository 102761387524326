import React from 'react';
import { Grid, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowIcon from './ArrowIcon';

const useStyles = makeStyles((theme) => ({
    boxWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column'
        }
    }
}));

const ArrowBox = ({ children, mdCols = 3, noArrow }) => {
    const classes = useStyles();
    const cols = typeof mdCols === 'number' ? mdCols : 3; // Convert mdCols to a number if it's not already
    
    return (
        <Grid item xs={12} lg={cols}>
            <div className={classes.boxWrapper}>
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    {children}
                </Box>
                {!noArrow && <ArrowIcon />}
            </div>
        </Grid>
    );
};

export default ArrowBox;
