import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box, Button, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Form } from '../components/useForm';
import Input from '../components/Input';
import * as api from '../api';
import { useAuth } from '../components/useAuth';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    button: {
        width: '80%',
    },
    buttonRoot: {
        textTransform: 'none',
        borderRadius: '0.8rem',
        padding: '4px 24px'
    },
}));

const initialValues = {
    password: '',
    confirmPassword: '',
}

const NewPassword = () => {
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);
    const [formError, setFormError] = useState('');
    const [tokenExpired, setTokenExpired] = useState(false);
    const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
    const { signout } = useAuth();
    const history = useNavigate();
    const { search } = useLocation(); 
    const decodedURI = decodeURIComponent(search);
    const token = decodedURI.slice(7);
    const { t } = useTranslation(['common', 'error']);

    const validate = () => {
        let temp = {}

        if (values.password.length !== 0) {
            if (values.password.length > 5) {
                temp.password = ""
            } else {
                temp.password = t('error:minCharacters')
            }
        } else {
            temp.password = t('error:fieldRequired')
        }

        if (values.password !== values.confirmPassword) {
            temp.password = t('error:passwordMismatch')
            temp.confirmPassword = t('error:passwordMismatch')
        }

        setErrors({
            ...temp
        })

        return Object.values(temp).every(x => x === "")
    }

    const {
        values, 
        setValues,
        errors,
        setErrors, 
        handleInputChange
    } = useForm(initialValues);

    useEffect(() => {
        const checkToken = async () => {
            try {
                await api.verifyToken(token); // Create an API call to verify the token
            } catch (err) {
                if (err.response && err.response.data && err.response.data.message === 'Token expired') {
                    setTokenExpired(true);
                } else {
                    setFormError(t('error:invalidToken'));
                }
            }
        };
        checkToken();
    }, [token, t]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormError('');
        if (validate()) {
            setSubmitting(true);
            api.changePasswordWithToken(values.password, token)
                .then((res) => {
                    setSubmitting(false);
                    setPasswordResetSuccess(true);
                    signout();
                })
                .catch(err => {
                    setFormError(t('error:passwordResetError'));
                    setSubmitting(false);
                });
        }
    }

    useEffect(() => {
        if (passwordResetSuccess) {
            const timer = setTimeout(() => {
                history('/'); // Redirect to home after 3 seconds
            }, 3000); // Redirect after 3 seconds
            return () => clearTimeout(timer); // Cleanup the timer
        }
    }, [passwordResetSuccess, history]);

    if (tokenExpired) {
        return (
            <Container maxWidth="sm">
                <Box my={3}>
                    <Typography variant="h4" align="center" color="error">
                        {t('Token Expired. Try again!')}
                    </Typography>
                </Box>
            </Container>
        );
    }

    if (passwordResetSuccess) {
        return (
            <Container maxWidth="sm">
                <Box my={3}>
                    <Typography variant="h4" align="center" color="success">
                        {t('Password has been updated.')}
                    </Typography>
                </Box>
            </Container>
        );
    }

    return (
        <div>
            <Container maxWidth="sm">
                <Box my={3}>
                    <Grid container spacing={1} >
                        <Grid item xs={12}>
                            <Typography variant="h3" align="center">
                                {t('common:newPasswordForm.newPassword')}
                            </Typography>
                        </Grid>
                        
                        <Grid item xs={12}>  
                            {formError || tokenExpired ? (
                                <Box my={3} p={1} bgcolor="secondary.light">
                                    <Typography variant="subtitle2" color="error" align="center">
                                        {tokenExpired ? t('error:tokenExpired') : formError}
                                    </Typography>
                                </Box>
                            ) : (
                                <Form onSubmit={handleSubmit}>
                                    <Input  
                                        label={t('common:newPasswordForm.newPassword')}
                                        name="password"
                                        type="password"
                                        value={values.password}
                                        onChange={handleInputChange}
                                        error={errors.password}
                                    />
                                    <Input
                                        label={t('common:newPasswordForm.confirmPassword')}
                                        name="confirmPassword"
                                        type="password"
                                        value={values.confirmPassword}
                                        onChange={handleInputChange}
                                        error={errors.confirmPassword}
                                    />
                                    <Box mx={1} my={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                        <Button 
                                            color="primary" 
                                            size="large" 
                                            variant="contained" 
                                            disableElevation
                                            className={classes.buttonRoot}
                                            type="submit"
                                            disabled={submitting}
                                        >
                                            {t('common:newPasswordForm.submitBtn')}
                                        </Button>
                                    </Box>
                                </Form>
                            )}
                        </Grid>
                    </Grid>
                </Box> 
            </Container>
        </div>
    )
}

export default NewPassword;
