import React, { useEffect } from "react";
import { 
  Grid, 
  Hidden, 
  Typography,
  Box,
  Container
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import ScrollMenu from '../../components/ScrollMenu';
import TrainingSection from './TrainingSection';
import EducationSection from './EducationSection';
import CredentialsSection from './CredentialsSection';
import TestingSection from './TestingSection';
import ApprenticeshipSection from './ApprenticeshipSection';
import SchoolSection from './SchoolSection';
import Banner from '../../components/Banner';
import iconInternational from '../../assets/icons/5-Welding.png';
import trainingHeroDesktop from '../../assets/training/training-header-desktop.png';
import trainingHeroMobile from '../../assets/training/training-header-mobile.png';
import ScrollTopButton from "../../components/ScrollTopButton";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    paddingLeft: '64px',
    paddingRight: '64px',
    [theme.breakpoints.down('md')]: {
        padding: 0
    }
  },  
  paddingSmall: {
    [theme.breakpoints.down('md')]:{
      paddingRight: '12px',
      paddingLeft: '12px',
    }
  },
  section: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  sidebar: {
    position: 'sticky',
    top: '10px'
  },
  container: {
    [theme.breakpoints.down('md')]: {
      margin: 0,
      padding: 0,
    }
  },
}));

const WeldingTraining = () => {
  const classes = useStyles();
  const { t } = useTranslation('weldingTraining');
  const [selectedIndexMobile, setSelectedIndexMobile] = React.useState(0);
  const sections = [
    { id: 'Training', title: t('navButtons.training') }, 
    { id: 'Education', title: t('navButtons.education') }, 
    { id: 'Credentials', title: t('navButtons.credentials') }, 
    { id: 'Testing', title: t('navButtons.testing') }, 
    { id: 'Apprenticeship', title: t('navButtons.apprenticeship') }, 
    { id: 'School', title: t('navButtons.typesOfSchools') }
  ];

  return <>
    <Helmet>
      <title>
        {t('metaTitle')}
      </title>
      <meta name="description" content={t('metaDesc')} />
    </Helmet>
    <Banner 
      src={trainingHeroMobile} 
      srcset={trainingHeroDesktop} 
      alt={''} 
      iconSrc={iconInternational} 
      iconAlt={t('bannerIconAlt')}
      title={t('banner')}
      jc={'center'}
    />
    {/** End Banner */}
    <Container maxWidth="xl" className={classes.container}>
    <ScrollTopButton />
    {/* <div className={classes.bodyContainer} > */}
    <Box mb={4} px="12px">
      <Typography variant="body1" color="inherit" gutterBottom align="left" className={classes.paddingSmall}>
        {t('body')}
      </Typography>
    </Box>
    {/** End Banner */}
    <Grid container spacing={3} style={{margin: 0,width: '100%',}}>
      {/* <Hidden lgDown> */}
        <Grid item md={2} sx={{ display: { xs: 'none', lg: 'block' } }}> 
          <div className={classes.sidebar}>
            <ScrollMenu sections={sections} offset={-250}/>
          </div>
        </Grid>
      {/* </Hidden> */}
      
      <Grid item xs={12} lg={10}>
        {/* <Hidden mdUp>
            <ScrollMenuMobile 
            sections={sections} 
            selectedIndex={selectedIndexMobile} 
            setSelectedIndex={setSelectedIndexMobile} />
        </Hidden> */}
        {/**Section 1 */}
        <section className={classes.section} id="Training">
          <TrainingSection />
        </section>
        {/**End Section 1 */}
        {/* <Hidden mdUp>
            <ScrollMenuMobile 
            sections={sections} 
            selectedIndex={selectedIndexMobile} 
            setSelectedIndex={setSelectedIndexMobile} />
        </Hidden> */}
        {/**Section 2 */}
        <section className={classes.section} id="Education">
          <EducationSection />
        </section>
        {/**End Section 2 */}
        {/* <Hidden mdUp>
          <ScrollMenuMobile 
          sections={sections} 
          selectedIndex={selectedIndexMobile} 
          setSelectedIndex={setSelectedIndexMobile} />
        </Hidden> */}
        {/**Section 3 */}
        <section className={classes.section} id="Credentials">
          <CredentialsSection />
        </section>
        {/**End Section 3 */}
        {/* <Hidden mdUp>
          <ScrollMenuMobile 
          sections={sections} 
          selectedIndex={selectedIndexMobile} 
          setSelectedIndex={setSelectedIndexMobile} />
        </Hidden> */}
        {/**Section 4 */}
        <section className={classes.section} id="Testing">
          <TestingSection />
        </section>
        {/**End Section 4 */}
        {/* <Hidden mdUp>
          <ScrollMenuMobile 
          sections={sections} 
          selectedIndex={selectedIndexMobile} 
          setSelectedIndex={setSelectedIndexMobile} />
        </Hidden> */}
        {/**Section 5 */}
        <section className={classes.section} id="Apprenticeship">
          <ApprenticeshipSection />
        </section>
        {/**End Section 5 */}
        {/* <Hidden mdUp>
          <ScrollMenuMobile 
          sections={sections} 
          selectedIndex={selectedIndexMobile} 
          setSelectedIndex={setSelectedIndexMobile} />
        </Hidden> */}
        {/**Section 6 */}
        <section className={classes.section} id="School">
          <SchoolSection />
        </section>
        {/**End Section 6 */}
      </Grid>
    </Grid>
    {/* </div> */}
    </Container>
  </>;
};

export default WeldingTraining;