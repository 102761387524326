import request from "./base";
import { excludeObjNullValue } from "../utils/common";

export const login = (payload) =>
  request.postJSON(
    "/login",
    excludeObjNullValue({
      email: payload.email,
      password: payload.password,
    })
  );

export const signup = (payload) => {
  const processedPayload = excludeObjNullValue({
    first_name: payload.first_name,
    last_name: payload.last_name,
    email: payload.email,
    password: payload.password,
    city: payload.city,
    province: payload.province,
    postcode: payload.postcode,
    gender: payload.gender,
    age_range: payload.age_range,
    designated_groups: payload.designated_groups,
    country: payload.country,
    agree: payload.agree,
    qualification_before: payload.qualification_before,
    opt_in_email: payload.opt_in_email,
  });

  console.log("Processed Payload:", processedPayload); // Log the processed payload
  return request.postJSON("/signup", processedPayload);
};

export const getCurrentUser = () => request.getJSON("/currentuser");

export const changePassword = (newPassword, oldPassword) =>
  request.postJSON("/changepassword", {
    password: newPassword,
    old_password: oldPassword,
  });

export const changePasswordWithToken = (newPassword, token) =>
  request.postJSON("/passwordreset", {
    password: newPassword,
    token: token,
  });

export const emailPasswordReset = (emailAddress) =>
  request.getJSON("/forgotpassword", { email: emailAddress });

export const verifyToken = (token) =>
  request.postJSON("/verifyToken", { token });
