import React from 'react'
import { 
    Grid,
    Typography,
    Box
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import {getProvinceFromId} from '../data';
import WelderBox from '../../../components/WelderBox'
import RedSeal from '../../../components/RedSeal';
import ListBox from '../../../components/ListBox';
import ArrowBox from '../../../components/ArrowBox';
import {useTranslation, Trans} from "react-i18next";


const useStyles = makeStyles((theme) => ({
    root: {
    },
    container: {
        backgroundColor: theme.palette.common.white,
        padding:theme.spacing(1),
    },
    boxWrapper: {
        display:'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]:{
            flexDirection: 'column'
        }
    },
    list:{
        listStyleType: 'lower-alpha'
    }
}));


const QC = () => {
    const classes = useStyles();
    const {t} = useTranslation('regulation');
    const listData = t('pathways.processList3', {returnObjects: true});
    return <>  
        <div className={classes.container}>
            {/**Section 1 */}
            <Box my={8}>
                <Box mb={3} p={1}>
                    <Typography variant="h5" align="center" gutterBottom color="secondary">
                        <b>{t('pathways.QC.section1.title')}</b>
                    </Typography>
                </Box>
                <Grid container justifyContent="center" alignItems="center" spacing={1}> 
                    <ArrowBox>
                        <WelderBox>
                            <Typography variant="body1" align="center" gutterBottom>
                                <Trans i18nKey="regulation:pathways.QC.section1.Box1.text1">
                                In Québec, there are study programs leading to a <u>vocational training diploma (DEP) </u>
                                for all the <u>trades</u> and some specialized occupations in the construction industry. 
                                These programs simulate the conditions that students will later find on construction sites. 
                                The duration and location of the programs varies by trade and occupation. Graduates benefit from <u>privileged access</u> to the construction industry.
                                </Trans>

                            </Typography>
                            <Typography variant="body1" align="center" gutterBottom>
                            <Trans i18nKey="regulation:pathways.QC.section1.Box1.text2">
                                Through these programs, people have access to the construction industry as <u>apprentices with a diploma</u> for each of the trades. 
                            </Trans>
                            
                            </Typography>
                            <Typography variant="body1" align="center" gutterBottom>
                                {t('pathways.QC.section1.Box1.text3')}
                            </Typography>
                        </WelderBox>
                    </ArrowBox>
                    <ArrowBox>
                        <WelderBox>
                            <Box py={1}>
                                <Typography variant="h4" align="center">
                                    <b>{t('pathways.pOfEdPreq')}</b>   
                                </Typography>    
                            </Box>
                            <Box p={1} bgcolor="common.white" borderRadius="1rem">
                                <Typography variant="body1" align="left" gutterBottom>
                                    {t('pathways.QC.section1.Box2')}
                                </Typography>
                            </Box>
                        </WelderBox>
                    </ArrowBox>
                    <ArrowBox>
                        <WelderBox>
                            <Typography variant="body1" align="left" gutterBottom>
                                {t('pathways.QC.section1.Box3.listHeader')}
                            </Typography>
                            <ol className={classes.list}>
                            {
                                t('pathways.QC.section1.Box3.list', {returnObjects: true}).map((item, idx) => (
                                    <li key={idx}>
                                    <Typography variant="body1" align="left" gutterBottom>
                                        {item}
                                    </Typography>
                                </li>
                                ))
                            }
                            </ol>
                            <Box p={1} bgcolor="common.white" borderRadius="1rem">
                                <Typography variant="body1" align="left" gutterBottom>
                                <b> {t('pathways.QC.section1.Box3.onJobTraining')}</b>              
                                </Typography>
                                <Typography variant="body1" align="left" gutterBottom>
                                {t('pathways.QC.section1.Box3.accumulateHours')}
                                </Typography>
                            </Box>

                        </WelderBox>
                    </ArrowBox>
                    <Grid item md={3}>
                        <WelderBox>
                            <Typography variant="body1" align="center">
                            {t('pathways.QC.section1.Box4.text1')}
                            </Typography>
                            <Typography variant="h4" align="center"> 
                                <b>{t('pathways.QC.section1.Box4.text2')}</b>
                            </Typography>
                        </WelderBox>   
                    </Grid>
                </Grid>
            </Box>  

            {/**Section 2 */}
            <Box my={8}>
                <Box mb={3} p={1}>
                    <Typography variant="h5" align="center" gutterBottom color="secondary">
                        <b>{t('pathways.QC.section2.title')}</b>
                    </Typography>
                    <Typography variant="body1" align="left" gutterBottom>
                        {t('pathways.challExam')}
                        <br />
                        {t('pathways.expWorkersText')}
                    </Typography>

                </Box>
                <Grid container justifyContent="center" alignItems="center" spacing={1}> 
                    
                    <ArrowBox mdCols={2}>
                        <WelderBox 
                            subtitle={t('pathways.QC.section2.Box1')}
                        />
                    </ArrowBox>
                    <ArrowBox mdCols={2}>
                        <WelderBox 
                            subtitle={t('pathways.QC.section2.Box2')}
                        />
                    </ArrowBox>
                    <ArrowBox mdCols={2}>
                        <WelderBox 
                            subtitle={t('pathways.QC.section2.Box3.title')}
                            secondarySubtitle={t('pathways.QC.section2.Box3.body')}
                        />
                    </ArrowBox>

                    <ArrowBox mdCols={2}>

                        <ListBox 
                            title={t('pathways.pOfCompProcess')}
                            list={listData}
                            
                        />

                        <Box my={6}>
                            <Typography variant="body1" color="secondary">
                                <b>{t('pathways.or')}</b>
                            </Typography>
                        </Box>

                        <WelderBox 
                            subtitle={t('pathways.ticketsHeader')}
                            secondarySubtitle={t('pathways.ticketsBody')}
                        />
                    </ArrowBox>
                    


                    <RedSeal mdCols={2}/>
                </Grid>
            </Box>
        </div>
       
    </>;
}

export default QC
