import React from 'react'
import { 
    Box,
    Button,
    Typography,
    Grid,
    useMediaQuery,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import GetAppIcon from '@mui/icons-material/GetApp';
import { useTheme } from '@mui/material/styles';
import {useTranslation} from "react-i18next";
import OverviewListItem from './OverviewListItem';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    listSection: {
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: '1rem',
        '& li:nth-last-child(1)': {
            borderBottomStyle: 'none',
        }
    },
    bottomContainer: {
        [theme.breakpoints.down('sm')]:{
            flexDirection:'column-reverse',
        }
    }
}));


const OverViewList = ({data}) => {
    const { t } = useTranslation('regulation');
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {items, totalHours, downloadLink} = data;
    return <>
    <Box mb={3} className={classes.listSection}>
        {
            items.map((item, idx) => {
                return (
                    <OverviewListItem 
                        key={idx}
                        title = {item.title}
                        body={item.body}
                        hours={item.hours}
                    />
                )
            })
        }
    </Box>
    <Box>
        <Grid container spacing={2} justifyContent="space-between" alignItems="center" className={classes.bottomContainer}>
            {downloadLink &&
            <Grid item xs={12} sm={6}>
                <Button
                    startIcon={<GetAppIcon />} 
                    color="primary" 
                    size="large"
                    variant="contained" 
                    disableElevation
                    href={downloadLink}
                    target="_blank"
                >
                    <Typography variant="h5">
                        {t('section6.downloadText')}
                    </Typography>
                </Button>
            </Grid>
            }
            
            {totalHours &&
            <Grid item xs={12} sm={6}>
                <Typography variant="body1" align={isMobile? 'center': 'right'}>    
                    <b>{totalHours}</b>
                </Typography>
            </Grid>
            }
        </Grid>
    </Box>    

    </>;
}

export default OverViewList;