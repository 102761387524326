import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  Box,
  Container,
  CircularProgress,
  Backdrop,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useAuth } from "../components/useAuth";
// import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ListAssessments from "../components/ListAssessments";
import ListAssessmentsMobile from "../components/ListAssessmentsMobile";
import ListAssessmentsXL from "../components/ListAssessmentsXL";

const useStyles = makeStyles((theme) => ({
  iframeContainer: {
    height: "100%",
  },
  backdrop: {
    zIndex: 1000,
    backgroundColor: `rgb(255, 255, 255, 1)`,
  },
  buttonRoot: {
    textTransform: "none",
    borderRadius: "0.8rem",
    padding: "4px 24px",
    margin: theme.spacing(2, 0),
    width: "200px",
    textAlign: "center",
  },
  redText: {
    color: "red",
  },
}));
const Dashboard = (props) => {
  const classes = useStyles();
  const { token, user } = useAuth();
  const { t, i18n } = useTranslation(["startAssessment", "error"]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isXLScreen = useMediaQuery(theme.breakpoints.down("xl"));

  return (
    <>
      {token && user ? (
        isSmallScreen ? (
          <ListAssessmentsMobile />
        ) : isXLScreen ? (
          <ListAssessmentsXL />
        ) : (
          <ListAssessments />
        )
      ) : (
        <Container maxWidth="lg">
          <Box my={3}>
            <Typography variant="h3" align="center">
              {t("error:loginRequired")}
            </Typography>
          </Box>
        </Container>
      )}
    </>
  );
};

export default Dashboard;
