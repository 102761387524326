import React from 'react';
import { 
    Container,
    Grid, 
    Typography, 
    Box,
    Avatar,
    Button
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import peopleImage1 from '../assets/programInformation/peopleImage1.png';
import peopleImage2 from '../assets/programInformation/peopleImage2.png';
import peopleImage3 from '../assets/programInformation/peopleImage3.png';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {useNavigate} from 'react-router-dom';

const success = [
    {image: peopleImage1, name: 'Ahmed Elsayed', body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore et dolorum harum eligendi alias enim similique, maxime doloribus quisquam libero sunt accusantium quasi quas amet illo laboriosam tempora nesciunt sapiente?'},
    {image: peopleImage2, name: 'Ahmed Elsayed', body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore et dolorum harum eligendi alias enim similique, maxime doloribus quisquam libero sunt accusantium quasi quas amet illo laboriosam tempora nesciunt sapiente?'},
    {image: peopleImage3, name: 'Ahmed Elsayed', body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore et dolorum harum eligendi alias enim similique, maxime doloribus quisquam libero sunt accusantium quasi quas amet illo laboriosam tempora nesciunt sapiente?'}
];



const useItemStyles = makeStyles((theme) => ({
    listItem: {
        listStyle: 'none',
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '1rem',
        padding: theme.spacing(2),

        [theme.breakpoints.down('md')]: {
            borderRadius: '1rem',
            border: 'none',
            backgroundColor: '#E8EDEF',
            marginBottom: theme.spacing(3),
        }
    },
    avatar: {
        height: '100px',
        width: '100px',
    },

}));


const Item = ({image, name, body}) => {
    const classes = useItemStyles();
    return (
        <Box className={classes.listItem} component="li" my={2}>
            <Grid container spacing={2} justifyContent="center" alignItems="center" style={{margin: 0,width: '100%',}}> 
                <Grid item xs={12} sm={3}>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Avatar alt={name} src={image} className={classes.avatar}></Avatar>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Typography variant="body2" align="left" gutterBottom>
                        {body}
                    </Typography>
                    <Typography variant="body2" color="secondary" align="left" gutterBottom>
                        - {name}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}
 
const useStyles = makeStyles((theme) => ({
    buttonRoot: {
        textTransform: 'none',
    }
}));



const SuccessStories = () => {
    const classes = useStyles();
    let history = useNavigate();
    const handleBack = () => {
        history.goBack();
    }
    return (
        <Container maxWidth="lg">

            <Box my={3}>
                <Button 
                    startIcon={<ArrowBackIosIcon/>}
                    className={classes.buttonRoot}
                    onClick={handleBack}
                >
                    Back
                </Button>
            </Box>
            <Box my={3}>
                <Typography variant="h3" align="center">
                    Success Stories
                </Typography>
            </Box>

            <Box>
                {
                    success.map((data, idx) => (
                        <Item 
                            key={idx}
                            image={data.image}
                            name={data.name}
                            body={data.body}
                        />
                    ))
                }
            </Box>
        </Container>
    )
}

export default SuccessStories
