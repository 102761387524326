import React from 'react'
import { Grid, Button, Typography, Box, Link } from "@mui/material";
import { makeStyles } from '@mui/styles';
import trainingImage8 from '../../assets/training/training-image8.png';
import iconDo from '../../assets/icons/icon-do.png';
import {useTranslation} from "react-i18next";
const useStyles = makeStyles((theme) => ({
    sectionImage: {
        // backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '250px',
        width:'100%',
        margin: '0 auto'
    },
    paddingSmall: {
        [theme.breakpoints.down('md')]:{
            paddingRight: '12px',
            paddingLeft: '12px',
        }
    },

    imageSection: {
        [theme.breakpoints.down('sm')]:{
          flexDirection: 'column-reverse',
        }
    },
    imageContainer: {
        width: '100%',
    },
    bodyImage: {
        width: '100%',
        height: 'auto',
    },
    buttonRoot: {
        textTransform: 'none',
        borderRadius: '0.8rem',
        padding: '4px 24px',
        margin:'0 auto',
    },
    link: {
        textDecoration: 'underline'
    },
    logoContainer: {
        flex: '0 1 40px',
    },
    logoImage: {
        height: '40px',
        width: '40px',
        marginRight: theme.spacing(2),
    }
}));


const TestingSection = () => {
    const classes = useStyles();
    const { t } = useTranslation(['weldingTraining', 'common']);

    return <>
    <div className={classes.paddingSmall}>
        <Box mb={3}>
            <Typography variant="h2" align="left" gutterBottom>
                {t('section4.header')} 
            </Typography>
        </Box>
        <Box  mb={2}>
            <Typography variant="body1" align="left" gutterBottom>
                {t('section4.text1')}
            </Typography>
        </Box>
        <Grid container spacing={3} justifyContent="center" alignItems="flex-start" className={` ${classes.imageSection}`}>
            
            <Grid item xs={12} md={6}>
                
                <Typography variant="body1" color="inherit" align="left" paragraph>
                    {t('section4.text2')}
                </Typography>
                <Box my={4}>
                    {/* <Link className={classes.link} href="https://www.cwbgroup.org/education/exams/examination-centres" target="_blank" color="secondary">CWB site - Welding Testing Center</Link> */}
                    <Grid container spacing={3} justifyContent="flex-start" alignItems="center">
                        <Grid item  xs={4} className={classes.logoContainer}>
                            <img src={iconDo} alt={t('section4.iconAlt')} className={classes.logoImage}/>
                        </Grid>

                        <Grid item  xs={8}>
                            <Box mb={1}>
                                <Typography variant="body1" color="inherit" align="left">
                                    {t('section4.linkText')}
                                </Typography>
                            </Box>
                            <Button 
                                color="primary" 
                                size="small" 
                                variant="contained" 
                                target="_blank" 
                                href="https://www.cwbgroup.org/education/exams/examination-centres"
                                disableElevation
                                className={classes.buttonRoot}
                            >
                                <Typography variant="h5">
                                    {t('common:buttons.viewWebsite')}
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                
            </Grid>

            <Grid item xs={12} md={6}>
                <img className={classes.bodyImage} alt={t('section4.imageAlt')} src={trainingImage8} />   
            </Grid>

            
        </Grid>
        <Box mt={3} mb={3}>
            <Typography variant="body1" align="left" gutterBottom>
                {t('section4.text3')}
            </Typography>
        </Box>
    </div>
    </>;
}

export default TestingSection;
