import React, { useState } from "react";
import { NavLink as Link, useLocation, useNavigate } from "react-router-dom";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Container,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useAuth } from "../useAuth";
import iconCwbGroup from "../../assets/icons/icon-cwb-group.png";
import iconArrowDown from "../../assets/icons/icon-arrow-down.png";
import iconArrowUp from "../../assets/icons/icon-arrow-up.png";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useTranslation } from "react-i18next";
import LanguageSelect from "./LanguageSelect";

const DesktopMenu = (props) => {
  let currentLocation = useLocation();
  const navigate = useNavigate();
  const {
    location,
    setOpenPopup,
    language,
    languageOptions,
    handleLanguageChange,
  } = props;
  const welderActive =
    location === "/international-welder-requirements-work-canada" ||
    location === "/welder-requirements-across-canada" ||
    location === "/welding-training-canada";
  const careerActive =
    location === "/search-welding-jobs-canada" || location === "/hire-welder";
  const classes = useStyles();
  const [anchorElWelder, setAnchorElWelder] = React.useState(null);
  const [anchorElCareer, setAnchorElCareer] = React.useState(null);
  const [anchorElProfile, setAnchorElProfile] = React.useState(null);
  const { user, signout, token } = useAuth();

  const { t } = useTranslation("common");

  const handleClickCareer = (event) => {
    setAnchorElCareer(event.currentTarget);
  };
  const handleCloseCareer = () => {
    setAnchorElCareer(null);
  };
  const handleClickWelder = (event) => {
    setAnchorElWelder(event.currentTarget);
  };
  const handleCloseWelder = () => {
    setAnchorElWelder(null);
  };
  const handleMenu = (event) => {
    setAnchorElProfile(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElProfile(null);
  };
  const isTablet = useMediaQuery("(max-width:1200px)");

  return (
    <Container maxWidth="xl" className={classes.wrapper}>
      <div className={classes.root}>
        <nav className={classes.container}>
          <Box>
            <Link className={classes.link} to="/">
              <img className={classes.logo} src={iconCwbGroup} alt="CWB Logo" />
            </Link>
          </Box>
          <Box
            className={classes.linksWrapper}
            justifyContent="space-between"
            alignItems="center"
            flexWrap="nowrap"
          >
            <List className={classes.list}>
              {/* <ListItem
                component={Link}
                to="/welder-competency-credential-assessment-program-information"
                className={`${classes.listItem} ${classes.link}`}
                style={
                  location ===
                  "/welder-competency-credential-assessment-program-information"
                    ? { borderBottom: "0.15rem solid #0C89BC" }
                    : {}
                }
              >
                <ListItemText
                  primary={t("topMenu.header1.title")}
                  className={`${classes.listItemText}`}
                />
              </ListItem> */}
              <ListItem
                onClick={handleClickWelder}
                className={`${classes.listItem} ${
                  welderActive ? classes.activeLink : null
                }`}
                aria-controls="simple-menu"
                aria-haspopup="true"
                sx={{ marginLeft: "1em" }}
              >
                <ListItemText
                  primary={t("topMenu.header2.title")}
                  className={`${classes.listItemText}`}
                />
                {anchorElWelder ? (
                  <IconButton size="small">
                    <img
                      className={classes.iconArrow}
                      src={iconArrowUp}
                      alt="arrow up"
                    />
                  </IconButton>
                ) : (
                  <IconButton size="small">
                    <img
                      className={classes.iconArrow}
                      src={iconArrowDown}
                      alt="arrow down"
                    />
                  </IconButton>
                )}
              </ListItem>
              <Menu
                id="simple-menu"
                anchorEl={anchorElWelder}
                keepMounted
                open={Boolean(anchorElWelder)}
                onClose={handleCloseWelder}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <MenuItem
                  onClick={handleCloseWelder}
                  component={Link}
                  to="/international-welder-requirements-work-canada"
                  className={`${classes.link} ${classes.menuLink}`}
                >
                  {t("topMenu.header2.subHeader1")}
                </MenuItem>
                <MenuItem
                  onClick={handleCloseWelder}
                  component={Link}
                  to="/welder-requirements-across-canada"
                  className={`${classes.link} ${classes.menuLink}`}
                >
                  {t("topMenu.header2.subHeader2")}
                </MenuItem>
                {/* <MenuItem
                  onClick={handleCloseWelder}
                  component={Link}
                  to="/welding-training-canada"
                  className={`${classes.link} ${classes.menuLink}`}
                >
                  {t("topMenu.header2.subHeader3")}
                </MenuItem> */}
              </Menu>
              <ListItem
                component={Link}
                to="/welders-certifications-requirements-canada"
                className={`${classes.listItem} ${classes.link}`}
                style={
                  location === "/welders-certifications-requirements-canada"
                    ? { borderBottom: "0.15rem solid #0C89BC" }
                    : {}
                }
              >
                <ListItemText
                  primary={t("topMenu.header3.title")}
                  className={`${classes.listItemText}`}
                />
              </ListItem>
              {/* <ListItem
                component={Link}
                to="/welder-competency-credential-assessment-information"
                className={`${classes.listItem} ${classes.link}`}
                style={
                  location ===
                  "/welder-competency-credential-assessment-information"
                    ? { borderBottom: "0.15rem solid #0C89BC" }
                    : {}
                }
              >
                <ListItemText
                  primary={t("topMenu.header4.title")}
                  className={`${classes.listItemText}`}
                />
              </ListItem> */}
              <ListItem
                onClick={handleClickCareer}
                className={`${classes.listItem} ${
                  careerActive ? classes.activeLink : null
                }`}
              >
                <ListItemText
                  primary={t("topMenu.header5.title")}
                  className={`${classes.listItemText}`}
                />
                {anchorElCareer ? (
                  <IconButton size="small">
                    <img
                      className={classes.iconArrow}
                      src={iconArrowUp}
                      alt="arrow up"
                    />
                  </IconButton>
                ) : (
                  <IconButton size="small">
                    <img
                      className={classes.iconArrow}
                      src={iconArrowDown}
                      alt="arrow down"
                    />
                  </IconButton>
                )}
              </ListItem>
              <Menu
                anchorEl={anchorElCareer}
                keepMounted
                open={Boolean(anchorElCareer)}
                onClose={handleCloseCareer}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <MenuItem
                  onClick={handleCloseCareer}
                  component={Link}
                  to="/search-welding-jobs-canada"
                  className={`${classes.link} ${classes.menuLink}`}
                >
                  {t("topMenu.header5.subHeader1")}
                </MenuItem>
              </Menu>
              <ListItem
                component={Link}
                to="/previous-programs"
                onClick={handleClose}
                className={`${classes.listItem} ${classes.link}`}
                style={
                  location ===
                  "/previous-programs"
                    ? { borderBottom: "0.15rem solid #0C89BC" }
                    : {}
                }
              >
                <ListItemText
                  primary={t("topMenu.header6.title")}
                  className={`${classes.listItemText}`}
                  //sx={{ maxWidth: "15ch" }}
                />
              </ListItem>
            </List>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="nowrap"
            className={`${classes.buttonWrapper} ${
              isTablet ? classes.buttonWrapperTablet : ""
            }`}
          >
            {token ? (
              <Box>
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  className={`${classes.itemMargin} ${classes.buttonRoot}`}
                  onClick={handleMenu}
                >
                  <Typography variant={"body1"} mr={1}>
                    <b>{t("topMenu.profileBtn")}</b>
                  </Typography>
                  <AccountCircle />
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElProfile}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElProfile)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={(e) => {
                        handleClose(e);
                      }}
                      component={Link}
                      to="/dashboard"
                    >
                      {t("topMenu.dashboard")}
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => {
                        handleClose(e);
                      }}
                      component={Link}
                      to="/my-account"
                    >
                      {t("topMenu.myAccount")}
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => {
                        signout();
                        handleClose(e);
                      }}
                    >
                      {t("topMenu.logoutBtn")}
                    </MenuItem>
                  </Menu>
                </Button>
              </Box>
            ) : (
              <Button
                color="primary"
                variant="contained"
                disableElevation
                className={classes.buttonRoot}
                onClick={() => {
                  setOpenPopup(true);
                  navigate("/signup", {
                    state: { background: currentLocation },
                  });
                }}
                sx={{ fontWeight: "bold", fontSize: "1em" }}
              >
                {t("topMenu.signupBtn")}
              </Button>
            )}
            <div className={`${classes.itemMargin} ${classes.lanContainer}`}>
              <LanguageSelect
                language={language}
                handleLanguageChange={handleLanguageChange}
                languageOptions={languageOptions}
              />
            </div>
          </Box>
        </nav>
      </div>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingRight: "0px",
    paddingLeft: "0px",
  },
  list: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    padding: "0 1em"
  },
  listItem: {
    padding: "0",
    margin: "0",
    "&::after": {
      content: '"|"',
      color: theme.palette.secondary.main,
    },
    "&:last-child::after": {
      display: "none",
    },
  },
  listItemText: {
    cursor: "pointer",
    maxWidth: "22ch",
    "& span": {
      textAlign: "center",
      fontSize: "1em",
      fontWeight: "bold",
      lineHeight: 1,
    },
  },
  menuLink: {
    fontSize: "1em",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  link: {
    color: "black",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      color: "black",
    },
  },
  lanContainer: {
    fontSize: "1em",
    alignItems: "center",
  },
  language: {
    color: "black",
    textDecoration: "none",
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(1, 0),
  },
  title: {
    flexGrow: 1,
  },
  itemMargin: {
    margin: theme.spacing(0, 1.5),
  },
  logo: {
    height: "120px",
    width: "120px",
  },
  activeLink: {
    borderBottom: `0.15rem solid ${theme.palette.secondary.main}`,
  },
  buttonRoot: {
    //fontSize: "20px",
    textTransform: "none",
    borderRadius: "0.8rem",
    //padding: "8px",
    //margin: theme.spacing(0, 1.5),
    lineHeight: 1.5,
    textAlign: "center",
  },
  iconArrow: {
    width: "20px",
    height: "20px",
  },
  linkUnderline: {
    borderBottom: `0.15rem solid ${theme.palette.secondary.main}`,
  },
  buttonWrapper: {
    flexDirection: "row",
  },
  buttonWrapperTablet: {
    flexDirection: "column",
  },
}));

export default DesktopMenu;
