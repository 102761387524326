import React, {useState} from 'react'
import { 
    Grid,
    TableBody,
    TableRow,
    TableCell,
    Link,
    Typography,
    Box,
    Collapse,
    IconButton,
    useTheme,
    useMediaQuery,
    Container
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import {getProvinceFromId} from './data';
import useTable from '../../components/useTable';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {useTranslation} from "react-i18next";


const useRowStyles = makeStyles((theme) => ({

    innerRow: {
        padding: theme.spacing(1, 0),
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
        backgroundColor: theme.palette.secondary.light,
    },
    wrapperInner: {
        '& .MuiGrid-root:nth-last-child(1)': {
            borderBottom: 'none'
        }
    },
    link: {
        textDecoration: 'underline'
    }
  }));
function MobileRow({data}) {
    const { t } = useTranslation('regulation');
    const { name, description, type, link } = data;
    const [open, setOpen] = useState(false);
    const classes = useRowStyles();

    return <>
        <TableRow style={!open ? { borderBottom: 'none' }: {}}>
            <TableCell  colSpan={9} >
                <Typography align="left" variant="body2" color="secondary">
                    {name}
                </Typography>
            </TableCell>
            <TableCell align="right" colSpan={3} >
                <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                    {open ? <KeyboardArrowUpIcon color="secondary"/> : <KeyboardArrowDownIcon color="secondary"/>}
                </IconButton>
            </TableCell>
        </TableRow>
        <TableRow >
            <TableCell style={{ padding:0}} colSpan={12} >
                <Collapse in={open} timeout="auto" unmountOnExit classes={{wrapperInner: classes.wrapperInner}}>

                        <Grid container justifyContent="flex-start" alignItems="center" >
                            {
                                description &&
                                <>
                                    <Grid item xs={12} className={classes.innerRow}>
                                        <Box px={2}>
                                        <Typography variant="subtitle2" align="left" >
                                            <b>{t('section6.contactsTableHeaders.description')}</b>
                                        </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} className={classes.innerRow}>
                                        <Box px={2}>
                                            <Typography variant="subtitle2" align="left">
                                            {description}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </>
                            }
                            {
                                type &&
                                <>
                                    <Grid item xs={12} className={classes.innerRow}>
                                        <Box px={2}>
                                        <Typography variant="subtitle2" align="left" >
                                            <b>{t('section6.contactsTableHeaders.type')}</b>
                                        </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} className={classes.innerRow}>
                                        <Box px={2}>
                                            <Typography variant="subtitle2" align="left">
                                            {type}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </>
                            }
                            {
                                link &&
                                <>
                                    <Grid item xs={12} className={classes.innerRow}>
                                        <Box px={2}>
                                        <Typography variant="subtitle2" align="left" >
                                            <b>{t('section6.contactsTableHeaders.link')}</b>
                                        </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} className={classes.innerRow}>
                                        <Box px={2}>
                                            <Typography variant="subtitle2" align="left">
                                            <Link
                                                className={classes.link}
                                                color="inherit"
                                                href={link}
                                                target="_blank"
                                                underline="hover">{link}</Link>
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </>
                            }
                            
                        </Grid>

                </Collapse>
            </TableCell>
        </TableRow>
    </>;

}

const useStyles = makeStyles((theme) => ({
    link: {
        textDecoration: 'underline',
    },
}));
const ContactSection  = ({province}) => {
    const classes = useStyles();
    const { t } = useTranslation('regulation');
    const provinceData = t('section6.data', {returnObjects: true}).find(item => item.id === province)
    const contacts = provinceData?.contacts;
    const contactsCount = contacts.length;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const headCells = [
        {id: 'name', label: t('section6.contactsTableHeaders.name')},
        {id: 'description', label: t('section6.contactsTableHeaders.description')},
        {id: 'type', label: t('section6.contactsTableHeaders.type')},
        {id: 'link', label: t('section6.contactsTableHeaders.link')},
    ];
    
    const { TblContainer, TblHead } = useTable(headCells);
    return (
        <Container maxWidth="xl">  
        {
            contactsCount !==0 ? (
         
            <Grid item xs={12}>
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {
                            !isMobile &&
                            contacts.map((item, idx) => 
                                (<TableRow key={idx}>
                                    <TableCell><Typography align="center" variant="body1">{item.name}</Typography></TableCell>
                                    <TableCell><Typography align="center" variant="body1">{item.description}</Typography></TableCell>
                                    <TableCell><Typography align="center" variant="body1">{item.type}</Typography></TableCell>
                                    <TableCell><Typography align="center" variant="body1"><Link
                                        className={classes.link}
                                        color="secondary"
                                        href={item.link}
                                        target="_blank"
                                        underline="hover">{item.link}</Link></Typography></TableCell>
                                </TableRow>))
                        }

                        {
                            isMobile &&
                            contacts.map((item, idx) => 
                                <MobileRow key={idx} data={item} />
                            )

                        }
                    </TableBody>
                </TblContainer>
            </Grid>
            ) : (
                <Grid item xs={12}>
                <Box my={3}>
                    <Typography align="left" variant="h6" gutterBottom>
                        {t('section6.noContactsText')}
                    </Typography>
                </Box>
            </Grid>
            )
        }
        </Container>
    );
}

export default ContactSection
