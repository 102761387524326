import React from 'react'
import { 
    Typography,Box,Button
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import GetAppIcon from '@mui/icons-material/GetApp';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    list: {
        listStyle: 'lower-latin'
    }
}));
const NT = ({data}) => {
    const {downloadLink, text1, listHeader1, list1, listHeader2,list2, listHeader3,list3,text2,downloadText } = data
    const classes = useStyles();
    const { t } = useTranslation('regulation');
    return (
        <>
            <Box my={3}>
                <Box my={3}>
                    <Typography variant="body1" gutterBottom>
                        {text1}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                    {listHeader1}
                    </Typography>
                    <ol className={classes.list}>
                        {
                            list1.map((item, idx )=> (
                                <li key={idx}>
                                    <Typography variant="body1" gutterBottom>
                                        {item}
                                    </Typography>
                                </li>
                            ))
                        }
                    </ol>
                </Box>
                <Box my={3}>
                    
                        <Typography variant="h4" gutterBottom>
                            <b>{listHeader2}</b>
                        </Typography>
                        <ul>
                            {
                                list2.map((item, idx) =>
                                <li key={idx}>
                                    <Typography variant="body1" gutterBottom>
                                        {item}
                                    </Typography>
                                </li>
                                )
                            }
                        </ul>
                </Box>
                    
                <Box my={3} >
                    <Typography variant="h4" gutterBottom>
                        <b>{listHeader3}</b>
                    </Typography>
                    <ul>
                        {
                            list3.map((item, idx) =>
                            <li key={idx}>
                                <Typography variant="body1" gutterBottom>
                                    {item}
                                </Typography>
                            </li>
                            )
                        }
                    </ul>
                </Box>
                <Typography variant="body1" gutterBottom>
                    {text2}
                </Typography>
                <Box my={3}>
                    <Typography variant="body1" gutterBottom>
                        {downloadText}
                    </Typography>
                    <Button
                        startIcon={<GetAppIcon />} 
                        color="primary" 
                        size="large"
                        variant="contained" 
                        disableElevation
                        href={downloadLink}
                        target="_blank"
                    >
                        <Typography variant="h5">
                        {t('section6.downloadText')}
                        </Typography>
                    </Button>
                </Box>
            </Box>
            
        </>
    )
}

export default NT
