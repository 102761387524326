// src/components/useAuth.js
import React, { useState, useEffect, useContext, createContext } from "react";
import * as api from "../api/account";
import { setAuthInfo, removeAuthInfo } from "../api/base";
import { useNavigate } from "react-router";

export const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();

  const signin = (email, password) => {
    return api.login({ email, password }).then((response) => {
      if (response.reset) {
        const error = new Error("Password reset required.");
        error.reset = true;
        throw error;
      }
      setAuthInfo(response.token);
      setToken(response.token);
      setUser(response.user);
      return response;
    });
  };

  const signup = (payload) => {
    return api.signup(payload).then((response) => {
      setAuthInfo(response.token);
      setToken(response.token);
      setUser(response.user);
      return response;
    });
  };

  const signout = () => {
    return new Promise((resolve) => {
      removeAuthInfo();
      setUser(null);
      setToken(null);
      navigate("/");
      resolve();
    });
  };

  const getCurrentUser = () => {
    return api
      .getCurrentUser()
      .then((response) => {
        setUser(response);
      })
      .catch(() => {
        signout();
      });
  };

  useEffect(() => {
    let savedToken = localStorage.getItem("cwbJwt");
    if (savedToken) {
      setAuthInfo(savedToken);
      getCurrentUser();
      setToken(savedToken);
    }
  }, [token]);

  return {
    getCurrentUser,
    setToken,
    token,
    user,
    signup,
    signin,
    signout,
  };
}
