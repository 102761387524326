// import { createRoot } from 'react-dom/client';
import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { ProvideAuth } from "./components/useAuth.js";
import { CssBaseline } from "@mui/material";
import { BrowserRouter as Router } from "react-router-dom";
import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material/styles";
// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';

// if (process.env.NODE_ENV === 'production') {
// 	Sentry.init({
// 		dsn: process.env.REACT_APP_SENTRY_URL,
// 		autoSessionTracking: true,
// 		integrations: [new Integrations.BrowserTracing()],

// 		// We recommend adjusting this value in production, or using tracesSampler
// 		// for finer control
// 		tracesSampleRate: 1.0,
// 	});
// }

const defaultTheme = createTheme({});
const { breakpoints } = defaultTheme;

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 420,
      sm: 480,
      md: 640,
      mdlg: 769,
      lg: 1024,
      xl: 1280,
    },
  },
  palette: {
    primary: {
      dark: "#b29700",
      light: "#ffdf33",
      main: "#FFD800",
    },
    secondary: {
      dark: "#085f83",
      light: "#E8EDEF",
      main: "#0E89BC",
    },
    tertiary: {
      dark: "#000000",
      light: "#E8EDEF",
      main: "#FFFFFF",
    },
    text: {
      primary: "#000",
      secondary: "#fff",
    },
    background: {
      paper: "#FFF",
    },
  },
  typography: {
    fontFamily: "'Calibri', sans-serif",
    h1: {
      fontSize: "3rem",
      fontWeight: 400,
      [breakpoints.down("md")]: {
        fontSize: "1.875rem",
      },
      [breakpoints.down("sm")]: {
        fontSize: "1.5rem",
      },
    },
    h2: {
      fontSize: "2.25rem",
      fontWeight: 300,
      [breakpoints.down("md")]: {
        fontSize: "1.5rem",
      },
    },
    h3: {
      fontSize: "2rem",
      fontWeight: 500,
      [breakpoints.down("md")]: {
        fontSize: "1.125rem",
      },
    },
    h4: {
      fontSize: "1rem",
      fontWeight: 400,
      [breakpoints.down("md")]: {
        fontSize: "0.75rem",
      },
    },
    h5: {
      fontSize: "1.125rem",
      fontWeight: 700,
      [breakpoints.down("md")]: {
        fontSize: "0.875rem",
      },
    },
    body1: {
      fontSize: "1.125rem",
      fontWeight: 400,
      [breakpoints.down("md")]: {
        fontSize: "1rem",
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Calibri';
          src: url('./fonts/calibri/calibrib.eot');
          src: url('./fonts/calibri/calibrib.eot?#iefix') format('embedded-opentype'),
              url('./fonts/calibri/calibrib.woff2') format('woff2'),
              url('./fonts/calibri/calibrib.woff') format('woff'),
              url('./fonts/calibri/calibrib.ttf') format('truetype'),
              url('./fonts/calibri/calibrib.svg#calibribold') format('svg');
          font-weight: bold;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'Calibri';
          src: url('./fonts/calibri/calibri.eot');
          src: url('./fonts/calibri/calibri.eot?#iefix') format('embedded-opentype'),
              url('./fonts/calibri/calibri.woff2') format('woff2'),
              url('./fonts/calibri/calibri.woff') format('woff'),
              url('./fonts/calibri/calibri.ttf') format('truetype'),
              url('./fonts/calibri/calibri.svg#calibriregular') format('svg');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'Calibri';
          src: url('./fonts/calibri/calibrii.eot');
          src: url('./fonts/calibri/calibrii.eot?#iefix') format('embedded-opentype'),
              url('./fonts/calibri/calibrii.woff2') format('woff2'),
              url('./fonts/calibri/calibrii.woff') format('woff'),
              url('./fonts/calibri/calibrii.ttf') format('truetype'),
              url('./fonts/calibri/calibrii.svg#calibriitalic') format('svg');
          font-weight: normal;
          font-style: italic;
          font-display: swap;
        }
        html, body {
          font-family: 'Calibri';
        }
      `,
    },
  },
});

// const root = createRoot(document.getElementById("root"));

// root.render(
//   <StyledEngineProvider injectFirst>
//     <ThemeProvider theme={theme}>
//       <CssBaseline />
//       <Router>
//         <ProvideAuth>
//           <App />
//         </ProvideAuth>
//       </Router>
//     </ThemeProvider>
//   </StyledEngineProvider>
// );

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <ProvideAuth>
          <App />
        </ProvideAuth>
      </Router>
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById("root")
);
