import React, { useLayoutEffect, useEffect, useState } from "react";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { CssBaseline } from '@mui/material';
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import About from "./pages/About";
import Assessment from "./pages/Assessment";
import Contact from "./pages/Contact";
import Faq from "./pages/Faq";
import FindJob from "./pages/FindJob";
import HireWelder from "./pages/HireWelder";
import InternationalWelders from "./pages/InternationalWelders";
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import ProgramInfo from "./pages/ProgramInfo";
import ProvinceToProvince from "./pages/ProvinceToProvince";
import Regulation from "./pages/Regulation";
import TermsAndConditions from "./pages/TermsAndConditions";
import WeldingTraining from "./pages/WeldingTraining";
//import Result from "./pages/Result";
import Dashboard from "./pages/Dashboard";
import SuccessStories from "./pages/SuccessStories";
//import TestAssessment from "./pages/TestAssessment";
import NotFound from "./pages/NotFound";
//import ReactGA from "react-ga";
//import TagManager from "react-gtm-module";
import CookieConsent from "./components/CookieConsent";
import Popup from "./components/Popup";
import SignupConfirm from "./pages/Signup/SignupConfirm";
import Signup from "./pages/Signup";
import ResetPassword from "./pages/ResetPassword";
//import ChangePassword from "./pages/ChangePassword";
import NewPassword from "./pages/NewPassword";
import BottomBar from "./components/BottomBar";
import { useAuth, ProvideAuth } from "./components/useAuth";
import i18n from "./i18n";
import SurveyComponent from "./components/SurveyComponent";
//import LogoutWarningPopup from "./components/LogoutWarningPopup";
import { setLicenseKey } from "survey-core";
//import useInactivityTimeout from "./hooks/useInactivityTimeout";
//import StartAssessment from "./components/StartAssessment";
import SetupProfile from "./pages/Signup/SetupProfile";
import axios from "axios";
import MyAccount from "./pages/MyAccount";
import PreviousPrograms from "./pages/PreviousPrograms";

setLicenseKey(process.env.SURVEY_JS_LICENSE_KEY);

// const trackingId = process.env.REACT_APP_GA_TRACKING_CODE;
// ReactGA.initialize(trackingId);

function Switch(props) {
  let location = useLocation();
  const { setOpenSignup, openSignup, setOpenSignupConfirm, openSignupConfirm } =
    props;
  const { user, token } = useAuth();
  const [language, setLanguage] = useState(i18n.language);
  const [showSetupProfile, setShowSetupProfile] = useState(false);

  // useEffect(() => {
  //   trackPageView();
  // }, [location]);

  // function trackPageView() {
  //   ReactGA.set({ page: window.location.pathname });
  //   ReactGA.pageview(window.location.pathname);
  // }

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const checkProfileSetup = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/check-profile`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setShowSetupProfile(!response.data.isProfileSetup);
      } catch (error) {
        console.error("Error checking profile setup:", error);
        setShowSetupProfile(false);
      }
    };

    if (user) {
      checkProfileSetup();
    } else {
      setShowSetupProfile(false);
    }
  }, [user, token]);

  const handleCloseSetupProfile = () => {
    setShowSetupProfile(false);
  };

  let background = location.state && location.state.background;

  return (
    <div>
      <Routes location={background || location}>
        <Route path="/" element={<Home />} />
        <Route path="/welder-competency-credential-assessment" element={<Navigate to="/" />} />
        <Route path="/about-cwb-welders-canada" element={<About />} />
        {/* <Route
          path="/welder-competency-credential-assessment-information"
          element={<Assessment />}
        /> */}
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/welders-work-canada-faq" element={<Faq />} />
        <Route path="/search-welding-jobs-canada" element={<FindJob />} />
        <Route path="/hireWelder" element={<HireWelder />} />
        <Route
          path="/international-welder-requirements-work-canada"
          element={<InternationalWelders />}
        />
        <Route path="/privacy" element={<Privacy />} />
        {/* <Route
          path="/welder-competency-credential-assessment-program-information"
          element={<ProgramInfo />}
        /> */}
        <Route
          path="/welder-requirements-across-canada"
          element={<ProvinceToProvince />}
        />
        <Route
          path="/welders-certifications-requirements-canada"
          element={<Regulation />}
        />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        {/* <Route path="/welding-training-canada" element={<WeldingTraining />} /> */}
        {/* <Route path="/result" element={<Result />} /> */}
        <Route path="/dashboard" element={<Dashboard />} />
        {/* <Route path="/test-assessment" element={<TestAssessment />} /> */}
        <Route path="/success-stories" element={<SuccessStories />} />
        <Route path="/reset" element={<ResetPassword />} />
        <Route path="/login/reset-password" element={<NewPassword />} />
        <Route path="/assessment/:surveyId" element={<SurveyComponent language={language} />} />
        {/* <Route path="/start-assessment" element={<StartAssessment />} /> */}
        <Route path="/my-account" element={<MyAccount />} />
        <Route path="/previous-programs" element={<PreviousPrograms />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {background && (
        <Routes>
          <Route
            path="/signup"
            element={
              <SignupModal
                setOpenSignup={setOpenSignup}
                openSignup={openSignup}
                setOpenSignupConfirm={setOpenSignupConfirm}
              />
            }
          />
          <Route
            path="/signup-confirm"
            element={
              <SignupConfirmModal
                openSignupConfirm={openSignupConfirm}
                setOpenSignupConfirm={setOpenSignupConfirm}
              />
            }
          />
        </Routes>
      )}
      {showSetupProfile && (
        <Popup openPopup={showSetupProfile} setOpenPopup={setShowSetupProfile}>
          <SetupProfile onClose={handleCloseSetupProfile} />
        </Popup>
      )}
    </div>
  );
}

const SignupModal = (props) => {
  const { openSignup, setOpenSignup, setOpenSignupConfirm } = props;
  return (
    <Popup openPopup={true} setOpenPopup={setOpenSignup}>
      <Signup
        setOpenPopup={setOpenSignup}
        setOpenSignupConfirm={setOpenSignupConfirm}
      />
    </Popup>
  );
};

const SignupConfirmModal = (props) => {
  const { setOpenSignupConfirm, openSignupConfirm } = props;
  return (
    <Popup openPopup={true} setOpenPopup={setOpenSignupConfirm}>
      <SignupConfirm setOpenPopup={setOpenSignupConfirm} />
    </Popup>
  );
};

function App() {
  const [cookieConsentOpen, setCookieConsentOpen] = React.useState(true);
  const [openSignup, setOpenSignup] = React.useState(false);
  const [openSignupConfirm, setOpenSignupConfirm] = React.useState(false);
  //const { signout, user } = useAuth();
  //const { isWarningVisible, isLoggedOut, resetTimeout } = useInactivityTimeout(60000, 10000); // 60 seconds timeout, 10 seconds warning

  // const handleStayLoggedIn = () => {
  //   resetTimeout();
  // };

  // useEffect(() => {
  //   if (isLoggedOut && user) {
  //     signout();
  //   }
  // }, [isLoggedOut, user, signout]);

  return (
    <ProvideAuth>
      <Navbar setOpenSignup={setOpenSignup} i18n={i18n} />
      <Switch
        setOpenSignup={setOpenSignup}
        openSignup={openSignup}
        setOpenSignupConfirm={setOpenSignupConfirm}
        openSignupConfirm={openSignupConfirm}
      />
      <Footer />
      <BottomBar />
      {cookieConsentOpen && (
        <Popup openPopup={true} setOpenPopup={setCookieConsentOpen}>
          <CookieConsent setOpen={setCookieConsentOpen} />
        </Popup>
      )}
      {/* {user && (
        <LogoutWarningPopup open={isWarningVisible} onStayLoggedIn={handleStayLoggedIn} onLogout={signout} />
      )} */}
    </ProvideAuth>
  );
}

export default App;
