import React, { useState } from "react";
import { Paper, Box, Container, Tabs, Tab } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import SetupProfile from "./SetupProfile";
import { useTranslation } from "react-i18next";
import Popup from "../../components/Popup";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
};

const Signup = (props) => {
  const [value, setValue] = React.useState(0);
  const [showSetupProfile, setShowSetupProfile] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const classes = useStyles();
  const { t } = useTranslation("common");
  
  return (
    <>
      <Container maxWidth="sm">
        <div className={classes.tabContainer}>
          <Paper className={classes.content} elevation={0}>
            <Tabs
              value={value}
              textColor="inherit"
              onChange={handleChange}
              className={classes.tabs}
              TabIndicatorProps={{ className: classes.indicator }}
            >
              <Tab label={t("loginForm.tabs.login")} />
              <Tab label={t("loginForm.tabs.signup")} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <LoginForm setShowSetupProfile={setShowSetupProfile} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <SignupForm setShowSetupProfile={setShowSetupProfile} />
            </TabPanel>
          </Paper>
        </div>
      </Container>
      <Popup
        openPopup={showSetupProfile}
        setOpenPopup={setShowSetupProfile}
        popupTitle="WCAST Registration"
      >
        <SetupProfile />
      </Popup>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
  },
  content: {},
  tabContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "0 auto",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  indicator: {},
  tabs: {
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-around",
    },
    "& .Mui-selected": {
      color: theme.palette.secondary.main,
    },
  },
}));

export default Signup;
