import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  CircularProgress,
  Tooltip,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Link,
  LinearProgress,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ListSubheader,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CloseIcon from "@mui/icons-material/Close";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useAuth } from "../components/useAuth";
import StartAssessmentPopup from "./StartAssessmentPopup";
import StartAssessment from "./StartAssessment";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { useTranslation, Trans } from "react-i18next";

const moduleLinks = {
  "(HEAL)":
    "https://www.cwbgroup.org/education/courses/welding-health-and-safety",
  "(MLHG)":
    "https://www.cwbgroup.org/education/courses/lifting-hoisting-and-rigging",
  "(SCWQ)":
    "https://www.cwbgroup.org/education/courses/codes-standards-specifications-and-welder-qualifications",
  "(SYMS)":
    "https://www.cwbgroup.org/education/courses/basic-drawing-interpretation",
  "(TOOL)":
    "https://www.cwbgroup.org/education/courses/welding-hand-and-power-tools",
  "(DRLF)":
    "https://www.cwbgroup.org/education/courses/drawings-layout-and-fabrication",
  "(QUAL)":
    "https://www.cwbgroup.org/education/courses/weld-quality-and-discontinuities",
  "(CAG)": "https://www.cwbgroup.org/education/courses/oxy-fuel-cutting",
  "(METL)":
    "https://www.cwbgroup.org/education/courses/basic-welding-metallurgy",
  "(PAC)": "https://www.cwbgroup.org/education/courses/plasma-arc-cutting",
  "(OFC)": "https://www.cwbgroup.org/education/courses/oxy-fuel-cutting",
  "(DIST)": "https://www.cwbgroup.org/education/courses/distortion",
  "(GTAW)":
    "https://www.cwbgroup.org/education/courses/basic-gas-tungsten-arc-welding",
  "(SMAW)":
    "https://www.cwbgroup.org/education/courses/basic-shielded-metal-arc-welding",
  "(WIRE)":
    "https://www.cwbgroup.org/education/courses/basic-wire-process-welding",
};

const ListAssessmentsXL = () => {
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOption, setSortOption] = useState("");
  const [previousResults, setPreviousResults] = useState([]);
  const [assessmentStatuses, setAssessmentStatuses] = useState({});
  const [openPopup, setOpenPopup] = useState(false);
  const [assessmentToStart, setAssessmentToStart] = useState(null);
  const navigate = useNavigate();
  const { token, user, isProfileSetup } = useAuth();
  const [showSetupProfile, setShowSetupProfile] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xl"));
  const { t } = useTranslation("dashboard");

  const assessments = [
    {
      _id: "assessmentA",
      name: t("assessment.A.title"),
      description: t("assessment.A.description"),
    },
    {
      _id: "assessmentB",
      name: t("assessment.B.title"),
      description: t("assessment.B.description"),
    },
    {
      _id: "assessmentC",
      name: t("assessment.C.title"),
      description: t("assessment.C.description"),
    },
    {
      _id: "assessmentD",
      name: t("assessment.D.title"),
      description: t("assessment.D.description"),
    },
  ];

  useEffect(() => {
    fetchPreviousResults();
  }, []);

  useEffect(() => {
    checkAssessmentsStatus();
  }, [previousResults]);

  useEffect(() => {
    if (user && !user.isProfileSetup) {
      setShowSetupProfile(true);
    } else {
      setShowSetupProfile(false);
    }
  }, [user, isProfileSetup]);

  const checkAssessmentsStatus = async () => {
    const statuses = {};
    for (const assessment of assessments) {
      statuses[assessment._id] = previousResults.some(
        (result) => result.surveyId === assessment._id
      );
    }
    setAssessmentStatuses(statuses);
  };

  const fetchPreviousResults = async () => {
    setLoading(true);
    try {
      const data = await getResults(user._id);
      setPreviousResults(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching results:", error);
      setLoading(false);
    }
  };

  const getResults = async (userId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/results?userId=${userId}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const getStatusDotColor = (result) => {
    if (!result || !result.data || !result.data[0]) {
      return "grey";
    }

    const score = result.data[0]?.score || 0;
    const questionCount = result.data[0]?.questionCount || 1;
    const percentage = (score / questionCount) * 100;

    if (percentage < 50) return "red";
    if (percentage < 70) return "orange";
    return "green";
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const sortByStatus = (assessments, direction) => {
    const statusOrder = { grey: 0, red: 1, orange: 2, green: 3 };

    return assessments.sort((a, b) => {
      const aStatus = getStatusDotColor(
        previousResults.find((result) => result.surveyId === a._id)
      );
      const bStatus = getStatusDotColor(
        previousResults.find((result) => result.surveyId === b._id)
      );

      if (aStatus === "grey") return -1;
      if (bStatus === "grey") return 1;

      const comparison = statusOrder[aStatus] - statusOrder[bStatus];
      return direction === "bestToWorst" ? comparison : -comparison;
    });
  };

  const sortedAssessments = [...assessments].sort((a, b) => {
    if (sortOption.includes("alphabetical")) {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      let comparison = 0;
      if (nameA > nameB) {
        comparison = 1;
      } else if (nameA < nameB) {
        comparison = -1;
      }

      return sortOption === "alphabetical-asc" ? comparison : comparison * -1;
    } else if (sortOption.includes("status")) {
      return sortByStatus(
        [a, b],
        sortOption === "status-bestToWorst" ? "bestToWorst" : "worstToBest"
      );
    }
    return 0;
  });

  const filteredAndSortedAssessments = sortedAssessments.filter((assessment) =>
    assessment.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const breadcrumbs = [
    <Link underline="hover" key="1" color="secondary" href="/">
      {t("breadcrumbs.level1")}
    </Link>,
    <Typography key="2" color="text.primary">
      {t("breadcrumbs.level2")}
    </Typography>,
  ];

  const handleStartAssessment = (assessmentId) => {
    setAssessmentToStart(assessmentId);
    setOpenPopup(true);
  };

  const startAssessment = async () => {
    try {
      const endpoint =
        assessmentToStart === "assessmentA"
          ? "generateAssessmentA"
          : assessmentToStart === "assessmentB"
          ? "generateAssessmentB"
          : assessmentToStart === "assessmentC"
          ? "generateAssessmentC"
          : "generateAssessmentD";

      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/${endpoint}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const surveyId = response.data.surveyId;
      navigate(`/assessment/${surveyId}`);
    } catch (error) {
      console.error("Error starting assessment:", error);
    }
  };

  const calculateModuleStatus = (assessment, previousResults) => {
    const modules = {
      assessmentA: [
        { name: t("assessment.A.course_HEAL"), range: [0, 4] },
        { name: t("assessment.A.course_MLHG"), range: [5, 9] },
        {
          name: t("assessment.A.course_SCWQ"),
          range: [10, 14],
        },
        { name: t("assessment.A.course_SYMS"), range: [15, 19] },
        { name: t("assessment.A.course_TOOL"), range: [20, 24] },
      ],
      assessmentB: [
        { name: t("assessment.B.course_DRLF"), range: [0, 13] },
        { name: t("assessment.B.course_QUAL"), range: [14, 27] },
      ],
      assessmentC: [
        { name: t("assessment.C.course_CAG"), range: [0, 4] },
        { name: t("assessment.C.course_METL"), range: [5, 10] },
        { name: t("assessment.C.course_PAC"), range: [11, 15] },
        { name: t("assessment.C.course_OFC"), range: [16, 21] },
      ],
      assessmentD: [
        { name: t("assessment.D.course_DIST"), range: [0, 9] },
        { name: t("assessment.D.course_GTAW"), range: [10, 24] },
        { name: t("assessment.D.course_SMAW"), range: [25, 39] },
        { name: t("assessment.D.course_WIRE"), range: [40, 54] },
      ],
    };

    const assessmentModules = modules[assessment._id] || [];
    const results = previousResults.filter(
      (result) => result.surveyId === assessment._id
    );

    if (results.length === 0) {
      return assessmentModules.map((module) => ({
        ...module,
        color: "grey",
        percentage: "N/A",
      }));
    }

    const latestResult = results.reduce((latest, current) =>
      new Date(latest.createdAt) > new Date(current.createdAt)
        ? latest
        : current
    );

    if (!latestResult || !latestResult.data || !latestResult.data[0]) {
      return assessmentModules.map((module) => ({
        ...module,
        color: "grey",
        percentage: "N/A",
      }));
    }

    const userAnswers = latestResult.data[0];

    if (!userAnswers.pages) {
      return assessmentModules.map((module) => ({
        ...module,
        color: "grey",
        percentage: "N/A",
      }));
    }

    return assessmentModules.map((module) => {
      let correctCount = 0;
      const moduleQuestions = userAnswers.pages.slice(
        module.range[0],
        module.range[1] + 1
      );

      moduleQuestions.forEach((page, index) => {
        const questionName = `question${module.range[0] + index + 1}`;
        const userAnswer = userAnswers[questionName];
        const correctAnswer =
          page.elements[0]?.correctAnswer !== undefined &&
          page.elements[0].correctAnswer === userAnswer;

        if (correctAnswer) {
          correctCount++;
        }
      });

      const percentage = (correctCount / moduleQuestions.length) * 100;

      let color;
      if (percentage < 50) color = "red";
      else if (percentage < 70) color = "orange";
      else color = "green";

      return {
        ...module,
        color,
        percentage: percentage.toFixed(2),
      };
    });
  };

  const getProgress = () => {
    const completedA = assessmentStatuses.assessmentA;
    const completedB = assessmentStatuses.assessmentB;
    const completedC = assessmentStatuses.assessmentC;
    const completedD = assessmentStatuses.assessmentD;
    let progress = 0;
    if (completedA) progress += 16;
    if (completedB) progress += 22;
    if (completedC) progress += 18;
    if (completedD) progress += 44;
    return Math.min(progress, 100);
  };

  const getLowestModules = () => {
    const allModules = assessments.flatMap((assessment) => {
      const assessmentModules = calculateModuleStatus(
        assessment,
        previousResults
      );
      return assessmentModules.map((module) => {
        // Directly match the key with the parentheses included
        const moduleKey = module.name.match(/\([^)]+\)/)?.[0];
        const link = moduleLinks[moduleKey];
        return {
          ...module,
          link,
        };
      });
    });

    return allModules
      .filter((module) => !isNaN(module.percentage)) // filter out modules with 'N/A' percentage
      .sort((a, b) => parseFloat(a.percentage) - parseFloat(b.percentage));
  };

  const lowestModules = getLowestModules();
  const topThreeModules = lowestModules.slice(0, 3);
  const remainingModules = lowestModules.slice(3);

  return (
    <Box display="flex">
      <Box className="main-content" flex={1}>
        <Box bgcolor="#FFD800" pr={4} pl={8} py={6}>
          <Link
            href="/"
            underline="always"
            color="#000"
            display="inline-flex"
            alignItems="center"
          >
            <CloseIcon sx={{ fontSize: "1em" }} /> Exit Dashboard
          </Link>
          <Typography variant="h2" gutterBottom>
            {t("assessment.label")}
          </Typography>
          <Typography variant="body1">{t("assessment.copy")}</Typography>
        </Box>
        <Box
          pt={2}
          pb={0}
          pr={4}
          pl={8}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Breadcrumbs
            separator={<NavigateNextIcon sx={{ color: "black" }} />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </Box>
        <Box
          pt={2}
          pb={3}
          pr={4}
          pl={8}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="center">
            <TextField
              placeholder={t("search_label")}
              inputProps={{ "aria-label": "search" }}
              value={searchQuery}
              onChange={handleSearchChange}
              color="secondary"
              variant="outlined"
              sx={{
                width: "12em",
              }}
            />
            <FormControl
              variant="outlined"
              sx={{ marginLeft: "10px", minWidth: 170 }}
            >
              <InputLabel
                color="secondary"
                sx={{ color: "rgba(0, 0, 0, 0.5)" }}
              >
                {t("sort_label")}
              </InputLabel>
              <Select
                value={sortOption}
                onChange={(e) => setSortOption(e.target.value)}
                label="Sort Assessments"
                variant="outlined"
                color="secondary"
                inputProps={{
                  MenuProps: {
                    PaperProps: {
                      sx: {
                        "& .Mui-selected": {
                          backgroundColor: "rgba(255, 216, 0, 0.6)",
                        },
                        "& .Mui-selected:hover": {
                          backgroundColor: "rgba(255, 216, 0, 0.3)",
                        },
                      },
                    },
                  },
                }}
              >
                <MenuItem value="">
                  <em>{t("sort_options.option1_label")}</em>
                </MenuItem>
                <ListSubheader sx={{ color: "black", fontWeight: "bold" }}>
                  {t("sort_options.option2.label")}
                </ListSubheader>
                <MenuItem value="alphabetical-asc">
                  {t("sort_options.option2.item1")}
                </MenuItem>
                <MenuItem value="alphabetical-desc">
                  {t("sort_options.option2.item2")}
                </MenuItem>
                <ListSubheader sx={{ color: "black", fontWeight: "bold" }}>
                  {t("sort_options.option3.label")}
                </ListSubheader>
                <MenuItem value="status-bestToWorst">
                  {t("sort_options.option3.item1")}t
                </MenuItem>
                <MenuItem value="status-worstToBest">
                  {t("sort_options.option3.item2")}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Grid container mb={2} pr={4} pl={8} alignItems="center">
          <Grid
            item
            xs={12}
            container
            alignItems="center"
            backgroundColor="#0E89BC"
            justifyContent="flex-start"
            py={2}
            pl={2.2}
          >
            <Typography
              variant="h6"
              display="inline"
              color="white"
              fontWeight={700}
            >
              {t("assessment_view.col1_label")}&nbsp;
            </Typography>
            <Tooltip title={t("assessment_view.col1_tooltip")} placement="top">
              <InfoIcon color="tertiary" />
            </Tooltip>
          </Grid>
        </Grid>
        {loading ? (
          <CircularProgress />
        ) : (
          <Grid container spacing={3} pr={4} pl={8}>
            {filteredAndSortedAssessments.map((assessment) => {
              const moduleStatus = calculateModuleStatus(
                assessment,
                previousResults
              );

              // Find the latest result for this assessment
              const latestResult = previousResults
                .filter((result) => result.surveyId === assessment._id)
                .reduce(
                  (latest, current) =>
                    latest &&
                    new Date(latest.createdAt) > new Date(current.createdAt)
                      ? latest
                      : current,
                  null
                );

              return (
                <Grid
                  item
                  xs={12}
                  sx={{ minWidth: "100%" }}
                  key={assessment._id}
                >
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls={assessment.name}
                    >
                      <Box flex={2}>
                        <Typography
                          variant="h6"
                          color="textPrimary"
                          pl={2}
                          mr={4}
                        >
                          {assessment.name}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textPrimary"
                          pl={2}
                          mr={4}
                        >
                          {assessment.description}
                        </Typography>
                      </Box>
                      <Box flex={1}>
                        <Button
                          color="primary"
                          variant="contained"
                          disableElevation
                          sx={{
                            backgroundColor: assessmentStatuses[assessment._id]
                              ? "#FFD800"
                              : "#29942E",
                            color: assessmentStatuses[assessment._id]
                              ? "#000000"
                              : "#FFFFFF",
                            paddingLeft: "1.8rem",
                            paddingRight: "1.8rem",
                            paddingTop: "0.8rem",
                            paddingBottom: "0.8rem",
                            borderRadius: "5px",
                          }}
                          onClick={() => handleStartAssessment(assessment._id)}
                        >
                          {assessmentStatuses[assessment._id]
                            ? t("assessment.retake")
                            : t("assessment.start")}
                        </Button>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ backgroundColor: "#f1f1f1" }}>
                      <Grid container pt={4}>
                        <Grid item xs={4}>
                          <Box display="flex" sx={{ alignItems: "center" }}>
                            <Typography variant="h6" pl={2} pr={1}>
                              {t("assessment_view.col1_mob_label")}
                            </Typography>
                            <Tooltip
                              title={t("assessment_view.col1_mob_tooltip")}
                            >
                              <InfoIcon color="black" />
                            </Tooltip>
                          </Box>
                        </Grid>
                        <Grid item xs={8} pl={2}>
                          <Typography
                            variant="body2"
                            color="textPrimary"
                            maxWidth={isMobile ? "100%" : 200}
                          >
                            {assessment.description}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container pt={4} pb={2}>
                        <Grid item xs={4}>
                          <Box display="flex" sx={{ alignItems: "center" }}>
                            <Typography variant="h6" pl={2} pr={1}>
                              {t("assessment_view.col3_label")}
                            </Typography>
                            <Tooltip title={t("assessment_view.col3_tooltip")}>
                              <InfoIcon color="black" />
                            </Tooltip>
                          </Box>
                        </Grid>
                        <Grid item xs={8} pl={2}>
                          <StatusDot
                            color={getStatusDotColor(latestResult)}
                            size="large"
                          />
                        </Grid>
                      </Grid>
                      <Typography variant="h6" pt={2} pl={2}>
                        {t("assessment_view.review_dropdown.label")}
                      </Typography>
                      <Grid container pt={4} pb={2}>
                        <Grid item xs={4}>
                          <Typography
                            variant="h5"
                            pl={2}
                            pr={1}
                            sx={{
                              [theme.breakpoints.down(420)]: {
                                maxWidth: "19ch",
                              },
                            }}
                          >
                            {t(
                              "assessment_view.review_dropdown.breakdown_title"
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          {moduleStatus.map((module) => (
                            <Box
                              key={module.name}
                              display="flex"
                              alignItems="center"
                              mb={1}
                            >
                              <StatusDot color={module.color} size="small" />
                              <Typography
                                variant="body2"
                                color="textPrimary"
                                ml={1}
                                maxWidth={isMobile ? "100%" : 200}
                              >
                                {module.name}
                              </Typography>
                              {/* <Typography
                              variant="body2"
                              color="textPrimary"
                              ml={1}
                            >
                              {module.percentage}%
                            </Typography> */}
                            </Box>
                          ))}
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              );
            })}
          </Grid>
        )}
        <Box mt={10} pr={4} pl={8} pb={8}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ backgroundColor: "#E5E5E5" }}
            >
              <Typography pl={1} variant="h6">
                {t("assessment_view.previous_attempts_label")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: "#F9F7F7" }}>
              <Grid container spacing={3}>
                {previousResults.length > 0 ? (
                  previousResults.map((result) => (
                    <Grid item xs={12} key={result._id}>
                      <Accordion>
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                          expandIcon={<ArrowDropDownIcon />}
                        >
                          <Box display="flex" flex={1}>
                            <Typography variant="h6" color="textPrimary">
                              {result.surveyId === "assessmentA"
                                ? t("assessment.A.title")
                                : result.surveyId === "assessmentB"
                                ? t("assessment.B.title")
                                : result.surveyId === "assessmentC"
                                ? t("assessment.C.title")
                                : result.surveyId === "assessmentD"
                                ? t("assessment.D.title")
                                : result.surveyId}
                            </Typography>
                          </Box>
                          <Box
                            display="flex"
                            flex={1}
                            sx={{ alignItems: "center" }}
                          >
                            <Typography variant="body2" color="textPrimary">
                              {new Date(result.createdAt).toLocaleDateString()}
                            </Typography>
                          </Box>
                          <StatusDot
                            color={getStatusDotColor(result)}
                            size="large"
                          />
                        </AccordionSummary>
                        <AccordionDetails sx={{ backgroundColor: "#F1F1F1" }}>
                          <Grid container mt={3} mb={1}>
                            <Grid
                              item
                              xs={3}
                              pr={2}
                              display="flex"
                              justifyContent="flex-end"
                            >
                              <Typography
                                variant="body1"
                                color="textPrimary"
                                fontWeight="bold"
                              >
                                Module Breakdown
                              </Typography>
                            </Grid>
                            <Grid item xs={9} pl={2}>
                              {calculateModuleStatus({ _id: result.surveyId }, [
                                result,
                              ]).map((module) => (
                                <Box
                                  key={module.name}
                                  display="flex"
                                  alignItems="center"
                                  mb={1}
                                >
                                  <StatusDot
                                    color={module.color}
                                    size="small"
                                  />
                                  <Typography
                                    variant="body2"
                                    color="textPrimary"
                                    ml={1}
                                  >
                                    {module.name}
                                  </Typography>
                                  {/* <Typography
                                    variant="body2"
                                    color="textPrimary"
                                    ml={1}
                                  >
                                    {module.percentage}%
                                  </Typography> */}
                                </Box>
                              ))}
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12}>
                    <Typography variant="body1" color="textPrimary" p={2}>
                      {t("assessment_view.no_results_label")}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
      <Box
        className="sidebar"
        width={400}
        position="sticky"
        top={0}
        right={0}
        bgcolor="#E3E3E3"
        pb={8}
      >
        <Box bgcolor="#0E89BC" p={4}>
          <Typography
            variant="h4"
            color="white"
            fontWeight="700"
            fontSize="20px"
          >
            {t("action_plan.label")}
          </Typography>
        </Box>
        <Box px={4} py={3}>
          <Box>
            <Typography variant="h3" sx={{ mb: ".5em" }}>
              {t("action_plan.greeting")}&nbsp;{user.firstName},
            </Typography>
            <Typography
              variant="h4"
              fontWeight="700"
              fontSize="18px"
              gutterBottom
            >
              {t("action_plan.legend.label")}
            </Typography>
          </Box>
          <Typography variant="body2" mb={1}>
            {t("action_plan.legend.copy")}
          </Typography>
          <Box display="flex" flexDirection="column" mb={3} gap={0.5}>
            <Box display="flex" alignItems="center" flexDirection="row" gap={1}>
              <svg
                width="11"
                height="11"
                viewBox="0 0 11 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="5.5" cy="5.5" r="5.5" fill="#29942E" />
              </svg>
              <Typography variant="body2">
                {t("action_plan.legend.green")}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" flexDirection="row" gap={1}>
              <svg
                width="11"
                height="11"
                viewBox="0 0 11 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="5.5" cy="5.5" r="5.5" fill="#C9A900" />
              </svg>
              <Typography variant="body2">
                {t("action_plan.legend.amber")}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" flexDirection="row" gap={1}>
              <svg
                width="11"
                height="11"
                viewBox="0 0 11 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="5.5" cy="5.5" r="5.5" fill="#E41F1F" />
              </svg>
              <Typography variant="body2">
                {t("action_plan.legend.red")}
              </Typography>
            </Box>
          </Box>
          <Typography
            variant="h4"
            fontWeight="700"
            fontSize="18px"
            gutterBottom
          >
            {t("action_plan.qualifiers.label")}
          </Typography>
          <Typography variant="body2" mb={3}>
            <Trans i18nKey={t("action_plan.qualifiers.copy")}></Trans>
          </Typography>
          <Typography
            variant="h4"
            fontWeight="700"
            fontSize="18px"
            gutterBottom
          >
            {t("action_plan.overall_progress.label")}
          </Typography>
          <Typography variant="body2" mb={2}>
            {t("action_plan.overall_progress.copy")}
          </Typography>
          <Box position="relative" display="flex" alignItems="center" mb={3}>
            <LinearProgress
              variant="determinate"
              value={getProgress()}
              sx={{
                width: "100%",
                height: 25,
                borderRadius: 4,
              }}
            />
            <Box
              position="absolute"
              left="50%"
              style={{
                transform: "translateX(-50%)",
                color: "black",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              {`${t("action_plan.overall_progress.bar_progress")}: ${getProgress()}%`}
            </Box>
          </Box>
          <Typography variant="h4" fontWeight="700" fontSize="18px" mb={0.5}>
            {t("action_plan.recommended_course.label")}
          </Typography>
          <Typography variant="body2" mb={1}>
            {t("action_plan.recommended_course.copy")}
          </Typography>
          {topThreeModules.map((module) => (
            <Typography variant="body2" gutterBottom key={module.name} mb={1}>
              <a href={module.link} target="_blank" rel="noopener noreferrer">
                {module.name}
              </a>{" "}
              - {t("action_plan.misc.improve")} {module.name.split(" (")[0]} {t("action_plan.misc.knowledge")}
            </Typography>
          ))}
          <Typography variant="body2" gutterBottom>
            <a
              href="https://www.cwbgroup.org/education/courses/welding-health-and-safety"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("action_plan.recommended_course.WHS_label")}
            </a>{" "}
            - {t("action_plan.recommended_course.WHS_description")}
          </Typography>
          <Typography variant="body2" mb={2}>
            <a
              href="https://placeholder.link/CareersInWelding"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("action_plan.recommended_course.CIW_label")}
            </a>{" "}
            - {t("action_plan.recommended_course.CIW_description")}
          </Typography>
          <Typography variant="h4" fontWeight="700" fontSize="18px" mb={0.5}>
            {t("action_plan.remaining_course.label")}
          </Typography>
          <Typography variant="body2" mb={1}>
            {t("action_plan.remaining_course.copy")}
          </Typography>
          {remainingModules.map((module) => (
            <Typography variant="body2" gutterBottom key={module.name} mb={1}>
              <a href={module.link} target="_blank" rel="noopener noreferrer">
                {module.name}
              </a>{" "}
              - {t("action_plan.misc.improve")} {module.name.split(" (")[0]} {t("action_plan.misc.knowledge")}
            </Typography>
          ))}
        </Box>
      </Box>
      <StartAssessmentPopup openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <StartAssessment
          assessmentId={assessmentToStart}
          onAssessmentBegin={() => {
            setOpenPopup(false);
            startAssessment();
          }}
        />
      </StartAssessmentPopup>
    </Box>
  );
};

const StatusDot = ({ color, size }) => (
  <Box
    sx={{
      width: size === "large" ? 30 : 20,
      height: size === "large" ? 30 : 20,
      borderRadius: "50%",
      backgroundColor: color,
    }}
  />
);

export default ListAssessmentsXL;
