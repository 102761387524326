import React, { useState, useEffect, useMemo } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import axios from "axios";
import "../index.css";
import { useAuth } from "../components/useAuth";
import {
  Box,
  Typography,
  Breadcrumbs,
  Link,
  Container,
  SwipeableDrawer,
  Fab,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { surveyLocalization } from "survey-core";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";

function SurveyComponent() {
  const location = useLocation();
  const navigate = useNavigate();
  const [surveyData, setSurveyData] = useState(null);
  const [attemptData, setAttemptData] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const { surveyId } = useParams();
  const { token, user } = useAuth();
  surveyLocalization.currentLocale = i18n.language;
  const { t } = useTranslation("dashboard");

  // useEffect(() => {
  //   // Check if the user came from the dashboard
  //   if (!location.state || !location.state.fromDashboard) {
  //     navigate("/dashboard");
  //     return;
  //   }

  //   // Change language based on the passed state
  //   if (location.state.language) {
  //     i18n.changeLanguage(location.state.language);
  //   }
  // }, [location.state, navigate]);

  useEffect(() => {
    const fetchSurvey = async () => {
      if (!token) return;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/getAttempt?surveyId=${surveyId}`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSurveyData(response.data.data);
      } catch (error) {
        console.error("Error fetching survey data:", error);
      }
    };

    const fetchAttempt = async () => {
      if (!token) return;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/getAttempt?surveyId=${surveyId}`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data) {
          setAttemptData(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching attempt data:", error);
      }
    };

    fetchSurvey();
    fetchAttempt();
  }, [surveyId, token]);

  const handleSurveyComplete = async (survey) => {
    try {
      const resultData = { ...survey.data };
      delete resultData.timeSpent;

      await axios.post(
        process.env.REACT_APP_SERVER_BASE_URL + "/api/post",
        {
          postId: surveyId,
          surveyResult: resultData,
        },
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Survey results posted successfully");
    } catch (error) {
      console.error("Error posting survey results:", error);
    }
  };

  const saveSurveyData = async (survey) => {
    const data = survey.data;
    data.pageNo = survey.currentPageNo;
    data.timeSpent = survey.timeSpent;
    try {
      await axios.post(
        process.env.REACT_APP_SERVER_BASE_URL + "/api/saveAttempt",
        {
          surveyId,
          data,
        },
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error("Error saving survey data:", error);
    }
  };

  // Utility function to format time in HH:MM
  // const formatTime = (totalSeconds) => {
  //   const hours = Math.floor(totalSeconds / 3600);
  //   const minutes = Math.floor((totalSeconds % 3600) / 60);
  //   return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  // };

  const breadcrumbs = [
    <Link underline="hover" key="1" color="secondary" href="/">
      {t("breadcrumbs.level1")}
    </Link>,
    <Link underline="hover" key="2" color="secondary" href="/dashboard">
      {t("breadcrumbs.level2")}
    </Link>,
    <Typography key="3" color="text.primary">
      {t("breadcrumbs.level3")}
    </Typography>,
  ];

  const survey = useMemo(() => {
    if (!surveyData) return null;

    const surveyInstance = new Model(surveyData);

    if (attemptData) {
      surveyInstance.data = attemptData;
      if (attemptData.pageNo) {
        surveyInstance.currentPageNo = attemptData.pageNo;
      }
      if (attemptData.timeSpent) {
        surveyInstance.timeSpent = attemptData.timeSpent;
      }
    }

    //onTimerPanelInfoText event to format the timer as HH:MM
    // surveyInstance.onTimerPanelInfoText.add((sender, options) => {
    //   const seconds = surveyData.maxTimeToFinish - sender.timeSpent;
    //   options.text = formatTime(seconds);
    // });

    surveyInstance.onComplete.add((sender) => handleSurveyComplete(sender));

    return surveyInstance;
  }, [surveyData, attemptData]);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const renderInstructions = (
    <Box
      className="sidebar"
      width={400}
      position="sticky"
      top={0}
      right={0}
      bgcolor="#E3E3E3"
    >
      <Box bgcolor="#0E89BC" p={4}>
        <Typography variant="h2" color="white" fontWeight="700" fontSize="20px">
          {t("assessment_sidebar.title")}
        </Typography>
      </Box>
      <Box px={4} py={3}>
        <Box>
          <Typography
            variant="h3"
            fontWeight="700"
            fontSize="18px"
            gutterBottom
          >
            {t("assessment_sidebar.title2")}
          </Typography>
        </Box>
        <Typography variant="body2" mb={2}>
          {t("assessment_sidebar.subtitle")}
        </Typography>
        <Box display="flex" flexDirection="column" mb={3} gap={4}>
          {/* <Box display="flex" alignItems="center" flexDirection="row" gap={1}>
            <svg
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="24.5" cy="24.5" r="24.5" fill="#0E89BC" />
              <path
                d="M34.2463 19.1288L29.8713 14.7538C29.7895 14.6727 29.6925 14.6085 29.5859 14.565C29.4793 14.5214 29.3652 14.4993 29.25 14.5H15.25C14.7859 14.5 14.3408 14.6844 14.0126 15.0126C13.6844 15.3408 13.5 15.7859 13.5 16.25V33.75C13.5 34.2141 13.6844 34.6593 14.0126 34.9875C14.3408 35.3156 14.7859 35.5 15.25 35.5H32.75C33.2141 35.5 33.6592 35.3156 33.9874 34.9875C34.3156 34.6593 34.5 34.2141 34.5 33.75V19.75C34.5007 19.6349 34.4786 19.5207 34.4351 19.4141C34.3915 19.3075 34.3273 19.2105 34.2463 19.1288ZM20.5 16.25H27.5V19.75H20.5V16.25ZM27.5 33.75H20.5V26.75H27.5V33.75ZM29.25 33.75V26.75C29.25 26.2859 29.0656 25.8408 28.7374 25.5126C28.4092 25.1844 27.9641 25 27.5 25H20.5C20.0359 25 19.5908 25.1844 19.2626 25.5126C18.9344 25.8408 18.75 26.2859 18.75 26.75V33.75H15.25V16.25H18.75V19.75C18.75 20.2141 18.9344 20.6593 19.2626 20.9875C19.5908 21.3156 20.0359 21.5 20.5 21.5H27.5C27.9641 21.5 28.4092 21.3156 28.7374 20.9875C29.0656 20.6593 29.25 20.2141 29.25 19.75V16.6088L32.75 20.1088V33.75H29.25Z"
                fill="white"
              />
            </svg>
            <Typography variant="body2" maxWidth={230}>
              <b>Save As You Go:</b> You can save your progress as you work
              through the assessment. Be sure to save frequently to avoid losing
              any work.
            </Typography>
          </Box> */}
          <Box display="flex" alignItems="center" flexDirection="row" gap={1}>
            <svg
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="24.5" cy="24.5" r="24.5" fill="#0E89BC" />
              <g clipPath="url(#clip0_348_4564)">
                <path
                  d="M32.1667 29.1883L30.5217 30.8333L26.3333 26.645L22.145 30.8333L20.5 29.1883L24.6883 25L20.5 20.8117L22.145 19.1667L26.3333 23.355L30.5217 19.1667L32.1667 20.8117L27.9783 25L32.1667 29.1883ZM35.6667 14.5C36.2855 14.5 36.879 14.7458 37.3166 15.1834C37.7542 15.621 38 16.2145 38 16.8333V33.1667C38 33.7855 37.7542 34.379 37.3166 34.8166C36.879 35.2542 36.2855 35.5 35.6667 35.5H18.1667C17.3617 35.5 16.7317 35.08 16.3117 34.4617L10 25L16.3117 15.5267C16.7317 14.9083 17.3617 14.5 18.1667 14.5H35.6667ZM35.6667 16.8333H18.1667L12.66 25L18.1667 33.1667H35.6667V16.8333Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_348_4564">
                  <rect
                    width="28"
                    height="28"
                    fill="white"
                    transform="translate(10 11)"
                  />
                </clipPath>
              </defs>
            </svg>
            <Typography variant="body2" maxWidth={230}>
              <b>{t("assessment_sidebar.instruction1_title")}</b>
              {t("assessment_sidebar.instruction1")}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" flexDirection="row" gap={1}>
            <svg
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="24.5" cy="24.5" r="24.5" fill="#0E89BC" />
              <path
                d="M20.5 14.5003V12.167H27.5V14.5003H20.5ZM22.8333 27.3337H25.1667V20.3337H22.8333V27.3337ZM24 36.667C22.5611 36.667 21.2047 36.3901 19.9307 35.8363C18.6567 35.2825 17.5437 34.5289 16.5917 33.5753C15.6397 32.6218 14.8864 31.5084 14.3318 30.2352C13.7773 28.9619 13.5 27.6059 13.5 26.167C13.5 24.7281 13.7773 23.3717 14.3318 22.0977C14.8864 20.8237 15.6397 19.7107 16.5917 18.7587C17.5437 17.8067 18.6571 17.0534 19.9318 16.4988C21.2066 15.9443 22.5627 15.667 24 15.667C25.2056 15.667 26.3625 15.8614 27.4708 16.2503C28.5792 16.6392 29.6194 17.2031 30.5917 17.942L32.225 16.3087L33.8583 17.942L32.225 19.5753C32.9639 20.5475 33.5278 21.5878 33.9167 22.6962C34.3056 23.8045 34.5 24.9614 34.5 26.167C34.5 27.6059 34.2227 28.9623 33.6682 30.2363C33.1136 31.5103 32.3603 32.6233 31.4083 33.5753C30.4563 34.5273 29.3429 35.281 28.0682 35.8363C26.7934 36.3917 25.4373 36.6685 24 36.667ZM24 34.3337C26.2556 34.3337 28.1806 33.5364 29.775 31.942C31.3694 30.3475 32.1667 28.4225 32.1667 26.167C32.1667 23.9114 31.3694 21.9864 29.775 20.392C28.1806 18.7975 26.2556 18.0003 24 18.0003C21.7444 18.0003 19.8194 18.7975 18.225 20.392C16.6306 21.9864 15.8333 23.9114 15.8333 26.167C15.8333 28.4225 16.6306 30.3475 18.225 31.942C19.8194 33.5364 21.7444 34.3337 24 34.3337Z"
                fill="white"
              />
            </svg>
            <Typography variant="body2" maxWidth={230}>
              <b>{t("assessment_sidebar.instruction2_title")}</b>
              {t("assessment_sidebar.instruction2")}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" flexDirection="row" gap={1}>
            <svg
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="24.5" cy="24.5" r="24.5" fill="#0E89BC" />
              <path
                d="M32.1667 14.5H15.8333C14.55 14.5 13.5 15.55 13.5 16.8333V33.1667C13.5 34.45 14.55 35.5 15.8333 35.5H32.1667C33.45 35.5 34.5 34.45 34.5 33.1667V16.8333C34.5 15.55 33.45 14.5 32.1667 14.5ZM32.1667 33.1667H15.8333V16.8333H32.1667V33.1667ZM18.1667 22.6667H20.5V30.8333H18.1667V22.6667ZM22.8333 19.1667H25.1667V30.8333H22.8333V19.1667ZM27.5 26.1667H29.8333V30.8333H27.5V26.1667Z"
                fill="white"
              />
            </svg>
            <Typography variant="body2" maxWidth={230}>
              <b>{t("assessment_sidebar.instruction3_title")}</b>
              {t("assessment_sidebar.instruction3")}
            </Typography>
          </Box>
        </Box>
        <Typography variant="body2" mb={4} mt={6} textAlign="center">
          {t("assessment_sidebar.good_luck")}
        </Typography>
      </Box>
    </Box>
  );

  if (!token || !user) {
    return (
      <Container maxWidth="lg">
        <Box my={3}>
          <Typography variant="h3" align="center">
            {t("error:loginRequired")}
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container
      maxWidth="100%"
      sx={{ paddingLeft: "0 !important", paddingRight: "0 !important" }}
    >
      <Box display="flex">
        <Box className="main-content" flex={1}>
          <Box
            bgcolor="#FFD800"
            pr={4}
            pl={isMobile ? 2 : 8}
            py={isMobile ? 3 : 6}
          >
            <Typography variant="h2" gutterBottom>
              {t("yellow_pane.title")}
            </Typography>
            <Typography variant="body1" maxWidth={isMobile ? 350 : 800}>
              {t("yellow_pane.subtitle")}
            </Typography>
          </Box>
          <Box pr={4} pl={isMobile ? 2 : 8} pt={isMobile ? 1 : 2}>
            <Breadcrumbs
              separator={<NavigateNextIcon sx={{ color: "black" }} />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Box>
          {survey && <Survey model={survey} />}
        </Box>
        {isMobile ? (
          <>
            <Fab
              variant="extended"
              color="secondary"
              sx={{
                borderRadius: 0,
                transform: "rotate(270deg)",
                textTransform: "none",
                position: "fixed",
                right: "-54px",
                top: "340px",
              }}
              onClick={toggleDrawer(true)}
            >
              <Typography
                variant="h6"
                sx={{ display: "inline-flex", alignItems: "center" }}
              >
                {t("assessment_sidebar.title")}
                <ArrowDropUpIcon />
              </Typography>
            </Fab>
            <SwipeableDrawer
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
            >
              {renderInstructions}
            </SwipeableDrawer>
          </>
        ) : (
          renderInstructions
        )}
      </Box>
    </Container>
  );
}

export default SurveyComponent;
