import React from 'react'
import { Grid, Typography, Box, Button } from "@mui/material";
import { makeStyles } from '@mui/styles';
import trainingImage9 from '../../assets/training/training-image9.png';
import iconDo from '../../assets/icons/icon-do.png';
import {useTranslation} from "react-i18next";
const useStyles = makeStyles((theme) => ({
    logoImage: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '250px',
        width:'300',
        margin: '0 auto'
    },
    paddingSmall: {
        [theme.breakpoints.down('md')]:{
            paddingRight: '12px',
            paddingLeft: '12px',
        }
    },

    imageSection: {
        [theme.breakpoints.down('sm')]:{
          flexDirection: 'column-reverse',
        }
    },
    imageContainer: {
        width: '100%',
    },
    bodyImage: {
        width: '100%',
        height: 'auto',
    },
    buttonRoot: {
        textTransform: 'none',
        borderRadius: '0.8rem',
        padding: '4px 24px'
        
    },
    logoContainer: {
        flex: '0 1 40px',
    },
    logoImage: {
        height: '40px',
        width: '40px',
        marginRight: theme.spacing(2),
    }
}));


const ApprenticeshipSection = () => {
    const classes = useStyles();
    const { t } = useTranslation(['weldingTraining', 'common']);
    return <>
    <div className={classes.paddingSmall}>
        <Box  mb={3}>
            <Typography variant="h2" align="left" gutterBottom>
                {t('section5.header')}
            </Typography>
        </Box>
        <Box mb={2}>
            <Typography variant="body1" align="left" gutterBottom>
                {t('section5.text1')}
            </Typography>
        </Box>
        

        <Grid container spacing={3} justifyContent="flex-start" alignItems="center">
            <Grid item  xs={4} className={classes.logoContainer}>
                <img src={iconDo} alt={t('section5.iconAlt')} className={classes.logoImage}/>
            </Grid>

            <Grid item  xs={8}>
                <Box mb={1}>
                    <Typography variant="body1" color="inherit" align="left">
                        {t('section5.linkText')}
                    </Typography>
                </Box>
                <Button 
                    color="primary" 
                    size="small" 
                    variant="contained" 
                    target="_blank" 
                    href="http://www.red-seal.ca/w.2lc.4m.2-eng.html"
                    disableElevation
                    className={classes.buttonRoot}
                >
                    <Typography variant="h5">
                        {t('common:buttons.viewWebsite')}
                    </Typography>
                </Button>
            </Grid>
        </Grid>

        <Box mt={3} mb={3}>
            <Typography variant="body1" align="left" gutterBottom>
                {t('section5.text2')}
            </Typography>
        </Box>

        <Grid container spacing={3} justifyContent="center" alignItems="flex-start" className={` ${classes.imageSection}`}>
            <Grid item xs={12} md={6}>     
                <Typography variant="body1" color="inherit" align="left">
                    {t('section5.text3')}
                </Typography>        
            </Grid>
            <Grid item xs={12} md={6}>
                <img className={`${classes.bodyImage}`} src={trainingImage9} alt={t('section5.imageAlt')}>
                </img>
            </Grid>

            
        </Grid>
    </div>
    </>;
}

export default ApprenticeshipSection;
