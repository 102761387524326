import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DesktopMenu from "./DesktopMenu";
import DashboardMenu from "./DashboardMenu";
import DashboardMobileMenu from "./DashboardMobileMenu";
import MobileMenu from "./MobileMenu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Popup from "../../components/Popup.js";
import Signup from "../../pages/Signup";
import SignupConfirm from "../../pages/Signup/SignupConfirm";

const languageOptions = [
  { code: "en", label: "EN ", iconClass: "CA" },
  { code: "fr", label: "FR ", iconClass: "CA" },
  { code: "ua", label: "UA ", iconClass: "UA" },
];

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }
  return ComponentWithRouterProp;
}

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1 },
  appBar: { backgroundColor: "white", color: "black" },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: { display: "none" },
  },
  title: { flexGrow: 1 },
  link: {
    color: "black",
    textDecoration: "none",
    margin: theme.spacing(1, 1.5),
  },
  activeLink: { borderBottom: "0.4rem solid black" },
  logo: { height: "50px", width: "50px" },
  toolbar: {},
}));

const Navigation = (props) => {
  const { router, i18n } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const classes = useStyles();
  const [language, setLanguage] = React.useState(i18n.language);
  const [openSignup, setOpenSignup] = useState(false);
  const [openSignupConfirm, setOpenSignupConfirm] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/signup") {
      setOpenSignup(true);
    } else if (location.pathname === "/signup-confirm") {
      setOpenSignupConfirm(true);
    } else {
      setOpenSignup(false);
      setOpenSignupConfirm(false);
    }
  }, [location]);

  const handleLanguageChange = (eventOrValue) => {
    let code;

    // Check if eventOrValue is an event object or a direct string value
    if (eventOrValue && eventOrValue.target) {
      // It's an event object, extract the value
      code = eventOrValue.target.value;
    } else if (typeof eventOrValue === "string") {
      // It's already the value, use it directly
      code = eventOrValue;
    } else {
      console.error(
        "Unexpected input, expected a string or an event:",
        eventOrValue
      );
      return;
    }

    if (code) {
      console.log("Language code:", code);
      i18n.changeLanguage(code);
      setLanguage(code);
    } else {
      console.error("Language code is undefined or invalid:", eventOrValue);
    }
  };

  return (
    <>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          {location.pathname === "/dashboard" ||
          location.pathname.startsWith("/assessment") ? (
            isSmallScreen ? (
              <DashboardMobileMenu
                location={router.location.pathname}
                setOpenPopup={setOpenSignup}
                languageOptions={languageOptions}
                language={language}
                handleLanguageChange={handleLanguageChange}
              />
            ) : (
              <DashboardMenu
                location={router.location.pathname}
                setOpenPopup={setOpenSignup}
                languageOptions={languageOptions}
                language={language}
                handleLanguageChange={handleLanguageChange}
              />
            )
          ) : isSmallScreen ? (
            <MobileMenu
              setOpenPopup={setOpenSignup}
              languageOptions={languageOptions}
              language={language}
              handleLanguageChange={handleLanguageChange}
            />
          ) : (
            <DesktopMenu
              location={router.location.pathname}
              setOpenPopup={setOpenSignup}
              languageOptions={languageOptions}
              language={language}
              handleLanguageChange={handleLanguageChange}
            />
          )}
        </Toolbar>
      </AppBar>
      <Popup openPopup={openSignup} setOpenPopup={setOpenSignup}>
        <Signup
          setOpenPopup={setOpenSignup}
          setOpenSignupConfirm={setOpenSignupConfirm}
        />
      </Popup>
      <Popup openPopup={openSignupConfirm} setOpenPopup={setOpenSignupConfirm}>
        <SignupConfirm setOpenPopup={setOpenSignupConfirm} />
      </Popup>
    </>
  );
};

const NavigationWithRouter = withRouter(Navigation);
export default NavigationWithRouter;
