import React from 'react'
import { 
    Box,
    Typography,
    Button
} from '@mui/material';
import {renderText} from '../../components/renderText';
import { makeStyles } from "@mui/styles";
import GetAppIcon from '@mui/icons-material/GetApp';
import { Toys } from '@mui/icons-material';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    listSection: {
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: '1rem',
        '& li:nth-last-child(1)': {
            borderBottomStyle: 'none',
        }
    },
    listItem: {
        listStyle: 'none',
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
        padding: theme.spacing(1,2)
    }, 
}));

const Section = ({data}) => {
    const classes = useStyles();
    const {title, body} = data;
    
    return (
        <>
        <Box px={3} py={2}  className={classes.listItem} component="li">
            <Typography variant="h4">
                <b>{title}</b>
            </Typography>
        </Box>
        <Box px={3} py={2}  className={classes.listItem} component="li">
            {
                body.map((item, idx) => 
                    <Typography variant="body1" gutterBottom key={idx}>
                        {renderText(item)}
                    </Typography>
                )
            }
        </Box>

        </>
    );
}


const OverViewSections = ({data}) => {
    const {items, downloadLink=''} = data;
    const classes = useStyles();
    const { t } = useTranslation('regulation');
    return (
        <>
        <Box mb={3} className={classes.listSection}>
            {
                items.map((item, idx) =>
                    <Section data={item} />
                )
            }
        </Box>
        {
            downloadLink &&
            <Box my={3}>
                <Button
                    startIcon={<GetAppIcon />} 
                    color="primary" 
                    size="large"
                    variant="contained" 
                    disableElevation
                    href={downloadLink}
                    target="_blank"
                >
                    <Typography variant="h5">
                    {t('section6.detailedOverviewText')}
                    </Typography>
                </Button>
            </Box>
        }
        </>
    )
}

export default OverViewSections
