import React from 'react'
import { 
    Grid,
    TableBody,
    TableRow,
    TableCell,
    Link,
    Typography,
    Box,
    Container
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import {getProvinceFromId} from './data';
import useTable from '../../components/useTable';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    link: {
        textDecoration: 'underline'
    }
}));
const ReferenceSection  = ({province}) => {
    const classes = useStyles();
    const { t } = useTranslation('regulation');
    // const provinceData = getProvinceFromId(province);
    const provinceData = t('section6.data', {returnObjects: true}).find(item => item.id === province)
    const references = provinceData?.references;
    const referenceCount = references.length;
    const title = provinceData?.label;
    const { TblContainer } = useTable();
    return (
        <Container maxWidth="xl">
        {
        referenceCount !== 0 ? (
        <Grid item xs={12}>
                {/* <Typography align="left" variant="h4" > 
                    <Box mt={4} mb={1} ml={2} >
                        {title}
                    </Box>
                </Typography> */}
                <Typography align="left" variant="h6" gutterBottom> 
                    <Box mt={1} mb={2} ml={2} >
                        {referenceCount} {t('section6.postSecondaryText')}
                    </Box>
                </Typography>
                <TblContainer>
                    <TableBody>
                        {
                            referenceCount >0 &&
                            references.map((item, idx) => 
                                (<TableRow key={idx}>
                                    <TableCell><Typography align="left" variant="body1">{item.name}</Typography></TableCell>
                                    <TableCell><Typography align="left" variant="body1"><Link
                                        className={classes.link}
                                        color="secondary"
                                        href={item.link}
                                        target="_blank"
                                        underline="hover">{item.link}</Link></Typography></TableCell>
                                </TableRow>))
                        }
                    </TableBody>
                </TblContainer>
            </Grid>
        ) : (
            <Grid item xs={12}>
                <Box my={3}>
                    <Typography align="center" variant="h6" gutterBottom>
                        {t('section6.noReferencesText')}
                    </Typography>
                </Box>
            </Grid>
        )    
        }
        </Container>
    );
}

export default ReferenceSection
