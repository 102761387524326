import React from 'react'
import { 
    Grid,
    Typography,
    Box
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import WelderBox from '../../../components/WelderBox'
import RedSeal from '../../../components/RedSeal';
import ListBox from '../../../components/ListBox';
import ArrowBox from '../../../components/ArrowBox';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    container: {
        backgroundColor: theme.palette.common.white,
        padding:theme.spacing(1),
    },
    boxWrapper: {
        display:'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]:{
            flexDirection: 'column'
        }
    }
}));


const SK = () => {
    const classes = useStyles();
    const {t} = useTranslation('regulation');

    const welderData = [
        {
            title:t('pathways.welderLvl1'),
            subtitle:`${t('pathways.techTraining')} 210 ${t('pathways.hours')} (7 ${t('pathways.weeks')})`,
            secondaryTitle:t('pathways.workTraining'),
            secondarySubtitle:t('pathways.accumulateHours'), 
            hours:`5400 ${t('pathways.hours')}`
        },
        {
            title:t('pathways.welderLvl2'),
            subtitle:`${t('pathways.techTraining')} 210 ${t('pathways.hours')} (7 ${t('pathways.weeks')})`,
            secondaryTitle:t('pathways.workTraining'),
            secondarySubtitle:t('pathways.accumulateHours'), 
            hours:`5400 ${t('pathways.hours')}`
        },
        {
            title:t('pathways.welderLvl3'),
            subtitle:`${t('pathways.techTraining')} 210 ${t('pathways.hours')} (8 ${t('pathways.weeks')})`,
            secondaryTitle:t('pathways.workTraining'),
            secondarySubtitle:t('pathways.accumulateHours'), 
            hours:`5400 ${t('pathways.hours')}`
        }
    ];
    const listData = t('pathways.processList4', {returnObjects: true});
    return <>  
        <div className={classes.container}>
            {/**Section 1 */}
            <Box my={8}>
                <Box mb={3} p={1}>
                    <Typography variant="h5" align="center" gutterBottom color="secondary">
                        <b>{t('pathways.SK.section1.title')}</b>
                    </Typography>
                </Box>
                <Grid container justifyContent="center" alignItems="center" spacing={1}> 
                    
                    <ArrowBox>
                        <WelderBox maxWidth="300px">
                            <Box py={1}>
                                <Typography variant="body1" align="center">
                                    <b>{t('pathways.pOfEdPreq')}</b>   
                                </Typography>
                                
                            </Box>
                            <Box p={1} bgcolor="common.white" borderRadius="1rem">
                                <Typography variant="body1" align="left" gutterBottom>
                                    {t('pathways.succCompletion')}
                                    <br/>
                                    {t('pathways.engAndMath')}   
                                </Typography>
                                <Typography variant="body1" align="center" gutterBottom>
                                    <b>{t('pathways.or')}</b>
                                 
                                </Typography>
                                <Typography variant="body1" align="left" gutterBottom>
                                {t('pathways.passGED')}
                                </Typography>     
                            </Box>
                        </WelderBox>
                    </ArrowBox>

                    <ArrowBox >
                    <WelderBox 
                    
                    title={t('pathways.succCompletionOf')}
                    subtitle={`${t('pathways.techTraining')} 240 ${t('pathways.hours')}  (8 ${t('pathways.weeks')})`}
                    secondaryTitle={t('pathways.workTraining')}
                    secondarySubtitle={""}
                    hours={`3600 ${t('pathways.hours')} (1800/${t('pathways.year')})`}

                    />
                    </ArrowBox>
                      
                    <Grid item md={3}>
                        <WelderBox 
                            jc={"center"}
                            title={t('pathways.SK.section1.Box3')}
                        />
                    </Grid>
                </Grid>
            </Box>  



            {/**Section 2 */}
            <Box my={8}>
                <Box mb={3} p={1}>
                    <Typography variant="h5" align="center" gutterBottom color="secondary">
                        <b>{t('pathways.SK.section2.title')}</b>
                    </Typography>
                </Box>
                <Grid container justifyContent="center" alignItems="center" spacing={1}> 
                    
                    <ArrowBox mdCols="2" >
                        <WelderBox maxWidth="300px">
                            <Box py={1}>
                                <Typography variant="body1" align="center">
                                    <b>{t('pathways.entranceExam')}</b>   
                                </Typography>
                                <Typography variant="body1" align="center" gutterBottom>
                                    <b>{t('pathways.or')}</b>
                                </Typography>
                                <Typography variant="body1" align="left" gutterBottom>
                                   <b>{t('pathways.pOfEdPreq')}</b>
                                </Typography>  
                            </Box>
                            <Box p={1} bgcolor="common.white" borderRadius="1rem">
                                <Typography variant="body1" align="left" gutterBottom>
                                {t('pathways.succCompletion')}
                                    <br/>
                                    {t('pathways.engAndMath')}
                                   
                                </Typography>
                                <Typography variant="body1" align="center" gutterBottom>
                                    <b>OR</b>
                                </Typography>
                                <Typography variant="body1" align="left" gutterBottom>
                                {t('pathways.passGED')} 
                                </Typography>
                            </Box>
                        </WelderBox>
                    </ArrowBox>
                    
                    {
                        welderData.map((data, idx) => {
                            // const {title, subtitle, secondaryTitle, secondarySubtitle, hours} = data;
                            return (
                                <ArrowBox mdCols="2" key={idx}>
                                    <WelderBox 
                                        title={data.title}
                                        subtitle={data.subtitle}
                                        secondaryTitle={data.secondaryTitle}
                                        secondarySubtitle={data.secondarySubtitle}
                                        hours={data.hours}
                                    />
                                </ArrowBox>
                            )
                        }
                            
                        )
                    }
                    <RedSeal mdCols="2"/>
                </Grid>
            </Box> 

            {/**Section 3 */}
            <Box my={8}>
                <Box mb={3} p={1}>
                    <Typography variant="h5" align="center" gutterBottom color="secondary">
                        <b>{t('pathways.SK.section3.title')}</b>
                    </Typography>
                    <Typography variant="body1" align="left" gutterBottom>
                        {t('pathways.challExam')}
                        <br />
                        {t('pathways.expWorkersText')}
                    </Typography>

                </Box>
                <Grid container justifyContent="center" alignItems="center" spacing={1}> 
                    
                    <ArrowBox mdCols={4}>
                    <WelderBox 
                        subtitle={t('pathways.SK.section3.Box1')}
                        maxWidth={"210px"}
                        
                    />
                    </ArrowBox>
                    <ArrowBox mdCols={4}>

                        <ListBox 
                            title={t('pathways.pOfCompProcess')}
                            list={listData}
                            maxWidth={"300px"}
                        />

                        <Box my={6}>
                            <Typography variant="body1" color="secondary">
                                <b>{t('pathways.or')}</b>
                            </Typography>
                        </Box>

                        <WelderBox 
                            subtitle={t('pathways.ticketsHeader')}
                            secondarySubtitle={t('pathways.ticketsBody')}
                            maxWidth={"300px"}
                        />


                    </ArrowBox>
                    


                    <RedSeal mdCols={4}/>
                </Grid>
            </Box>
        </div>
       
    </>;
}

export default SK
