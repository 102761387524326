import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Box
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import iconClose from "../assets/icons/icon-close.png";
import { useNavigate, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {},
  dialogRoot: {},
  rounded: {
    borderRadius: "1rem",
  },
  icon: {
    height: "30px",
    width: "30px",
  },
  dialogContent: {
    padding: theme.spacing(3),
  },
  dialogTitle: {
    paddingBottom: 0,
  },
}));

export default function Popup(props) {
  const classes = useStyles();
  const { children, openPopup, setOpenPopup, popupTitle } = props;
  let navigate = useNavigate();
  let location = useLocation();

  const handleClose = (e) => {
    e.stopPropagation();
    setOpenPopup(false);
    if (location.state?.background) {
      navigate(location.state.background.pathname);
    } else {
      navigate("/");
    }
  };

  //console.log('Popup Props:', props); // Debugging line
  
  return (
    <Dialog
      open={openPopup}
      maxWidth="md"
      classes={{ paper: classes.dialogWrapper, root: classes.dialogRoot }}
    >
      <Box classes={{ root: classes.dialogTitle }} sx={{ m: 2, }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h3" color="secondary">
            {popupTitle}
          </Typography>
          <IconButton onClick={handleClose} size="large">
            <img src={iconClose} className={classes.icon} alt="close icon" />
          </IconButton>
        </div>
      </Box>
      <DialogContent classes={{ root: classes.dialogContent }}>
        {children}
      </DialogContent>
    </Dialog>
  );
}
