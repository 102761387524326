import React from "react";
import Banner from "../components/Banner";
import { makeStyles } from "@mui/styles";
import { Typography, Box, Container, Link } from "@mui/material";
import jobHeroDesktop from "../assets/findJob/job-hero-desktop.png";
import jobHeroMobile from "../assets/findJob/job-hero-mobile.png";
import iconInternational from "../assets/icons/10-Search-Job.png";
import ListSection from "../components/ListSection";
import { Helmet } from "react-helmet";
import { useTranslation, Trans } from "react-i18next";

const section = {
  title: "Search Welding Jobs in Canada",
  items: [
    {
      title: "Job Bank Canada",
      body: `The Canadian Employment Connections (CEC) program is a free pre-arrival employment program. 
        It offers virtual services to help international welders prepare for Canadian welding jobs.
        `,
      link: "https://www.jobbank.gc.ca/home",
    },
    {
      title: "Indeed",
      body: `Indeed Canada is a popular job site where employers can post jobs for free. 
        Jobs can be sorted by location (city, town, province) and applicants can use Indeed to submit their resumes. 
        Employers can include a job description, company information, and compensation details in their posting.`,
      link: "https://ca.indeed.com/",
    },
    {
      title: "LinkedIn",
      body: `LinkedIn is a professional network of employers posting jobs and job seekers applying.`,
      link: "https://www.linkedin.com/",
    },
    // {
    //     title: 'CWB Group Careers',
    //     body: `CWB Group Careers`,
    //     link: 'https://www.cwbgroup.org/about/careers '
    // }
  ],
};

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    paddingLeft: "64px",
    paddingRight: "64px",
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  paddingSmall: {
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
  },
  padding: {
    // paddingLeft: '64px',
    // paddingRight: '64px',
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
  },
  section: {
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(7),
    },
  },
  container: {
    [theme.breakpoints.down("md")]: {
      margin: 0,
      padding: 0,
      // justifyContent: 'center',
    },
  },
}));
const FindJob = () => {
  const classes = useStyles();
  const { t } = useTranslation("careers");
  return (
    <>
      <Helmet>
        <title>{t("metaTitle")}</title>
        <meta name="description" content={t("metaDesc")} />
      </Helmet>
      <Banner
        src={jobHeroMobile}
        srcset={jobHeroDesktop}
        alt={""}
        iconSrc={iconInternational}
        iconAlt={t("bannerIconAlt")}
        title={t("banner")}
        jc={"center"}
      />
      <Container maxWidth="xl" className={classes.container}>
        <div className={classes.padding}>
          <Typography variant="h2" gutterBottom align="left">
            {t("section1.title")}
          </Typography>
          <Typography variant="body1" gutterBottom align="left">
            {t("section1.copy")}
          </Typography>
          <ListSection items={t("section1.items", { returnObjects: true })} />
          <Typography variant="body1" gutterBottom align="left">
            {t("section1.copy2")}
          </Typography>
          <Typography variant="body1" gutterBottom align="left">
            {t("section1.copy3")}
            <Link href="https://www.cwbgroup.org/association" target="_blank" rel="nofollow" color="secondary">Visit Association | CWB Group</Link> for more information.
          </Typography>
          <Typography variant="body1" gutterBottom align="left">
            {t("section1.copy4")}<Link href="https://www.learninghub.ca" target="_blank" rel="nofollow" color="secondary">www.learninghub.ca.</Link>
          </Typography>
        </div>
      </Container>
    </>
  );
};

export default FindJob;
