import React from 'react'
import { Grid, Typography, Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    buttonRoot: {
        textTransform: 'none',
        borderRadius: '0.8rem',
        padding: '4px 24px',
        margin: '0 auto',
    },
    buttonContainer: {
        textAlign: 'center'
    }
}));

const SignupConfirm = (props) => {
    const classes = useStyles();
    const { setOpenPopup } = props;
    let location = useLocation();
    return (
        <div>
            <Box mb={4}>
                <Typography variant="h3" align="center">
                    Sign up confirmed
                </Typography>
            </Box>
            <Box mb={4}>
                <Typography variant="body2" gutterBottom align="center">
                    Thank you for signing up for the Welder Competency and Credential Assessment Program (WCCAP).
                </Typography>
            </Box>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={6} className={classes.buttonContainer}>
                    <Button
                        color="primary"
                        size="small"
                        variant="contained"
                        disableElevation
                        className={classes.buttonRoot}
                        component={Link}
                        to="/"
                    >
                        Back to home
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.buttonContainer}>
                    <Button
                        color="primary"
                        size="small"
                        variant="contained"
                        disableElevation
                        className={classes.buttonRoot}
                        component={Link}
                        to={{
                            pathname: "/signup",
                            state: { background: { hash: "", pathname: "/", search: "", state: undefined }, goTo: '/dashboard' }
                        }}
                    >
                        Start Assessment
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}

export default SignupConfirm;
