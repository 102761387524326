import React from "react";
import { Grid, Typography, Hidden, Box, Paper, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ScrollMenu from "../components/ScrollMenu";
import Banner from "../components/Banner";
import ListSection from "../components/ListSection";
import ScrollMenuMobile from "../components/ScrollMenuMobile";
import iconNewcomer from "../assets/icons/icon-newcomer.png";
import iconInternationl from "../assets/icons/icon-international.png";
import iconLocation from "../assets/icons/icon-location.png";
import bodyImage1 from "../assets/internationalWelders/bodyImage1.png";
import internationalHeroDesktop from "../assets/internationalWelders/international-header-desktop.png";
import internationalHeroMobile from "../assets/internationalWelders/international-header-mobile.png";
import ScrollTopButton from "../components/ScrollTopButton";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
// const sections = [
//     {
//         id:'Arrival',
//         title: 'Pre-arrival Readiness Services',
//         url: '/images/photo1.jpeg',
//         items: [
//             {
//                 title: 'Active Engagement and Integration Project (AEIP) ',
//                 body: `AEIP offers FREE services to people before they land in Canada. AEIP helps with settlement and
//                 employment preparation for newcomers.`,
//                 link: 'https://aeipsuccess.ca/'
//             },

//             {
//                 title: 'The Canadian Employment Connections (CEC)',
//                 body: `The CEC is a free employment program that offers virtual services to prepare international welders. The
//                 program helps welders know which province or territory needs their skills. CEC helps international
//                 welders with job search tools. It offers Canadian business communication training, sector information
//                 and trends, and employer connections.`,
//                 link: 'https://accesemployment.ca/index.php/planning-your-move-to-canada'
//             },
//             {
//                 title: 'Immigration, Refugees and Citizenship Canada',
//                 body: `Immigration, Refugees and Citizenship Canada offers programming to help newcomers settle in Canada.
//                 It grants citizenship, and issues travel documents (such as passports) to Canadians.`,
//                 link: 'https://www.canada.ca/en/immigration-refugees-citizenship.html'
//             },
//             {
//                 title: 'Settlement Online Pre-Arrival (SOPA)',
//                 body:   `SOPA is an Immigration, Refugees & Citizenship Canada (IRCC) funded program that offers free pre
//                 arrival services for newcomers. It provides job readiness, success, and retention. It offers one-on-one
//                 orientation, job search support, employment counselling, and workplace culture training. They also have
//                 post-arrival services when you land in Canada.`,
//                 link: 'https://arriveprepared.ca/'
//             },
//             {
//                 title: 'Foreign Credential Recognition Program - ESDC ',
//                 body:   `Newcomers to Canada must have their foreign licences and certificates recognized to work in some Canadian jobs.
//                 This is foreign credential recognition. An authorized individual will confirm that federal, provincial or territorial standards are met.
//                 They review your foreign education, language skills or work experience.`,
//                 link: 'https://www.canada.ca/en/employment-social-development/programs/foreign-credential-recognition.html'
//             },
//         ]
//     },
//     {
//         id:'Settled',
//         title:'Information on Provincial Immigration Services' ,
//         url: '/images/photo1.jpeg',
//         items: [
//             {
//                 title: 'The Alberta Association of Immigrant Serving Agencies (AAISA)',
//                 body: `AAISA is a non-profit organization that helps newcomers in the province of Alberta.`,
//                 link: 'https://aaisa.ca/',
//                 province: 'Alberta'
//             },
//             {
//                 // ARAISA is a non-profit umbrella organization that has been providing support to the settlement and integration sector in
//                 // Atlantic Canada for the past 25 years. ARAISA currently counts 16 Anglophone and Francophone member
//                 // organizations in the provinces of New Brunswick, Newfoundland and Labrador, Nova Scotia and Prince
//                 // Edward Island. Their goal is to help newcomers create a new life in Atlantic Canada.
//                 title: 'The Atlantic Region Association of Immigrant Serving Agencies (ARAISA)',
//                 body: `ARAISA is a non-
//                 profit umbrella organization that has
//                 been providing support to
//                 the settlement and integration sector in
//                 Atlantic Canada for the past 25 years.
//                 ARAISA currently counts 16 Anglophone
//                 and Francophone member organizations
//                 in the provinces of New Brunswick,
//                 Newfoundland and Labrador,
//                 Nova Scotia and Prince Edward Island.
//                 Their goal is to help newcomers create
//                 a new life in Atlantic Canada.`,
//                 link: 'https://araisa.ca/#close',
//                 province: 'Atlantic Regions'

//             },
//             {
//                 title: 'Immigrant Employment Council of BC (IEC-BC)',
//                 body: `Immigrant Employment Council of BC (IEC-BC) is a not-for-profit organization that provides BC employers with solutions,
//                 tools and resources they need to attract, hire and retain qualified immigrant talent.
//                 IEC-BC works with employers, government, and other partner stakeholders to ensure that
//                  BC employers can effectively integrate global talent.`,
//                 link: 'https://iecbc.ca/',
//                 province: 'British Columbia'
//             },
//             {
//                 title: 'The Manitoba Association of Newcomer Serving Organizations (MANSO)',
//                 body: `MANSO is an organization for immigrant and refugee service providers in Manitoba. It offers settlement
//                 services, language training, employment services, and welcoming communities programs.`,
//                 link: 'https://mansomanitoba.silkstart.com/companies/immigrant-centre',
//                 province: 'Manitoba'

//             },

//             {
//                 title: 'Immigrant Services Association of Nova Scotia (ISANS)',
//                 body: `ISANS works with newcomers to help them build a future in Canada. They have a wide range of services
//                 for employment, family, and social help.`,
//                 link: 'https://www.isans.ca/',
//                 province: 'Nova Scotia'
//             },
//             {
//                 title: 'Skills for Change',
//                 body: `Skills for Change is a free community agency that helps immigrants and refugees. They provide programs
//                 that help newcomers face less workspace and life change challenges. They help introduce Canadian
//                 diversity and workspace culture to newcomers.`,
//                 link: 'https://skillsforchange.org/',
//                 province: 'Ontario'
//             },
//             {
//                 title: 'Halton Multicultural Council (HMC Connections)',
//                 body: `The Halton Multicultural Council strives to do two things:`,
//                 listItems: [
//                     'welcome newcomers and prepare them for life in Canada',
//                     'prepare our community to welcome immigrant newcomers and refugees'
//                 ],
//                 body1: `They are a community-based settlement agency that provides a variety of programs and services to
//                         immigrant and refugee communities`,
//                 link: ' https://hmcconnections.com/about-us/',
//                 province: 'Ontario'
//             },
//             {
//                 title: 'Ministère de l’Immigration, de la Francisation et de l’Intégration',
//                 body: `Ministère de l’Immigration, de la Francisation et de l’Intégration and its partners provide newcomers
//                 with various services to help them get settled. They offer:`,
//                 listItems: [
//                     'Information sessions',
//                     'French courses',
//                     'Directory of community service organizations'
//                 ],
//                 link: 'http://www.immigration-quebec.gouv.qc.ca/en/home.html',
//                 province: 'Quebec'
//             },

//             {
//                 title: 'Saskatchewan Association of Immigrant Settlement Integration Agencies (SAISIA)',
//                 body: `Established in 1987, SAISIA serves newcomers by providing settlement and integration agencies in Saskatchewan
//                 with access to professional development and opportunities to network, as well as links to resources they can use to
//                  strengthen and enhance their internal procedures, client programs and services
//                 `,
//                 link: 'https://www.saisia.ca/',
//                 province: 'Saskatchewan'
//             },

//         ]
//     },
//     {
//         id:'Employment',
//         title:'How to get a Career in Canada' ,
//         url: '/images/photo1.jpeg',
//         items: [
//             {
//                 body: `Canada is an equal opportunity place, meaning anyone with the right credentials can get the job. Please
//                 visit our Careers page for more information.`,
//                 linkTitle: 'Careers',
//                 internalLink: '/search-welding-jobs-canada'
//             }
//             // {
//             //     title: 'Job Bank Canada',
//             //     body: `The Canadian Employment Connections (CEC) program is a free pre-arrival employment
//             //             program that offers virtual services to prepare internationally-trained professionals for the Canadian
//             //             job market as they prepare to immigrate to Canada.`,
//             //     link: 'https://accesemployment.ca/index.php/planning-your-move-to-canada'
//             // },
//             // {
//             //     title: 'Indeed',
//             //     body: `Indeed: Job Search Canada`,
//             //     link: 'https://ca.indeed.com/'
//             // },
//             // {
//             //     title: 'LinkedIn',
//             //     body: `LinkedIn is mainly used for professional networking, including employers posting jobs and job seekers posting their CVs.`,
//             //     link: 'https://www.linkedin.com/'
//             // },
//             // {
//             //     title: 'CWB Group Careers',
//             //     body: `CWB Group Careers`,
//             //     link: 'https://www.cwbgroup.org/about/careers '
//             // }
//         ]
//     },
//     {
//         id:'Assistance',
//         title:'Financial Assistance for Newcomers',
//         url: '/images/photo1.jpeg',
//         items: [
//             {
//                 title: 'Atlantic Immigrant Career Loan Fund',
//                 body: `The Atlantic Immigrant Career Loan Fund offers small loans for internationally trained tradespeople.
//                 This fund helps newcomers overcome financial obstacles. They help newcomers get a license to practice
//                 and enter Canadian jobs. The Government of Canada funds the program.`,
//                 link: 'https://www.aiclf.ca/'
//             },
//             {
//                 title: 'Foreign Credential Recognition Program (FCRP)',
//                 body: `This program supports foreign credential recognition in Canada and is funded by the Canadian
//                 government.`,
//                 link: 'https://www.canada.ca/en/employment-social-development/services/funding/foreign-credential-recognition.html'
//             }
//         ]
//     },
//     {
//         id: 'Immigrants',
//         title:'Application for Immigrants',
//         url: '/images/photo1.jpeg',
//         items: [
//             {
//                 title: 'Immigrate to Canada',
//                 body: `Helps you with the steps of immigrating to Canada. It tells you how to apply, how to protect yourself
//                 from fraud, and how to prepare.`,
//                 link: 'https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada.html'
//             }
//         ]
//     }
// ]

const useStyles = makeStyles((theme) => ({
  paddingSmall: {
    [theme.breakpoints.down("md")]: {
      paddingRight: "12px",
      paddingLeft: "12px",
    },
  },
  padding: {
    paddingRight: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
  },
  section: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(6),
      // height: '80vh'
    },
  },
  sectionContainer: {
    "& section#Immigrants": {
      paddingBottom: "50%",
    },
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: theme.palette.primary.main,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderTopRightRadius: "1rem",
    borderBottomRightRadius: "1rem",
    [theme.breakpoints.down("md")]: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  headerIconContainer: {
    marginRight: theme.spacing(2),
  },
  headerIcon: {
    height: "40px",
    width: "40px",
  },
  headerTextContainer: {},
  imageSection: {
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
      padding: theme.spacing(0, 0),
    },
  },
  imageContainer: {
    width: "100%",
  },
  // image: {
  //     backgroundSize: 'cover',
  //     backgroundRepeat: 'no-repeat',
  //     backgroundPosition: 'center',
  //     height: '300px',
  //     width:'100%',
  //     margin: '0 auto',
  //     borderRadius: '1rem',
  //     [theme.breakpoints.down('sm')]: {
  //         borderRadius: 0
  //     },
  // },
  sidebar: {
    position: "sticky",
    top: "10px",
  },
  container: {
    [theme.breakpoints.down("md")]: {
      margin: 0,
      padding: 0,
      // justifyContent: 'center',
    },
  },
  image: {
    width: "100%",
    height: "200px",
    objectFit: "cover",
    objectPosition: "center",
  },
}));

const InternationalWelders = () => {
  const classes = useStyles();
  const [selectedIndexMobile, setSelectedIndexMobile] = React.useState(0);
  const { t } = useTranslation("intlWelders");
  const sections = [
    {
      id: "Arrival",
      title: t("section2.table1.header"),
      url: "/images/photo1.jpeg",
      items: [
        {
          title: t("section2.table1.item1.title"),
          body: t("section2.table1.item1.body"),
          link: "https://aeipsuccess.ca/",
        },

        {
          title: t("section2.table1.item2.title"),
          body: t("section2.table1.item2.body"),
          link: "https://accesemployment.ca/index.php/planning-your-move-to-canada",
        },
        {
          title: t("section2.table1.item3.title"),
          body: t("section2.table1.item3.body"),
          link: "https://www.canada.ca/en/immigration-refugees-citizenship.html",
        },
        {
          title: t("section2.table1.item4.title"),
          body: t("section2.table1.item4.body"),
          link: "https://arriveprepared.ca/",
        },
        {
          title: t("section2.table1.item5.title"),
          body: t("section2.table1.item5.body"),
          link: "https://www.canada.ca/en/employment-social-development/programs/foreign-credential-recognition.html",
        },
      ],
    },
    {
      id: "Settled",
      title: t("section2.table2.header"),
      url: "/images/photo1.jpeg",
      items: [
        {
          title: t("section2.table2.item1.title"),
          body: t("section2.table2.item1.body"),
          link: "https://aaisa.ca/",
          province: t("section2.table2.item1.province"),
        },
        {
          // ARAISA is a non-profit umbrella organization that has been providing support to the settlement and integration sector in
          // Atlantic Canada for the past 25 years. ARAISA currently counts 16 Anglophone and Francophone member
          // organizations in the provinces of New Brunswick, Newfoundland and Labrador, Nova Scotia and Prince
          // Edward Island. Their goal is to help newcomers create a new life in Atlantic Canada.
          title: t("section2.table2.item2.title"),
          body: t("section2.table2.item2.body"),
          link: "https://araisa.ca/#close",
          province: t("section2.table2.item2.province"),
        },
        {
          title: t("section2.table2.item3.title"),
          body: t("section2.table2.item3.body"),
          link: "https://iecbc.ca/",
          province: t("section2.table2.item3.province"),
        },
        {
          title: t("section2.table2.item4.title"),
          body: t("section2.table2.item4.body"),
          link: "https://mansomanitoba.silkstart.com/companies/immigrant-centre",
          province: t("section2.table2.item4.province"),
        },

        {
          title: t("section2.table2.item5.title"),
          body: t("section2.table2.item5.body"),
          link: "https://www.isans.ca/",
          province: t("section2.table2.item5.province"),
        },
        {
          title: t("section2.table2.item6.title"),
          body: t("section2.table2.item6.body"),
          link: "https://skillsforchange.org/",
          province: t("section2.table2.item6.province"),
        },
        {
          title: t("section2.table2.item7.title"),
          body: t("section2.table2.item7.body"),
          listItems: [
            t("section2.table2.item7.listItems.item1"),
            t("section2.table2.item7.listItems.item2"),
          ],
          body1: t("section2.table2.item7.body1"),
          link: " https://hmcconnections.com/about-us/",
          province: t("section2.table2.item7.province"),
        },
        {
          title: t("section2.table2.item8.title"),
          body: t("section2.table2.item8.body"),
          listItems: [
            t("section2.table2.item8.listItems.item1"),
            t("section2.table2.item8.listItems.item2"),
            t("section2.table2.item8.listItems.item3"),
          ],
          link: "http://www.immigration-quebec.gouv.qc.ca/en/home.html",
          province: t("section2.table2.item8.province"),
        },

        {
          title: t("section2.table2.item9.title"),
          body: t("section2.table2.item9.body"),
          link: "https://www.saisia.ca/",
          province: t("section2.table2.item9.province"),
        },
      ],
    },
    {
      id: "Employment",
      title: t("section2.table3.header"),
      url: "/images/photo1.jpeg",
      items: [
        {
          body: t("section2.table3.item1.body"),
          linkTitle: t("section2.table3.item1.linkTitle"),
          internalLink: "/search-welding-jobs-canada",
        },
        {
          body: t("section2.table3.item2.body"),
          linkTitle: t("section2.table3.item2.linkTitle"),
          link: "https://www.jobbank.gc.ca/findajob/newcomers",
        },
        {
          body: t("section2.table3.item3.body"),
          linkTitle: t("section2.table3.item3.linkTitle"),
          link: "https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees/economic-mobility-pathways-pilot.html",
        },
        {
          body: t("section2.table3.item4.body"),
          linkTitle: t("section2.table3.item4.linkTitle"),
          link: "https://jumpstartrefugee.ca/",
        },
        // {
        //     title: 'Job Bank Canada',
        //     body: `The Canadian Employment Connections (CEC) program is a free pre-arrival employment
        //             program that offers virtual services to prepare internationally-trained professionals for the Canadian
        //             job market as they prepare to immigrate to Canada.`,
        //     link: 'https://accesemployment.ca/index.php/planning-your-move-to-canada'
        // },
        // {
        //     title: 'Indeed',
        //     body: `Indeed: Job Search Canada`,
        //     link: 'https://ca.indeed.com/'
        // },
        // {
        //     title: 'LinkedIn',
        //     body: `LinkedIn is mainly used for professional networking, including employers posting jobs and job seekers posting their CVs.`,
        //     link: 'https://www.linkedin.com/'
        // },
        // {
        //     title: 'CWB Group Careers',
        //     body: `CWB Group Careers`,
        //     link: 'https://www.cwbgroup.org/about/careers '
        // }
      ],
    },
    {
      id: "Assistance",
      title: t("section2.table4.header"),
      url: "/images/photo1.jpeg",
      items: [
        {
          title: t("section2.table4.item1.title"),
          body: t("section2.table4.item1.body"),
          link: "https://www.aiclf.ca/",
        },
        {
          title: t("section2.table4.item2.title"),
          body: t("section2.table4.item2.body"),
          link: "https://www.canada.ca/en/employment-social-development/services/funding/foreign-credential-recognition.html",
        },
      ],
    },
    {
      id: "Immigrants",
      title: t("section2.table5.header"),
      url: "/images/photo1.jpeg",
      items: [
        {
          title: t("section2.table5.item1.title"),
          body: t("section2.table5.item1.body"),
          link: "https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada.html",
        },
      ],
    },
  ];
  return (
    <>
      <Helmet>
        <title>{t("metaTitle")}</title>
        <meta name="description" content={t("metaDesc")} />
      </Helmet>
      {/** Banner */}

      <Banner
        src={internationalHeroMobile}
        srcset={internationalHeroDesktop}
        alt={""}
        iconSrc={iconInternationl}
        iconAlt={t("bannerIcon")}
        title={t("banner")}
        jc={"center"}
      />
      {/** End Banner */}
      <Container maxWidth="xl" className={classes.container}>
        <ScrollTopButton />
        <section className={classes.section}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={8}
              className={`${classes.headerContainer} ${classes.padding}`}
            >
              <div className={classes.headerIconContainer}>
                <img
                  className={classes.headerIcon}
                  src={iconLocation}
                  alt={t("section1.headerIconAlt")}
                />
              </div>

              <div className={classes.headerTextContainer}>
                <Typography variant="h2" align="left">
                  {t("section1.header")}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Box mt={4}>
            <Grid
              container
              justifyContent="center"
              alignItems="flex-start"
              spacing={3}
              className={`${classes.imageSection}`}
              style={{ margin: 0, width: "100%" }}
            >
              <Grid item xs={12} md={8}>
                <div className={` ${classes.paddingSmall}`}>
                  <Typography
                    variant="body1"
                    color="inherit"
                    align="left"
                    gutterBottom
                  >
                    {t("section1.text1")}
                  </Typography>
                  <Typography variant="body1" align="left" gutterBottom>
                    {t("section1.text2")}
                  </Typography>
                </div>
              </Grid>

              <Grid item xs={12} md={4} className={classes.imageContainer}>
                {/* <Paper className={`${classes.image}`} style={{backgroundImage: `url(${bodyImage1})`}}>
                      </Paper> */}
                <img
                  className={classes.image}
                  src={bodyImage1}
                  alt={t("section1.imageAlt")}
                />
              </Grid>
            </Grid>
          </Box>
        </section>

        <Grid container>
          <Grid
            item
            xs={12}
            md={8}
            className={`${classes.headerContainer} ${classes.padding}`}
          >
            <div className={classes.headerIconContainer}>
              <img
                className={classes.headerIcon}
                src={iconNewcomer}
                alt={t("section2.headerIconAlt")}
              />
            </div>

            <div className={classes.headerTextContainer}>
              <Typography variant="h2" align="left">
                {t("section2.header")}
              </Typography>
            </div>
          </Grid>
        </Grid>

        <Box mt={4}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
            className={`${classes.padding}`}
            style={{ margin: 0, width: "100%" }}
          >
            {/* <Hidden xlDown>  */}
            <Grid item md={2} sx={{ display: { xs: "none", xl: "block" } }}>
              <div className={classes.sidebar}>
                <ScrollMenu sections={sections} offset={-250} />
              </div>
            </Grid>
            {/* </Hidden> */}

            <Grid item xs={12} lg={10} className={classes.sectionContainer}>
              {sections.map((sec) => (
                <div key={sec.id}>
                  {/* { <Hidden mdUp  >
                      <ScrollMenuMobile 
                          sections={sections} 
                          selectedIndex={selectedIndexMobile} 
                          setSelectedIndex={setSelectedIndexMobile}
                      />
                  </Hidden> } */}
                  <section className={classes.section} id={`${sec.id}`}>
                    <ListSection title={sec.title} items={sec.items} />
                  </section>
                </div>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default InternationalWelders;
