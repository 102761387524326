import request from './base';
import {excludeObjNullValue} from '../utils/common';

export const contact = (payload) => request.postJSON(
    '/api/contact-us',
    excludeObjNullValue({
        email: payload.email,
        message: payload.message,
    })
)
