import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  Paper,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  FormLabel,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useAuth } from "./../components/useAuth";
import { useTranslation } from "react-i18next";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const MyAccount = () => {
  const classes = useStyles();
  const { t } = useTranslation("myAccount");
  const { token } = useAuth();
  const [userData, setUserData] = useState(null);
  const [formData, setFormData] = useState({
    email: "",
    confirmEmail: "",
    city: "",
    province: "",
    postcode: "",
    age_range: "",
    gender: "",
  });
  const [emailChanged, setEmailChanged] = useState(false);

  const genderData = [
    { id: "male", label: t("fields.gender.male") },
    { id: "female", label: t("fields.gender.female") },
    { id: "non_binary", label: t("fields.gender.non_binary") },
    { id: "transgender", label: t("fields.gender.transgender") },
    { id: "other", label: t("fields.gender.other") },
    { id: "prefer_not", label: t("fields.gender.prefer_not") },
  ];

  const ageRangeData = [
    { id: "16-29", label: t("fields.ageRange.16_29") },
    { id: "30-39", label: t("fields.ageRange.30_39") },
    { id: "40-49", label: t("fields.ageRange.40_49") },
    { id: "50-59", label: t("fields.ageRange.50_59") },
    { id: "60+", label: t("fields.ageRange.60_plus") },
  ];

  useEffect(() => {
    const fetchUserData = async () => {
      if (!token) return;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/check-profile`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUserData(response.data.user);
        setFormData({
          email: response.data.user.email,
          confirmEmail: "",
          city: response.data.user.city,
          province: response.data.user.province,
          postcode: response.data.user.postcode,
          age_range: response.data.user.age_range,
          gender: response.data.user.gender,
        });
      } catch (error) {
        console.error(t("errors.fetchUserData"), error);
      }
    };

    fetchUserData();
  }, [token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "email") {
      setEmailChanged(value !== userData.email);
    }
  };

  const handleSave = async () => {
    if (emailChanged && formData.email !== formData.confirmEmail) {
      alert(t("errors.emailMismatch"));
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/update-account`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert(t("success.profileUpdated"));
    } catch (error) {
      console.error(t("errors.updateProfile"), error);
      alert(t("errors.updateProfile"));
    }
  };

  const handleDelete = async () => {
    if (window.confirm(t("confirm.deleteProfile"))) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_SERVER_BASE_URL}/delete-account`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        alert(t("success.profileDeleted"));
      } catch (error) {
        console.error(t("errors.deleteProfile"), error);
        alert(t("errors.deleteProfile"));
      }
    }
  };

  if (!userData) {
    return <Typography>{t("loading")}</Typography>;
  }

  return (
    <Container className={classes.container}>
      <Typography variant="h2" className={classes.title}>
        {t("title")}
      </Typography>
      <Paper className={classes.paper}>
        <Typography variant="h6" gutterBottom>{t("personalInfo.title")}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t("fields.first_name")}
              value={userData.first_name}
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t("fields.last_name")}
              value={userData.last_name}
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t("fields.email")}
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              fullWidth
              color="secondary"
              type="email"
              sx={{ "& .MuiInputLabel-root": { color: "tertiary.dark" } }}
            />
          </Grid>
          {emailChanged && (
            <Grid item xs={12}>
              <TextField
                label={t("fields.confirmEmail")}
                name="confirmEmail"
                value={formData.confirmEmail}
                onChange={handleInputChange}
                fullWidth
                type="email"
                sx={{ "& .MuiInputLabel-root": { color: "tertiary.dark" } }}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <TextField
              label={t("fields.city")}
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              fullWidth
              sx={{ "& .MuiInputLabel-root": { color: "tertiary.dark" } }}
              color="secondary"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t("fields.province")}
              name="province"
              value={formData.province}
              onChange={handleInputChange}
              fullWidth
              sx={{ "& .MuiInputLabel-root": { color: "tertiary.dark" } }}
              color="secondary"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t("fields.postcode")}
              name="postcode"
              value={formData.postcode}
              onChange={handleInputChange}
              fullWidth
              sx={{ "& .MuiInputLabel-root": { color: "tertiary.dark" } }}
              color="secondary"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel color="secondary" sx={{ color: "tertiary.dark" }}>
                {t("fields.selectGenderLabel")}
              </InputLabel>
              <Select
                name="gender"
                value={formData.gender}
                onChange={handleInputChange}
                color="secondary"
              >
                {genderData.map((gender) => (
                  <MenuItem key={gender.id} value={gender.id}>
                    {gender.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <FormLabel sx={{ color: "text.primary" }} color="secondary">
                {t("fields.ageRange.title")}
              </FormLabel>
              <RadioGroup
                row
                name="age_range"
                value={formData.age_range}
                onChange={handleInputChange}
              >
                {ageRangeData.map((ageRange) => (
                  <FormControlLabel
                    key={ageRange.id}
                    value={ageRange.id}
                    control={
                      <Radio
                        sx={{ color: "text.primary" }}
                        color="secondary"
                      />
                    }
                    label={ageRange.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleSave}
        >
          {t("actions.updateProfile")}
        </Button>
      </Paper>
      <Paper className={classes.paper}>
        <Typography variant="h6">{t("deleteAccount.title")}</Typography>
        <Typography>{t("deleteAccount.warning")}</Typography>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={handleDelete}
        >
          {t("actions.deleteAccount")}
        </Button>
      </Paper>
    </Container>
  );
};

export default MyAccount;
