import React, { useState } from "react";
import {
  Typography,
  Box,
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm, Form } from "../../components/useForm";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../components/useAuth";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";

const initialValues = {
  email: "",
  password: "",
};

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.secondary.main,
  },
  buttonRoot: {
    textTransform: "none",
    borderRadius: "0.8rem",
  },
}));

const LoginForm = ({ setOpenPopup, setShowSetupProfile }) => {
  const classes = useStyles();
  const { signin } = useAuth();
  const [submitting, setSubmitting] = useState(false);
  const [formError, setFormError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  let location = useLocation();
  let navigate = useNavigate();
  const { t } = useTranslation(["common", "error"]);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validate = () => {
    let temp = {};
    const re = /\S+@\S+\.\S+/;
    temp.email = re.test(values.email) ? "" : t("error:emailInvalid");
    temp.password = values.password.length > 5 ? "" : t("error:minCharacters");

    setErrors({ ...temp });

    return Object.keys(temp).every((key) => temp[key] === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useForm(initialValues);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError("");
    setSuccessMessage("");
    if (validate()) {
      setSubmitting(true);
      signin(values.email, values.password)
        .then((user) => {
          setSubmitting(false);
          setSuccessMessage("Sign in successful");
          setTimeout(() => {
            navigate("/dashboard");
          }, 1000);
        })
        .catch((err) => {
          setFormError(t("error:invalidEmailPassword"));
          setSubmitting(false);
        });
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        {formError && (
          <Box my={3} p={1} bgcolor="secondary.light">
            <Typography variant="subtitle2" color="error">
              {formError}
            </Typography>
          </Box>
        )}
        {successMessage && (
          <Box my={3} p={1} bgcolor="success.light">
            <Typography variant="subtitle2" color="success">
              {successMessage}
            </Typography>
          </Box>
        )}
        <Grid container>
          <Grid item xs={12}>
            <TextField
              label={t("loginForm.fields.email")}
              name="email"
              value={values.email}
              onChange={handleInputChange}
              error={Boolean(errors.email)}
              color="secondary"
              sx={{ "& .MuiInputLabel-root": { color: "tertiary.dark" } }}
              autoComplete="email"
              type="email"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl sx={{ m: 1 }} variant="outlined">
              <InputLabel
                sx={{ color: "text.primary" }}
                color="secondary"
                htmlFor="outlined-adornment-password"
              >
                {t("loginForm.fields.password")}
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                name="password"
                type={showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleInputChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={t("loginForm.fields.password")}
                error={Boolean(errors.password)}
                autoComplete="password"
                color="secondary"
                sx={{ "& .MuiInputLabel-root": { color: "tertiary.dark" } }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Box
          mx={1}
          my={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            color="primary"
            size="large"
            variant="contained"
            disableElevation
            className={classes.buttonRoot}
            endIcon={<ArrowForwardIcon />}
            type="submit"
            disabled={submitting}
            sx={{ fontSize: "1em", fontWeight: "bold" }}
          >
            {t("loginForm.loginButton")}
          </Button>
          <Box
            my={2}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box mr={1}>
              <Typography variant="subtitle2">
                {t("loginForm.forgotPassword")}
              </Typography>
            </Box>
            <Link className={classes.link} to="/reset">
              <Typography variant="subtitle2">
                <b>{t("loginForm.clickHere")}</b>
              </Typography>
            </Link>
          </Box>
        </Box>
      </Form>
    </div>
  );
};

export default LoginForm;
