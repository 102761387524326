import React from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('lg')]: {
            transform: `rotate(90deg)`
        }
    }
}));
const ArrowIcon = () => {
    const classes= useStyles();
    return (
        <ArrowForwardIcon fontSize="large" className={classes.root} color="secondary"/>
    )
}

export default ArrowIcon
