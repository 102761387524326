import React, {useEffect, useState} from 'react'
import { Grid, Typography, Box, Button, useMediaQuery, TextField, Container} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {useForm, Form} from '../components/useForm';
import Input from '../components/Input';

import * as api from '../api';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    button: {
        width: '80%',
    },
    buttonRoot: {
        textTransform: 'none',
        borderRadius: '0.8rem',
        padding: '4px 24px'
    },
}));
const initialValues = {
    email: '',
}
const ResetPassword = () => {
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);
    const [formError, setFormError] = useState('');
    const [result, setResult] = useState('');
    const { t } = useTranslation(['common', 'error']);

    const validate = () => {
        let temp = {}
        const re = /\S+@\S+\.\S+/
        temp.email = re.test(values.email) ? "" : t('error:emailInvalid')

        setErrors({
            ...temp
        })

        //return Object.values(temp).every(x => x === "")
        var tempError = Object.keys(temp).map(function(key){
            return temp[key]
        });
        
        return tempError.every(x => x === "")
    }
    const {
        values, 
        setValues,
        errors,
        setErrors, 
        handleInputChange
    } = useForm(initialValues);

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormError('');
        if(validate()){
            setSubmitting(true);
            api.emailPasswordReset(values.email)
                .then((res) => {
                    setResult(t('error:passwordResetSuccess', {email: values.email}))
                    setSubmitting(false);
                    
                })
                .catch(err => {
                    setFormError(t('error:passwordResetError'));
                    setSubmitting(false);
                });
        }
    }
    return (
        <div>
            <Container maxWidth="sm">
                <Box my={3}>
                    <Grid container spacing={1} >
                        <Grid item xs={12}>
                            <Typography variant="h3" align="center">
                                {t('common:passwordReset.resetPasswod')}
                            </Typography>
                        </Grid>
                        
                        <Grid item xs={12}>
                        {
                            result ? (
                                <Box my={3} p={1} bgcolor="secondary.light">
                                    <Typography variant="subtitle2" color="textPrimary" align="center">
                                        {result}
                                    </Typography>
                                </Box>
                            ) : (
                            <Form onSubmit={handleSubmit}>
                                {
                                    formError &&
                                    <Box my={3} p={1} bgcolor="secondary.light">
                                        <Typography variant="subtitle2" color="error">
                                            {formError}
                                        </Typography>
                                    </Box>
                                }
                                <Input  
                                    label={t('common:passwordReset.email')}
                                    name="email"
                                    value={values.email}
                                    onChange= {handleInputChange}
                                    error={errors.email}
                                />
                                <Box mx={1} my={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                    <Button 
                                        color="primary" 
                                        size="large" 
                                        variant="contained" 
                                        disableElevation
                                        className={classes.buttonRoot}
                                        type="submit"
                                        disabled={submitting}
                                    >
                                        {t('common:passwordReset.resetPasswod')}
                                    </Button>
                                </Box>
                            </Form>
                            )
                        }   
                        </Grid>
                    </Grid>
                </Box> 
            </Container>
        </div>
    )
}

export default ResetPassword;
