import React, {useLayoutEffect} from "react";
import { Typography,useMediaQuery, Box, Container, Grid, Button, IconButton } from "@mui/material";
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Link, useLocation, useNavigate } from "react-router-dom";
import {useAuth} from './useAuth';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    container: {
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      padding: theme.spacing(4, 4),
    },
    banner: {
        backgroundColor: theme.palette.primary.main,
        position:'relative',
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('md')]:{
            marginBottom: theme.spacing(4)
        }
      },
      heroImage: {
        width:'100%',
        maxWidth: '100%',
        // minHeight:'300px',
        maxHeight:'300px',
        height: 'auto',
      },
      heroContainer: {
        position:'absolute',
        width: '60%',
        top:0,
        right: 0,
        marginTop:'5%',
        [theme.breakpoints.down('md')]: {
          width: '100%',
          marginTop:'1.5rem',
          
        },
      },
      heroIcon: {
        height: '70px',
        width: '70px',
        [theme.breakpoints.down('lg')]: {
          height: '40px',
          width: '40px',
        },
      },
      iconContainer: {
        paddingRight: theme.spacing(4)
      },
      heroContent: {
        marginRight: theme.spacing(3),
        [theme.breakpoints.down('md')]:{
          justifyContent: 'center',
          padding: theme.spacing(0, 3)
        }
      },
      textContainer: {
        paddingRight: '40px',
      },
      overlayContainer: {
        backgroundColor: 'black',
        position: 'absolute',
        opacity: '0.9',
        zIndex: 998,
        bottom: 0,
        width: '100%',
        padding: theme.spacing(1, 0),
      },
      overlayWrapper: {
        padding: theme.spacing(1, 4),
        [theme.breakpoints.down('md')]: {
          padding: '0px 8px',
        }
      },
      scroll: {
        position:'fixed',
        bottom: '0',
      },
      buttonRoot: {
        textTransform: 'none',
        borderRadius: '0.8rem',
        padding: '4px 24px'
    },
    closeIcon: {
      color: 'white',
      marginLeft: theme.spacing(1),
      cursor: 'pointer'
    },
}));

const Banner = ({src, srcset, alt = '', iconSrc, iconAlt='', title, jc}) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const lessThan400 = useMediaQuery('(max-width:400px');
    const [overlay, setOverlay] = React.useState(true);
    const [scrolled, setScrolled] = React.useState(false);
    const location = useLocation();
    const history = useNavigate();
    const {user, token} = useAuth();
    useLayoutEffect(() => {
      const handleScroll = e => {
        setScrolled(window.scrollY > 0)
      }
  
      window.addEventListener("scroll", handleScroll)
  
      return () => {
        window.removeEventListener("scroll", handleScroll)
      }
    }, []);
  
    // const closeOverlay = () => {
    //   setOverlay(!overlay);
    // }

    // const handleClick = (e) => {
    //   if(token) {
    //     history.push({
    //       pathname: '/dashboard'
    //     })
    //   } else {
    //     history.push({
    //       pathname: '/signup',
    //       state: {background: {hash: "", pathname: location.pathname, search: "", state: undefined}, goTo: 'dashboard' }
    //     })
    //   }
      
    // }


    return(
      <div className={classes.banner}>
          <Container maxWidth="xl">
            <Box py={4} display="flex" justifyContent="flex-start" alignItems="center">
              <div className={classes.iconContainer}>
                <img src={iconSrc} alt={iconAlt} className={classes.heroIcon} />
              </div>

              <div >
                <Typography variant="h1" align="left">
                    {title}
                </Typography>
              </div>
            </Box>
            </Container>
        {/* <div className={classes.overlay}></div> */}
        {/* <picture>
          <source srcSet={srcset} media="(min-width: 640px)"/>
          <img src={src} alt={alt}  className={classes.heroImage}/>
        </picture> */}
        {/* <div className={classes.heroContainer}>
          <Box display="flex" alignItems="center" justifyContent={jc} className={classes.heroContent}> 
            <div className={classes.iconContainer}>
              <img src={iconSrc} alt={iconAlt} className={classes.heroIcon} />
            </div>

            <div className={classes.textContainer}>
              <Typography variant="h1" align="left">
                  {title}
              </Typography>
            </div>
          </Box>
        </div> */}
              {/* {
                lessThan400 ? (
                  <>
                  <Typography variant="h3" align="left">
                    <b>{title}</b>
                  </Typography>
                  
                </>
                ): (
                  <>
                  <Typography variant="h2" align="left">
                    <b>{title}</b>
                  </Typography>
              
                </>
                )
              } */}
           
        
        {/* <div>
        {
          overlay ? 
          (<div className={`${classes.overlayContainer} ${scrolled? classes.scroll: null}`} >
            <Container maxWidth="lg">
              <div className={classes.overlayWrapper}>
                <Grid container alignItems="center" justify="space-between">
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body1" style={{color: 'white'}}>
                      Want to  test your knowledge to see how your skills align to Canadian standards?
                    </Typography>
                    <Typography variant="body1" color="primary" gutterBottom>
                      Take the <b>FREE</b> assessment to find out.
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={4} >
                    <Box display="flex"  >
                      <Button 
                        size="small" 
                        color="primary" 
                        variant="contained" 
                        disableElevation 
                        className={classes.buttonRoot}
                        // component={Link}
                        onClick={handleClick}
                        // to={{
                        //   pathname:"dashboard",
                        //   // state: { background: {hash: "", pathname: "/dashboard", search: "", state: undefined} }
                        //   // state: {background: location}
                          
                        // }}
                        >
                          <Typography variant="h5">
                          Start Assessment
                          </Typography>
                      </Button>
                      <CloseIcon fontSize="small" onClick={closeOverlay} className={classes.closeIcon}/>
                    </Box>

                  </Grid>

                </Grid>
                </div>
            </Container>      
          </div>):null
        }
        </div> */}
      </div>
    );
}

export default Banner;