import React from "react";
import { Grid, Typography, Container, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Helmet } from "react-helmet";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  container: {
    // height: '100vh',
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  section: {
    marginTop: theme.spacing(3),
  },
}));
const TermsAndConditions = () => {
  const classes = useStyles();
  const { t } = useTranslation("terms");
  return (
    <>
      <Helmet>
        <title>{t("metaTitle")}</title>
        <meta name="description" content={t("metaDesc")} />
      </Helmet>
      <Container maxWidth="xl" className={classes.container}>
        <section className={classes.section}>
          <Box mb={2}>
            <Typography variant="h1" align="left" gutterBottom>
              {t("title")}
            </Typography>
          </Box>
        </section>
        <section className={classes.section}>
          <Box mb={2}>
            <Typography variant="h2" align="left" gutterBottom>
              {t("section1.header")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" gutterBottom>
              {t("section1.text1")}
            </Typography>
          </Box>
        </section>
        <section className={classes.section}>
          <Box mb={2}>
            <Typography variant="h2" align="left" gutterBottom>
              {t("section2.header")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" gutterBottom>
              {t("section2.text1")}
            </Typography>
          </Box>
        </section>
        <section className={classes.section}>
          <Box mb={2}>
            <Typography variant="h2" align="left" gutterBottom>
              {t("section3.header")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" gutterBottom>
              {t("section3.text1")}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t("section3.text2")}
            </Typography>
          </Box>
        </section>
        <section className={classes.section}>
          <Box mb={2}>
            <Typography variant="h2" align="left" gutterBottom>
              {t("section4.header")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" gutterBottom>
              {t("section4.text1")}
            </Typography>
          </Box>
        </section>
        <section className={classes.section}>
          <Box mb={2}>
            <Typography variant="h2" align="left" gutterBottom>
              {t("section5.header")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" gutterBottom>
              {t("section5.text1")}
            </Typography>
          </Box>
        </section>
        <section className={classes.section}>
          <Box mb={2}>
            <Typography variant="h2" align="left" gutterBottom>
              {t("section6.header")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" gutterBottom>
              {t("section6.text1a")} <Link to="/privacy">{t("section6.privacyPolicy")}.</Link> {t("section6.text1b")}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t("section6.text2")}
            </Typography>
          </Box>
        </section>
        <section className={classes.section}>
          <Box mb={2}>
            <Typography variant="h2" align="left" gutterBottom>
              {t("section7.header")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" gutterBottom>
              {t("section7.text1")}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t("section7.text2")}
            </Typography>
          </Box>
        </section>
        <section className={classes.section}>
          <Box mb={2}>
            <Typography variant="h2" align="left" gutterBottom>
              {t("section8.header")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" gutterBottom>
              {t("section8.text1")}
            </Typography>
          </Box>
        </section>
        <section className={classes.section}>
          <Box mb={2}>
            <Typography variant="h2" align="left" gutterBottom>
              {t("section9.header")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" gutterBottom>
              {t("section9.text1")}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t("section9.text2")}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t("section9.text3")}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t("section9.text4")}
            </Typography>
          </Box>
        </section>
        <section className={classes.section}>
          <Box mb={2}>
            <Typography variant="h2" align="left" gutterBottom>
              {t("section10.header")}
            </Typography>
          </Box>
          <Box>
          <Typography variant="body1" gutterBottom>
              {t("section10.text1a")} <Link to="/privacy">{t("section6.privacyPolicy")}</Link> {t("section10.text1b")}
            </Typography>
          </Box>
        </section>
        <section className={classes.section}>
          <Box mb={2}>
            <Typography variant="h2" align="left" gutterBottom>
              {t("section11.header")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" gutterBottom>
              {t("section11.text1")}
            </Typography>
          </Box>
        </section>
        <section className={classes.section}>
          <Box mb={2}>
            <Typography variant="h2" align="left" gutterBottom>
              {t("section12.header")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" gutterBottom>
              {t("section12.text1")}
            </Typography>
          </Box>
        </section>
        <section className={classes.section}>
          <Box mb={2}>
            <Typography variant="h2" align="left" gutterBottom>
              {t("section13.header")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" gutterBottom>
              {t("section13.text1")}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t("section13.text2")}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t("section13.text3")}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t("section13.text4")}
            </Typography>
          </Box>
        </section>
      </Container>
    </>
  );
};

export default TermsAndConditions;
