import React from 'react'
import { 
    Typography,Box, Container
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import OverViewList from './OverviewList';
import OverViewParagraph from './OverViewParagraph';
import OverViewSections from './OverViewSections';
import {getProvinceFromId} from './data';
import NT from './overviews/NT';
import NU from './overviews/NU';
import YT from './overviews/YT';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    container: {
         backgroundColor: theme.palette.common.white,
        padding:theme.spacing(2, 4),
    }
}));

const displayOverview = (province, overviewData) => {
    switch(province) {
        
        case 'CA-AB': 
        case 'CA-ON':  
        case 'CA-SK': 
        case 'CA-BC': 
        case 'CA-MB':
            return <OverViewList data={overviewData}/>
        case 'CA-PE': 
        case 'CA-NB': 
        case 'CA-NL': 
        case 'CA-NS':
            return <OverViewParagraph data={overviewData}/>
        case 'CA-QC':
            return <OverViewSections data={overviewData} />
        case 'CA-NT': 
            return <NT data={overviewData}/>
        case 'CA-NU': 
            return <NU data={overviewData}/>
        case 'CA-YT':
            return <YT data={overviewData}/>
        default: 
            return <Typography>Overview</Typography>
    }

}

const Overview = ({province}) => {
    const { t } = useTranslation('regulation');
    const provinceData = t('section6.data', {returnObjects: true}).find(item => item.id === province)
    const overviewData = provinceData.overview;
    const overViewComponent = displayOverview(province, overviewData);
    const classes = useStyles();

    return (
        <Container maxWidth="xl">
        <div className={classes.container}>
            <Box my={8}>

           {
            overViewComponent
           }
           </Box>
        </div>
        </Container>
    );
}

export default Overview;