import React from 'react'
import {VectorMap} from 'react-jvectormap';
import {getIndexFromProvinceId} from './provinces';
import {scroller} from 'react-scroll';
import {useTranslation} from "react-i18next";
const Map = ({mapType,selectedIndex, setSelectedIndex, setProvince, province, provinces, setMobileSelectValue }) => {
    const mapRef = React.useRef(null);
    const { t } = useTranslation('regulation');

    const handleClick = (e, code) => {
        const index = provinces.findIndex(item => item.code === code);
        mapRef.current.$mapObject.tip.hide();
        mapRef.current.$mapObject.clearSelectedRegions();
        mapRef.current.$mapObject.setSelectedRegions(code);
        setSelectedIndex(index);
        setProvince(code);
        setMobileSelectValue(code);
        scroller.scrollTo('MapTitle', {
            spy:true,
            smooth:true,
            offset: -100,
            duration: 500
        });
    }
    
    return (
        <div style={{width: '100%', height: '500px'}}>   
            <VectorMap
                regionLabelStyle= {{
                    initial: {
                        fill: '#085f83'
                    },
                    hover: {
                        fill: 'black'
                    },
                    selected: {
                        fill: 'black'
                    }
                }}
                labels={
                    {
                        regions:{
                            render: function(code) {
                                const labelMap={
                                    'CA-AB': t('section5.provinces.AB'),
                                    'CA-BC': t('section5.provinces.BC'),
                                    'CA-MB': t('section5.provinces.MB'),
                                    'CA-NB': t('section5.provinces.NB'),
                                    'CA-NL': t('section5.provinces.NL'),
                                    'CA-NS': t('section5.provinces.NS'),
                                    'CA-NT': t('section5.provinces.NT'),
                                    'CA-NU': t('section5.provinces.NU'),
                                    'CA-ON':t('section5.provinces.ON'),
                                    'CA-PE':t('section5.provinces.PE'),
                                    'CA-QC': t('section5.provinces.QC'),
                                    'CA-SK': t('section5.provinces.SK'),
                                    'CA-YT': t('section5.provinces.YT')
                                };
                                // return labelMap[code];
                                return code.split('-')[1];
                            },
                            // offsets: function(code){
                            //     return {
                            //         //'CA-AB': [0,0],
                            //         //'CA-BC': [0,0],
                            //         //'CA-MB': [0,0],
                            //         //'CA-NB': [0,0],
                            //         //'CA-NL': [0,0],
                            //         //'CA-NS': [0,0],
                            //         //'CA-NT': [0,0],
                            //         //'CA-NU': [0,0],
                            //         //'CA-ON': [0,0],
                            //         'CA-PE': [4,4],
                            //         //'CA-QC': [0,0],
                            //         //'CA-SK': [0,0],
                            //         //'CA-YT': [0,0]
                            //     }
                            // }
                        }
                        
                    }
                }
                map={'ca_lcc'}
                backgroundColor="#FFF"
                zoomOnScroll={false}
                ref={mapRef}
                containerStyle={{
                    width: '100%',
                    height: '100%'
                }}
                containerClassName="map"
                onRegionClick={handleClick}
                regionStyle={{
                    initial: {
                        fill: "#0C89BC",
                        "fill-opacity": 0.9,
                        stroke: "none",
                        "stroke-width": 0,
                        "stroke-opacity": 0
                    },
                    hover: {
                        "fill-opacity": 0.8,
                        cursor: "pointer"
                    },
                    selected: {
                        fill: "#FFD800" //color for the clicked country
                    },
                    selectedHover: {}
                }}
                regionsSelectableOne={true}
                selectedRegions={province}
                
            />
        </div>
    )
}

export default Map;
