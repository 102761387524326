import React from 'react'
import { Grid, Typography, Box, Button } from "@mui/material";
import { makeStyles } from '@mui/styles';
import trainingImage10 from '../../assets/training/training-image10.png';
import trainingImage11 from '../../assets/training/training-image11.png';
import trainingImage12 from '../../assets/training/training-image12.png';
import trainingImage13 from '../../assets/training/training-image13.png';
import trainingImage14 from '../../assets/training/training-image14.png';
import { Link } from "react-router-dom";
import iconDo from '../../assets/icons/icon-do.png';
import {useTranslation} from "react-i18next";
const useStyles = makeStyles((theme) => ({
    listImage: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '250px',
        width:'100%',
        margin: '0 auto',
    },
    paddingSmall: {
        [theme.breakpoints.down('md')]:{
            paddingRight: '12px',
          paddingLeft: '12px',
        }
    },
    bodyImage: {
        width: '100%',
        height: 'auto',
        
    },
    imageSection: {
        [theme.breakpoints.down('sm')]:{
          flexDirection: 'column-reverse',
        }
    },
    imageContainer: {
        width: '100%',
    },
    link: {
        color: theme.palette.secondary.main
    },
    buttonRoot: {
        textTransform: 'none',
        borderRadius: '0.8rem',
        padding: '4px 24px',
        margin:'0 auto',
    },
    logoContainer: {
        flex: '0 1 40px',
    },
    logoImage: {
        height: '40px',
        width: '40px',
        marginRight: theme.spacing(2),
    }
}));


const ListItem = ({url, title, body, alt, linkText}) => {
    const classes = useStyles();
    

    return (
        <Grid item>
            <Box mb={3}>
            <Typography variant="h3" color="secondary" gutterBottom >
                {title}
            </Typography>
            </Box>
            <Grid container justifyContent="center" alignItems="flex-start" spacing={3} className={` ${classes.imageSection}`}>
                <Grid item xs={12} md={6}>
                    <Typography variant="body1" align="left" paragraph>
                        {body}
                    </Typography>

                    {/* <Typography variant="body1" align="left" paragraph>
                    To view the list of colleges of a specific province, please go to Regulation and Certification:
                    </Typography>
                    <Button 
                        color="primary" 
                        size="small" 
                        variant="contained" 
                        disableElevation
                        className={classes.buttonRoot}
                        component={Link}
                        to={{
                            pathname: "/welders-certifications-requirements-canada",
                            state: { scrollToMap: true }
                        }}
                    >
                        <Typography variant="h5">
                        Regulation and Certification
                        </Typography>
                    </Button> */}
                    <Link 
                        className={classes.link} 
                        to={{
                            pathname: "/welders-certifications-requirements-canada",
                            state: { scrollToMap: true }
                        }}>
                            <Typography variant="body1">
                            {linkText}
                            </Typography>
                    </Link>


                </Grid>
                
                <Grid item xs={12} md={6} className={classes.imageContainer}>
                    <img src={url} alt={alt}  className={classes.bodyImage}/>
                </Grid>
                
            </Grid>
        </Grid>
    ); 
}

const SchoolSection = () => {
    const classes = useStyles();
    const { t } = useTranslation('weldingTraining');
    const listSections = [
        {
            url: trainingImage10,
            alt: t('section6.item1.imageAlt'),
            title: t('section6.item1.title'),
            body: t('section6.item1.text'),
            linkText: t('section6.item1.linkText'),
        },
        {
            url: trainingImage11,
            alt: t('section6.item2.imageAlt'),
            title: t('section6.item2.title'),
            body: t('section6.item2.text'),
            linkText: t('section6.item2.linkText'),
        },
        {
            url: trainingImage12,
            alt: t('section6.item3.imageAlt'),
            title: t('section6.item3.title'),
            body: t('section6.item3.text'),
            linkText: t('section6.item3.linkText'),
        },
        {
            url: trainingImage13,
            alt: t('section6.item4.imageAlt'),
            title: t('section6.item4.title'),
            body: t('section6.item4.text'),
            linkText: t('section6.item4.linkText'),
        },
        {
            url: trainingImage14,
            alt: t('section6.item5.imageAlt'),
            title: t('section6.item5.title'),
            body: t('section6.item5.text'),
            linkText: t('section6.item5.linkText'),
        } 
    ]
    return (
        <>
        <div className={classes.paddingSmall}>
            <Box mb={3}>
                <Typography variant="h2" align="left" gutterBottom>
                    {t('section6.header')}              
                </Typography>
            </Box>
            <Box mb={3}>
                <Typography variant="body1" align="left" gutterBottom>
                    {t('section6.text1')}
                </Typography>
            </Box>
            <Grid container spacing={4} >
                {listSections.map((section, idx) => 
                   
                    <ListItem 
                        key={idx}
                        url={section.url}
                        title={section.title}
                        body={section.body}
                    /> 
                )}
            </Grid>
        </div>
        </>
    )
}

export default SchoolSection;
