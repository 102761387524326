import React, { useEffect } from "react";
import { Grid, Typography, Box, Button, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useAuth } from "../../components/useAuth";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ScrollMenu from "../../components/ScrollMenu";
import Banner from "../../components/Banner";
import assessmentHeroDeskTop from "../../assets/assessment/assessment-header-desktop.png";
import assessmentHeroMobile from "../../assets/assessment/assessment-header-mobile.png";
import iconInternational from "../../assets/icons/9-Assessment.png";
import { Element } from "react-scroll";
import ScrollTopButton from "../../components/ScrollTopButton";
import { Helmet } from "react-helmet";
// import { fetchPastResultsPresent } from "../../api/quiz";
import { scroller } from "react-scroll";
import { useTranslation } from "react-i18next";
import Popup from "../../components/Popup";
import Signup from "../../pages/Signup";

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(4),
    },
  },
  sidebar: {
    position: "sticky",
    top: "10px",
  },
  itemMargin: {
    marginBottom: theme.spacing(2),
  },
  padding: {
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
  },
  buttonRoot: {
    textTransform: "none",
    borderRadius: "0.8rem",
    padding: "4px 24px",
    margin: theme.spacing(2, 0),
  },
  buttonContainer: {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  bottomSection: {
    paddingBottom: "30%",
  },
  container: {
    [theme.breakpoints.down("md")]: {
      margin: 0,
      padding: 0,
    },
  },
  tableContainer: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "1rem",
    overflow: "hidden",
    backgroundColor: "#FFF",
  },
  table: {
    tableLayout: "fixed",
    "& thead th": {
      wordWrap: "break-word",
      fontWeight: "600",
      color: theme.palette.secondary.main,
    },
    "& tbody td": {
      wordWrap: "break-word",
      fontWeight: "300",
    },
    "& .MuiTableCell-root": {
      borderBottom: "none",
    },
    "& .MuiTableHead-root": {
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
    },
    "& .MuiTableRow-root": {
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
    },
    "& .MuiTableRow-root:nth-last-child(1)": {
      borderBottom: "none",
    },
  },
  link: {
    color: theme.palette.secondary.main,
  },
}));

const Assessment = (props) => {
  const classes = useStyles();
  const [openPopup, setOpenPopup] = React.useState(false);
  const navigate = useNavigate();
  const { user, token } = useAuth();
  let location = useLocation();
  const { t } = useTranslation("assessment");

  const sections = [
    { id: "Overview", title: t("navButtons.overview") },
    { id: "Cost", title: t("navButtons.cost") },
    { id: "Prepare", title: t("navButtons.prepare") },
    { id: "StartAssessment", title: t("navButtons.startAssessment") },
  ];

  useEffect(() => {
    if (location.state?.scrollToStartAssessment) {
      scroller.scrollTo("StartAssessment", {
        spy: true,
        smooth: true,
        offset: -50,
        duration: 500,
      });
    }
  }, [location.state]);

  const handleNavigation = (path) => {
    if (token) {
      navigate(path);
    } else {
      setOpenPopup(true);
      navigate("/signup", {
        state: { background: location, goTo: path },
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>{t("metaTitle")}</title>
        <meta name="description" content={t("metaDesc")} />
      </Helmet>
      <Banner
        src={assessmentHeroMobile}
        srcset={assessmentHeroDeskTop}
        alt={""}
        iconSrc={iconInternational}
        iconAlt={t("bannerIcon")}
        title={t("banner")}
        jc={"center"}
      />
      <Container maxWidth="xl" className={classes.container}>
        <ScrollTopButton />
        <Grid
          container
          justifyContent="center"
          spacing={2}
          className={`${classes.padding}`}
          style={{ margin: 0, width: "100%" }}
        >
          <Grid item lg={2} sx={{ display: { xs: "none", xl: "block" } }}>
            <div className={classes.sidebar}>
              <ScrollMenu sections={sections} offset={-250} />
            </div>
          </Grid>

          <Grid item xs={12} xl={10}>
            <Element name="Overview">
              <section className={classes.section} id="Overview">
                <Box>
                  <Typography variant="h2" gutterBottom align="left">
                    {t("section1.header")}
                  </Typography>
                  <Box mt={3}>
                    <Typography variant="body1" gutterBottom align="left">
                      {t("section1.text")}
                    </Typography>
                  </Box>
                  {t("section1.items", { returnObjects: true }).map(
                    (item, idx) => (
                      <Box key={idx} my={3}>
                        <Typography
                          variant="h3"
                          gutterBottom
                          align="left"
                          color="secondary"
                        >
                          {item.title}
                        </Typography>
                        <Typography variant="body1" gutterBottom align="left">
                          {item.body}
                        </Typography>
                        {item.links.length > 0 &&
                          item.links.map((link, index) => (
                            <Link
                              key={index}
                              className={classes.link}
                              to={link}
                              target="_blank"
                            >
                              <Typography variant="body1">{link}</Typography>
                            </Link>
                          ))}
                      </Box>
                    )
                  )}
                </Box>
              </section>
            </Element>

            <Element name="Cost">
              <section className={classes.section} id="Cost">
                <Box pb={4} mb={8}>
                  <Typography variant="h2" gutterBottom align="left">
                    {t("section2.header")}
                  </Typography>
                  <Typography variant="body1" align="left" gutterBottom>
                    {t("section2.text")}
                  </Typography>
                </Box>
              </section>
            </Element>

            <Element name="Prepare">
              <section className={classes.section} id="Prepare">
                <Box pb={4} mb={8}>
                  <Typography variant="h2" gutterBottom align="left">
                    {t("section3.header")}
                  </Typography>
                  <Typography variant="body1" align="left" gutterBottom>
                    {t("section3.text1")}
                  </Typography>
                  <Typography variant="body1" align="left" gutterBottom>
                    {t("section3.text2")}
                  </Typography>
                  <Box mt={2}>
                    <Link
                      className={classes.link}
                      to="/welders-certifications-requirements-canada"
                      state={{ scrollToMap: true }}
                    >
                      <Typography variant="body1">
                        {t("section3.linkText1")}
                      </Typography>
                    </Link>
                  </Box>
                  <Box mt={2}>
                    <Link
                      className={classes.link}
                      to="https://www.cwbgroup.org/education/online-courses"
                      target="_blank"
                    >
                      <Typography variant="body1">
                        {t("section3.linkText2")}
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </section>
            </Element>

            <Element name="StartAssessment">
              <section
                className={`${classes.section} ${classes.bottomSection}`}
              >
                <Box pb={4} mb={8}>
                  <Typography variant="h2" gutterBottom align="left">
                    {t("section5.header")}
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    p={2}
                    borderRadius="16px"
                    bgcolor="secondary.light"
                    height={300}
                  >
                    <Typography
                      variant="h3"
                      align="center"
                      color="secondary"
                      gutterBottom
                    >
                      <b>{t("section5.text1")}</b>
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="space-around"
                      alignItems="center"
                      className={classes.buttonContainer}
                    >
                      <Box mx={1}>
                        <Button
                          endIcon={<ArrowForwardIcon />}
                          color="primary"
                          size="large"
                          variant="contained"
                          disableElevation
                          onClick={() => handleNavigation("/dashboard")}
                          className={classes.buttonRoot}
                        >
                          <Typography variant="h5">
                            {t("section5.buttonText1")}
                          </Typography>
                        </Button>
                      </Box>
                    </Box>
                    {!token && (
                      <Typography
                        variant="h4"
                        align="center"
                        color="secondary"
                        gutterBottom
                      >
                        {t("section5.text2")}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </section>
            </Element>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Assessment;
