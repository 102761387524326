import React, { useState } from "react";
import { NavLink as Link, useLocation, useNavigate } from "react-router-dom";
import {
  Typography,
  Box,
  Button,
  Menu,
  MenuItem,
  Container,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useAuth } from "../useAuth";
import iconCwbGroup from "../../assets/icons/icon-cwb-group.png";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useTranslation } from "react-i18next";
import LanguageSelect from "./LanguageSelect";

const DashboardMobileMenu = (props) => {
  let currentLocation = useLocation();
  const navigate = useNavigate();
  const {
    location,
    setOpenPopup,
    language,
    languageOptions,
    handleLanguageChange
  } = props;
  const classes = useStyles();
  const [anchorElProfile, setAnchorElProfile] = useState(null);
  const { user, signout, token } = useAuth();

  const { t } = useTranslation("common");

  const handleMenu = (event) => {
    setAnchorElProfile(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElProfile(null);
  };

  return (
    <Container maxWidth="false" className={classes.wrapper}>
      <div className={classes.root}>
        <nav className={classes.container}>
          <Box>
            <Link className={classes.link} to="/">
              <img className={classes.logo} src={iconCwbGroup} alt="CWB Logo" />
            </Link>
          </Box>

          <Box
            className={classes.buttonWrapper}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="nowrap"
          >
            {token ? (
              <Box>
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  className={`${classes.itemMargin} ${classes.buttonRoot}`}
                  onClick={handleMenu}
                >
                  <Typography variant={"body1"} mr={1}>
                    <b>{t("topMenu.profileBtn")}</b>
                  </Typography>
                  <AccountCircle />
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElProfile}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElProfile)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={(e) => {
                        handleClose(e);
                      }}
                      component={Link}
                      to="/dashboard"
                    >
                      {t("topMenu.dashboard")}
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => {
                        handleClose(e);
                      }}
                      component={Link}
                      to="/my-account"
                    >
                      {t("topMenu.myAccount")}
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => {
                        signout();
                        handleClose(e);
                      }}
                    >
                      {t("topMenu.logoutBtn")}
                    </MenuItem>
                  </Menu>
                </Button>
              </Box>
            ) : (
              <Button
                color="primary"
                variant="contained"
                disableElevation
                className={classes.buttonRoot}
                onClick={() => {
                  setOpenPopup(true);
                  navigate("/signup", {
                    state: { background: currentLocation },
                  });
                }}
              >
                <b>{t("topMenu.signupBtn")}</b>
              </Button>
            )}
            <div className={`${classes.itemMargin} ${classes.lanContainer}`}>
              <LanguageSelect
                language={language}
                handleLanguageChange={handleLanguageChange}
                languageOptions={languageOptions}
              />
            </div>
          </Box>
        </nav>
      </div>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingRight: "0px",
    paddingLeft: "0px",
  },
  list: {
    display: "flex",
  },
  listItem: {
    paddingBottom: "0",
    paddingRight: 0,
    width: "auto",
    "&::after": {
      content: '"|"',
      float: "right",
      color: theme.palette.secondary.main,
      paddingLeft: theme.spacing(1),
    },
    "&:last-child::after": {
      display: "none",
    },
  },
  listItemText: {
    cursor: "pointer",
    "& span": {
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "bold",
      lineHeight: 1,
    },
  },
  menuLink: {
    fontSize: "20px",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  link: {
    color: "black",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      color: "black",
    },
  },
  lanContainer: {
    fontSize: "16px",
  },
  language: {
    color: "black",
    textDecoration: "none",
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(1, 0),
  },
  title: {
    flexGrow: 1,
  },
  itemMargin: {
    margin: theme.spacing(0, 1.5),
  },
  logo: {
    height: "auto",
    width: "80px",
  },
  activeLink: {
    borderBottom: `0.15rem solid ${theme.palette.secondary.main}`,
  },
  buttonRoot: {
    fontSize: "20px",
    textTransform: "none",
    borderRadius: "0.8rem",
    padding: "8px",
    margin: theme.spacing(0, 1.5),
    lineHeight: 1,
    textAlign: "center",
  },
  iconArrow: {
    width: "20px",
    height: "20px",
  },
  linkUnderline: {
    borderBottom: `0.15rem solid ${theme.palette.secondary.main}`,
  },
}));

export default DashboardMobileMenu;
