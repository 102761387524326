import React from 'react';
import { 
    List,
    ListItemText,
    Typography
} from '@mui/material';
import { makeStyles, withStyles } from "@mui/styles";
// import {provinces} from './provinces';
import MuiListItem from "@mui/material/ListItem";
import {scroller} from 'react-scroll';

const useStyles = makeStyles((theme) => ({
    container: {
        // marginLeft: theme.spacing(2)
    }
}));

const ListItem = withStyles((theme)=>({
    root: {
        
        // border: `1px solid ${theme.palette.secondary.main}`,
        // borderRadius: '50%',        
        // backgroundColor: '#FFF',
        // margin: theme.spacing(0,1),
        "&.Mui-selected": {
            // backgroundColor: theme.palette.secondary.main
            color: theme.palette.secondary.main
        },
        "&.Mui-selected:hover": {
            backgroundColor: theme.palette.secondary.light,
        }, 
        "&:hover": {
            color: theme.palette.secondary.main
        }
    },
    selected: {
        color: theme.palette.secondary.main
    }

}))(MuiListItem);

const MapList = ({selectedIndex, setSelectedIndex, setProvince, setMobileSelectValue, provinces}) => {
    const classes = useStyles();

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        if (setProvince && provinces[index]) {

            setProvince(provinces[index].id);
            setMobileSelectValue(provinces[index].code);
            scroller.scrollTo('MapTitle', {
                spy:true,
                smooth:true,
                offset: -100,
                duration: 500
            });
        }
    }
    return (
        <>
            <div className={classes.container}>
                
                <List component="nav">
                    {
                        provinces.map((province, idx) => {
                            return (
                                // <Link to="MapResult" duration={2000}>
                                <ListItem 
                                    key={idx}
                                    button
                                    selected={selectedIndex === idx}
                                    onClick={(event) => handleListItemClick(event, idx)}
                                >    
                                    <ListItemText 
                                        primary={
                                            <Typography variant="h4"><b>{province.label}</b></Typography>
                                        } 
                                    />
                                </ListItem>
                                // </Link>
                            );
                        })
                    }
                </List>
            </div>
        </>
    )
}

export default MapList
