  
import React from "react";
import { 
    Grid, 
    Typography, 
    Hidden,
    Container,
    Box,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
// import Paper from '@mui/material/Paper';
import ScrollMenu from '../components/ScrollMenu';
// import ScrollMenuMobile from '../components/ScrollMenuMobile';
// import Carousel from '../components/Carousel';
import Banner from '../components/Banner';
import iconInformation from '../assets/icons/icon-information.png';
import programHeroDesktop from '../assets/programInformation/program-header-desktop.png';
import programHeroMobile from '../assets/programInformation/program-header-mobile.png';
import bodyImage1 from '../assets/programInformation/bodyImage1.png';
import bodyImage2 from '../assets/programInformation/bodyImage2.png';
import bodyImage3 from '../assets/programInformation/bodyImage3.png';
import bodyImage4 from '../assets/programInformation/bodyImage4.png';
import bodyImage5 from '../assets/programInformation/bodyImage5.png';
import peopleImage1 from '../assets/programInformation/peopleImage1.png';
import peopleImage2 from '../assets/programInformation/peopleImage2.png';
import peopleImage3 from '../assets/programInformation/peopleImage3.png';
import graphImage from '../assets/programInformation/programInfo-graph.jpeg';
import iconInteractive from '../assets/icons/icon-interactive.png';
import iconProcess from '../assets/icons/icon-process.png';
import iconTrend from '../assets/icons/icon-trend.png';
import {Helmet} from "react-helmet";
import ScrollTopButton from "../components/ScrollTopButton";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({

  PageTitle: {
    textAlign: 'left'
  },
  bodyContainer: {
    // paddingLeft: '24px',
    // paddingRight: '24px',
    [theme.breakpoints.down('md')]: {
      padding: 0
    }
  },
  section:{
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  paddingSmall: {
    [theme.breakpoints.down('md')]:{
      paddingRight: '12px',
      paddingLeft: '12px',
    }
  },
  
  imageSection: {
    [theme.breakpoints.down('md')]:{
      flexDirection: 'column-reverse',
    }
  },
  
  imageContainer: {
    width: '100%',
  },
  // image: {
  //   backgroundSize: 'cover',
  //   backgroundRepeat: 'no-repeat',
  //   backgroundPosition: 'center',
  //   height: '300px',
  //   width:'100%',
  //    margin: '0 auto',
  //   borderRadius: '1rem',
  //   [theme.breakpoints.down('sm')]: {
  //    borderRadius: 0
  //   },
  // },

  section3LargeImage: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '400px',
    margin: '0 auto'
  },
  
  circleBig:{
    width: '150px',
    height: '150px',
    borderRadius: '50%',
    textAlign: 'center',
    background: 'black',
    color: 'white',
    display:'flex',
    flexDirection: 'column',
    alignItems:'center',
    justifyContent: 'center',
  },

  sidebar: {
    position: 'sticky',
    top: '10px'
  },

  paperImg: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    margin: '0 auto',
    height: '250px',
    width: '400px',
  },
  section7Container: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    backgroundColor: '#E8EDEF',
  },

  cardRoot: {
    maxWidth: '300px',
    padding: theme.spacing(2),
    border: '1px solid black',
    borderRadius: '2rem'
  },
  cardMedia: {
    margin: '0 auto',
    width: '200px',
    height: '200px',
    [theme.breakpoints.down('md')]: {
      width: '150px',
      height: '150px',
    }
  },
  quoteContainer: {
    textAlign:'center',
    marginBottom: theme.spacing(1),
  },
  quote: {
    color: theme.palette.primary.main,
    fontSize: '4em',
  },
  IconContainer:{

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 3),
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
 
  timelineContainer: {
    padding: 0,
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    listStyle: 'none',
    justifyContent: 'space-between',
    "&:after": {
      content: "''",
      position: 'absolute',
      zIndex: '-1',
      left:'15%',
      width:'70%',
      // height:'2px',
      top: '25%',
      // backgroundColor: theme.palette.secondary.main
      borderTop: `2px dotted ${theme.palette.secondary.main}`
    },

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: 0,
      "&:after": {
        display: 'none'
      }
    }
  },
  timelineListItem: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justify: 'center',
    alignItems: 'center',
    margin: theme.spacing(1),
    flexBasis: '250px',
    [theme.breakpoints.down('md')]: {
      flexBasis: '0',
      maxWidth: '350px',
    },
  },
  timelineIconContainer: {},
  timelineIcon: {
    marginBottom: theme.spacing(1),
    height: '80px',
    width: '80px',
    [theme.breakpoints.down('md')]: {
      height: '50px',
      width: '50px'
    },
  },
  verticalLine: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display:'block',
      position: 'relative',
      zIndex: '-1',
      height: '40px',
      marginLeft: '50%',
      borderLeft: `1px dashed ${theme.palette.secondary.main}`,
    },
  },
  link: {
    textDecoration: 'underline',
    color: theme.palette.secondary.main
  },
  container: {
    
    [theme.breakpoints.down('md')]: {
      margin: 0,
      padding: 0,
      // justifyContent: 'center',
    }
  },
  bottomSection: {
    marginBottom: '25%'
  },
  image: {
    width: '100%',
    height: '300px',
    objectFit: 'cover',
    objectPosition: 'center'
  }
  
}));


const agencies = [
  {name: 'Agency 1', link: 'www.google.com'},
  {name: 'Agency 2', link: 'www.google.com'},
  {name: 'Agency 3', link: 'www.google.com'},

]
const success = [
  {image: peopleImage1, name: 'Ahmed Elsayed', body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore et dolorum harum eligendi alias enim similique, maxime doloribus quisquam libero sunt accusantium quasi quas amet illo laboriosam tempora nesciunt sapiente?'},
  {image: peopleImage2, name: 'Ahmed Elsayed', body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore et dolorum harum eligendi alias enim similique, maxime doloribus quisquam libero sunt accusantium quasi quas amet illo laboriosam tempora nesciunt sapiente?'},
  {image: peopleImage3, name: 'Ahmed Elsayed', body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore et dolorum harum eligendi alias enim similique, maxime doloribus quisquam libero sunt accusantium quasi quas amet illo laboriosam tempora nesciunt sapiente?'}
];
const ProgramInfo = (props) => {
  const classes = useStyles();
  const [selectedIndexMobile, setSelectedIndexMobile] = React.useState(0);
  const { t } = useTranslation('programInfo');
  const sections = [
    {id:'About', title: t('navButtons.about') }, 
    {id:'What', title:t('navButtons.what')}, 
    {id:'Why', title:t('navButtons.why')}, 
    {id:'Where', title: t('navButtons.where')},
  ];
  return <>
    <Helmet>
      <title>
        {t('metaTitle')}
      </title>
      <meta name="description" content={t('metaDescription')} />
    </Helmet>
    {/** Banner */}
    <Banner 
      src={programHeroMobile} 
      srcset={programHeroDesktop} 
      alt={''} 
      iconSrc={iconInformation} 
      iconAlt={t('bannerIconAlt')} 
      title={t('banner')}
      jc={'center'}
    />
    {/** End Banner */}

    <Container maxWidth="xl" className={classes.container}>
      <ScrollTopButton />
    <div className={classes.bodyContainer}>
    
    {/**Section 1 TItle End */}
    
    <Grid container justifyContent="center"  style={{margin: 0,width: '100%',}}>
      {/* <Hidden lgDown>  */}
        <Grid item md={2} sx={{ display: { xs: "none", lg: "block" } }}> 
          <div className={classes.sidebar}>
            <ScrollMenu sections={sections} offset={-80}/>
          </div>
        </Grid>
      {/* </Hidden> */}
      
      <Grid item xs={12} lg={10}>
        {/* <Hidden mdUp>
          <ScrollMenuMobile 
            sections={sections} 
            selectedIndex={selectedIndexMobile} 
            setSelectedIndex={setSelectedIndexMobile} />
        </Hidden> */}
        {/** Section 1 */}
        <section className={`${classes.section}`} id="About">
          <Typography variant="h2" color="inherit" gutterBottom className={classes.paddingSmall}>
            <Box mb={1} pl="12px">{t('section1.header')}</Box>
          </Typography>

          <Grid container spacing={3} justifyContent="center" alignItems="flex-start" className={` ${classes.imageSection}`} style={{margin: 0,width: '100%',}}>

            <Grid item xs={12} md={8}>
              
                <div className={` ${classes.paddingSmall}`}> 
                    <Typography variant="body1" color="inherit" paragraph>
                    {t('section1.listHeader')}
                      </Typography>
                      <ul>
                        <li><Typography variant="body1" color="inherit">{t('section1.listItem1')}</Typography></li>
                        <li><Typography variant="body1" color="inherit">{t('section1.listItem2')}</Typography></li>
                        <li><Typography variant="body1" color="inherit">{t('section1.listItem3')}</Typography></li>
                        <li><Typography variant="body1" color="inherit">{t('section1.listItem4')}</Typography></li>
                        <li><Typography variant="body1" color="inherit">{t('section1.listItem5')}</Typography></li>
                      </ul>
                    
                    <Typography variant="body1" color="inherit" paragraph>
                      {t('section1.text1')}
                    </Typography>
                    <Typography variant="body1" color="inherit" paragraph>
                      {t('section1.text2')}
                    </Typography>
                </div>
              </Grid>

              <Grid item xs={12} md={4} className={classes.imageContainer}>
                {/* <Paper className={`${classes.image}`} style={{ backgroundImage: `url(${bodyImage1})` }}>
                </Paper> */}
                <img className={classes.image} src={bodyImage1} alt={t('section1.imageAlt')}/>
            
              </Grid>
            
          </Grid>
        </section>
        {/** End Section 1 */}

        {/**Section 2 */}
        {/* <Hidden mdUp>
          <ScrollMenuMobile 
            sections={sections} 
            selectedIndex={selectedIndexMobile} 
            setSelectedIndex={setSelectedIndexMobile} />
        </Hidden> */}
        <section className={classes.section} id="What">
          <Typography variant="h2" color="inherit" gutterBottom className={classes.paddingSmall}>
            <Box mb={1} pl="12px">{t('section2.header')}</Box>
          </Typography>

          <Grid container spacing={3} className={`${classes.imageSection}`} justifyContent="center" alignItems="flex-start" style={{margin: 0,width: '100%',}}>
            
            <Grid item xs={12}  md={8}>

              <div className={` ${classes.paddingSmall}`}> 
                <Typography variant="body1" color="inherit" paragraph>
                  {t('section2.text1')}
                  </Typography>
                  <Typography variant="body1" color="inherit" paragraph>
                  {t('section2.text2')}
                  </Typography>
                  <Typography variant="body1" color="inherit" paragraph>
                  {t('section2.text3')}
                </Typography>
                <Typography variant="body1" color="inherit" paragraph>
                {t('section2.text4')}
                </Typography>
              </div>
            </Grid>
            
            <Grid item xs={12} md={4} className={classes.imageContainer}>
                {/* <Paper className={`${classes.image} `} style={{ backgroundImage: `url(${bodyImage2})` }}>
                </Paper> */}
                <img className={classes.image} src={bodyImage2} alt={t('section2.imageAlt')}/>
            </Grid>
          </Grid>

          <Box mt={1}>
            <Box pl="12px">
            <Typography variant="h3" color="secondary" align="left" paragraph className={classes.paddingSmall}>
              
              {t('section2.graphicTitle')}
              
            </Typography>
            </Box>
            <Box className={classes.IconContainer}>
            
              <ul className={classes.timelineContainer}>
                <li className={classes.timelineListItem}>
                  <div className={classes.timelineIconContainer}>
                    <img src={iconProcess} alt={t('section2.timelineIconAlt1')} className={classes.timelineIcon} />
                    </div>
                  <Typography variant="body2" align="center">
                   
                      <b>{t('section2.graphicText1')}</b>
                   
                  </Typography>
                </li>
                <div className={classes.verticalLine}></div>

                <li className={classes.timelineListItem}>
                  <div className={classes.timelineIconContainer}>
                    <img src={iconTrend} alt={t('section2.timelineIconAlt2')} className={classes.timelineIcon} />
                    </div>
                  <Typography variant="body2" align="center">
                 
                    <b>{t('section2.graphicText2')}</b>
              
                  </Typography>
                </li>

                <div className={classes.verticalLine}></div>


                <li className={classes.timelineListItem}>
                  <div className={classes.timelineIconContainer}>
                    <img src={iconInteractive} alt={t('section2.timelineIconAlt3')} className={classes.timelineIcon} />
                    </div>
                  
                  <Typography variant="body2" align="center">
                    <b>
                      {/* An interactive and bilingual web portal to provide evidence-based information on current demands, needs and trends */}
                      {/* Online competency assessment */}
                      {t('section2.graphicText3')}
                    </b>
                  </Typography>
               
                </li>
              </ul>
            </Box>
          </Box>
         
        </section>
        {/**End Section 2 */}
        {/** Section 3 */}
        {/* <Hidden mdUp>
          <ScrollMenuMobile 
            sections={sections} 
            selectedIndex={selectedIndexMobile} 
            setSelectedIndex={setSelectedIndexMobile} />
        </Hidden> */}
        <section className={classes.section} id="Why">
          <Typography variant="h2" color="inherit" gutterBottom className={classes.paddingSmall}>
            <Box mb={1} pl="12px"> {t('section3.header')}</Box>
          </Typography>

          <Grid container spacing={3} className={`${classes.imageSection}`} justifyContent="center" alignItems="flex-start"  style={{margin: 0,width: '100%',}}>
            <Grid item xs={12}  md={8}>
              <div className={classes.paddingSmall}> 
                
                <Typography variant="body1" color="inherit" paragraph>
                {t('section3.text1')}
                  {/* Online competency assessment */}
                </Typography>
                <Typography variant="body1" color="inherit" paragraph gutterBottom>
                {t('section3.listHeader')}
                </Typography>
                    <ul>
                      <li><Typography variant="body1" color="inherit">{t('section3.listItem1')}</Typography></li>
                      <li><Typography variant="body1" color="inherit">{t('section3.listItem2')}</Typography></li>
                      <li><Typography variant="body1" color="inherit">{t('section3.listItem3')}</Typography></li>
                    </ul>
              </div>
            </Grid>
            
            <Grid item xs={12} md={4} className={classes.imageContainer}>
                {/* <Paper className={`${classes.image}`} style={{ backgroundImage: `url(${bodyImage3})` }}>
                </Paper> */}
                <img className={classes.image} src={bodyImage3} alt={t('section3.imageAlt')}/>
            </Grid>                
          </Grid>

       {/*  <Box pt={4}>
          <Typography variant="h5" color="secondary" align="left" paragraph className={classes.paddingSmall}>
            A pathway to rewarding and fulfilling careers
          </Typography>
          <Grid container spacing={3} alignItems="center" style={{margin: 0,width: '100%',}}>
            <Grid item xs={12}>
              <img src={graphImage} alt="average wages for welders" style={{width: '100%', maxWidth: '700px'}}/>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.paddingSmall}>
              <Typography variant="subtitle2" align="left" gutterBottom>
                
                The workforce is aging at a faster pace in the trades than in other
                industries. In fact, 26.1% of workers are 55 years old or older.
              </Typography>
              <Typography variant="subtitle2" align="left" gutterBottom>
                The skilled trades industry must continue to attract young workers with 
                modern and relevant professional development opportunities, such as the 
                WCCA.
              </Typography>
              </div>
            </Grid> 
          </Grid>
        </Box>*/}
        </section>
        {/** End Section 3 */}

        {/** Section 4 */}
        {/* <Hidden mdUp>
          <ScrollMenuMobile 
            sections={sections} 
            selectedIndex={selectedIndexMobile} 
            setSelectedIndex={setSelectedIndexMobile} />
        </Hidden>
        <section className={classes.section} id="Who">
          <Typography variant="h3" color="inherit" gutterBottom className={classes.paddingSmall}>
            <Box mb={3}>Our Partners</Box>
          </Typography>
          <Grid container spacing={3} justify="center" alignItems="flex-start" className={` ${classes.imageSection}`} style={{margin: 0,width: '100%',}}>
            <Grid item xs={12} md={8}>
              <div className={classes.paddingSmall}>
                <Typography variant="body2" paragraph >
                  The CWB GROUP Welder Competency and Credential Assessment Program is funded by the Government of Canada through its Foreign Credential Recognition Program.
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4} className={classes.imageContainer}> 
              <Paper className={`${classes.image}`} style={{ backgroundImage: `url(${bodyImage4})` }}>
              </Paper>
            </Grid>
          </Grid>  
        </section> */}
        {/** End Section 4 */}
        {/** Section 5 */}
        {/* <Hidden mdUp>
          <ScrollMenuMobile 
            sections={sections} 
            selectedIndex={selectedIndexMobile} 
            setSelectedIndex={setSelectedIndexMobile} />
        </Hidden>
        <section className={classes.section} id="When">
          <Typography variant="h3" color="inherit" gutterBottom className={classes.paddingSmall}>
            <Box mb={3}>When does it launch?</Box>
          </Typography>
          <Grid container spacing={3} className={`${classes.paddingSmall}`} style={{margin: 0,width: '100%',}}>    
            <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <Box flex="0 0 150px" mr={3}>
                    <div className={`${classes.circleBig}`}>
                        <div className="circleLargeText">
                          <Typography variant="h1">5</Typography>
                        </div>
                        <div className="circleLargeText">
                          <Typography variant="h4">May, 2020</Typography>
                        </div>
                    </div>
                  </Box>
            
                  <Box flexShrink={1}>
                  <Box mb={2}>
                    <Typography variant="h4" color="secondary" align="left" gutterBottom>
                      
                      <b>Soft Launch</b>
                      
                    </Typography>
                    </Box>
                    <Hidden xsDown>
                      <Typography variant="body2" align="left" gutterBottom>
                        A soft launch of the Welder Competency and Credential
                        Assessment project is scheduled for May 2020.
                      </Typography>
                    </Hidden>
                  </Box>
                </Box>
                <Hidden smUp>
                  <Typography variant="body2" align="left">
                    <Box my={2}>
                      A soft launch of the Welder Competency and Credential
                      Assessment project is scheduled for May 2020.
                    </Box>
                  </Typography>
                </Hidden>

            </Grid>

            <Grid item xs={12}>
              
              <Box display="flex" alignItems="center">
                <Box flex="0 0 150px" mr={3}>
                  <div className={`${classes.circleBig}`}>
                    <div className="circleLargeText">
                      <Typography variant="h1">10</Typography>
                    </div>
                    <div className="circleLargeText">
                      <Typography variant="h4">Oct, 2020</Typography>
                    </div>
                  </div>
                </Box>
             
                <Box flexShrink={1}>
                <Box mb={2}>
                  <Typography variant="h4" color="secondary" align="left" gutterBottom>
                    <b>
                    Hard Launch
                    </b>
                  </Typography>
                  </Box>
                  <Hidden xsDown>
                  <Typography variant="body2" align="left" gutterBottom>
                    A hard launch of this leading WCCA portal is planned for October
                  </Typography>
                  </Hidden>
                </Box>
              </Box>
              <Hidden smUp>
              <Box my={2}>
                <Typography variant="body2" align="left">
                  
                    A hard launch of this leading WCCA portal is planned for October
                  
                </Typography>
                </Box>
              </Hidden>  
            </Grid>
            
          </Grid>  
        </section> */}
        {/** End Section 5 */}
        {/** Section 6 */}
        {/* <Hidden mdUp>
          <ScrollMenuMobile 
            sections={sections} 
            selectedIndex={selectedIndexMobile} 
            setSelectedIndex={setSelectedIndexMobile} />
        </Hidden> */}
        <section className={`${classes.section} ${classes.bottomSection}`} id="Where">
          <Typography variant="h2" color="inherit" gutterBottom className={classes.paddingSmall}>
            <Box mb={1} pl="12px">{t('section4.header')}</Box>
          </Typography>
          <Grid container spacing={3} justifyContent="center" alignItems="flex-start" className={` ${classes.imageSection}`} style={{margin: 0,width: '100%',}}>
            <Grid item xs={12} md={8}>
              <div className={classes.paddingSmall}> 
                <Typography variant="body1" color="inherit" paragraph >
                  {t('section4.text')}
                </Typography>
                
                  <Typography variant="body1" color="secondary" paragraph>
                  <a className={classes.link} href="mailto:weldercompetency@cwbgroup.org" rel="noopener noreferrer">
                    weldercompetency@cwbgroup.org
                    </a>
                  </Typography>

                  
                
              </div>
            </Grid>

            <Grid item xs={12} md={4} className={classes.imageContainer}>
                {/* <Paper className={`${classes.image}`} style={{ backgroundImage: `url(${bodyImage5})` }}>
                </Paper> */}
                <img className={classes.image} src={bodyImage5} alt={t('section4.imageAlt')}/>
            </Grid>
            
          </Grid>
        </section>
        {/** End of Section 6 */}

        {/** Section 7 - DISABLED */}
        {/* <Hidden mdUp>
          <ScrollMenuMobile 
            sections={sections} 
            selectedIndex={selectedIndexMobile} 
            setSelectedIndex={setSelectedIndexMobile} />
        </Hidden>
        <section className={classes.section} id="Partners">
          <Typography variant="h3" color="inherit" gutterBottom className={classes.paddingSmall}>
            <Box mb={3}>Our Partners</Box>
          </Typography>
          <Grid container spacing={3} justify="center" alignItems="flex-start" className={` ${classes.imageSection}`} style={{margin: 0,width: '100%',}}>
            <Grid item xs={12} md={8}>
              <div className={classes.paddingSmall}>
                {
                  agencies.map((item, idx) => 
                    <Box mb={2} key={idx}>
                      <Grid container justify="center">
                        <Grid item xs={12} md={3}>
                          <Typography variant="body2">
                            <b>{item.name}</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <a className={classes.link} href={item.link}>
                            {item.link}
                          </a>
                        </Grid>
                      </Grid>
                    </Box>
                  )
                }
              </div>
            </Grid>
            <Grid item xs={12} md={4} className={classes.imageContainer}> 
              <Paper className={`${classes.image}`} style={{ backgroundImage: `url(${bodyImage4})` }}>
              </Paper>
            </Grid>
          </Grid>  
        </section> */}
        {/** End of Section 7 */}
        
      </Grid>
    </Grid>
    </div>
    </Container>
    
    {/** Success Stories - DISABLED */}
    {/* <Grid container spacing={3} className={`${classes.section7Container} ${classes.section} ${classes.paddingSmall}`} justify="center" alignItems="center" style={{margin: 0,width: '100%',}}>
      <Grid item xs={12}>
        <Typography variant="h3" color="secondary" align="center" gutterBottom>
          <Box mb={3}>
            Success Stories
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} justify="center" alignItems="center" style={{margin: 0,width: '100%',}}>
          <Carousel />
        </Grid>
      </Grid>
    </Grid> */}
    {/** End of Success Stories */}
  </>;
};

export default ProgramInfo;