import React, {useLayoutEffect} from "react";
import { Typography, useMediaQuery, Box, Container, Grid, Button, IconButton } from "@mui/material";
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from './useAuth';
import { useTranslation, Trans } from "react-i18next";
import Popup from './Popup';
import Signup from "../pages/Signup";

const useStyles = makeStyles((theme) => ({
    overlayContainer: {
        backgroundColor: 'black',
        position: 'fixed',
        opacity: '0.9',
        zIndex: 998,
        bottom: 0,
        width: '100%',
        padding: theme.spacing(1, 0),
    },
    overlayWrapper: {
        padding: theme.spacing(1, 4),
        [theme.breakpoints.down('md')]: {
            padding: '0px 8px',
        }
    },
    buttonRoot: {
        textTransform: 'none',
        borderRadius: '0.8rem',
        padding: '4px 24px'
    },
    closeIcon: {
      color: 'white',
      marginLeft: theme.spacing(1),
      cursor: 'pointer'
    },
}));

const BottomBar = () => {
    const classes = useStyles();
    const [overlay, setOverlay] = React.useState(true);
    const [openPopup, setOpenPopup] = React.useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { user, token } = useAuth();
    const { t } = useTranslation('common');

    const closeOverlay = () => {
        setOverlay(!overlay);
    }

    const handleClick = () => {
        if (token) {
            navigate('/dashboard');
        } else {
            setOpenPopup(true);
            navigate('/signup', {
                state: { background: location, goTo: '/dashboard' }
            });
        }
    }

    const hideBottomBar = location.pathname.startsWith('/dashboard') || location.pathname.startsWith('/assessment');

    if (hideBottomBar) {
        return null;
    }

    return (
      <div>
        {overlay ? (
          <div className={`${classes.overlayContainer}`} >
            <Container maxWidth="lg">
              <div className={classes.overlayWrapper}>
                <Grid container alignItems="center" justifyContent="space-between">
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body1" style={{color: 'white'}}>
                      {t('floatingBanner.text1')}
                    </Typography>
                    <Typography variant="body1" color="primary" gutterBottom>
                      <Trans i18nKey="common:floatingBanner.text2">
                        Take the <b>FREE</b> assessment to find out.
                      </Trans>
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Box display="flex">
                      <Button 
                        size="small" 
                        color="primary" 
                        variant="contained" 
                        disableElevation 
                        className={classes.buttonRoot}
                        onClick={handleClick}
                      >
                        <Typography variant="h5">
                          {t('buttons.startAssessment')}
                        </Typography>
                      </Button>
                      <CloseIcon fontSize="small" onClick={closeOverlay} className={classes.closeIcon}/>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </Container>      
          </div>
        ) : null}
      </div>
    );
}

export default BottomBar;
