import React from "react";
import { 
  Grid, 
  Typography, 
  Box,
  Button,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import { renderText } from '../renderText';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    listItem: {
        listStyle: 'none',
        border: `1px solid ${theme.palette.secondary.main}`,
        borderBottom: 0,
        padding: theme.spacing(2),

        [theme.breakpoints.down('md')]: {
            borderRadius: '1rem',
            border: 'none',
            backgroundColor: '#E8EDEF',
            marginBottom: theme.spacing(3),
        }
    },  
    buttonRoot: {
        textTransform: 'none',
        borderRadius: '0.8rem',
        padding: '4px 24px',
        textAlign:'center'
    },
    textListItem: {
        border: 'none'
    }
}));

const Item = ({ item }) => {
    const { t } = useTranslation('common');
    const { title, body, link, linkTitle = t('buttons.viewWebsite'), internalLink = '', listItems = [], body1 = '', province = '' } = item;
    const classes = useStyles();

    return (
        <Box className={classes.listItem} component="li">
            <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ margin: 0, width: '100%' }}>
                <Grid item xs={12} sm={3}>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb={1}>
                        {province &&
                            <Typography gutterBottom align="center" color="secondary" variant="h4">
                                <b>{province}</b>
                            </Typography>
                        }
                        {title &&
                            <Box py={1}>
                                <Typography variant="h4" component="h5" align="center" gutterBottom>
                                    <b>{title}</b>
                                </Typography>
                            </Box>
                        }
                        {link &&
                            <Button
                                color="primary"
                                size="small"
                                variant="contained"
                                target="_blank"
                                href={link}
                                disableElevation
                                className={classes.buttonRoot}
                            >
                                <Typography variant="h5">{linkTitle}</Typography>
                            </Button>
                        }
                        {internalLink &&
                            <Button
                                color="primary"
                                size="small"
                                variant="contained"
                                component={Link}
                                to={internalLink}
                                disableElevation
                                className={classes.buttonRoot}
                            >
                                <Typography variant="h5">{linkTitle}</Typography>
                            </Button>
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Typography variant="body1" align="left">
                        {renderText(body)}
                    </Typography>
                    {listItems.length > 0 && (
                        listItems.map((item, idx) => (
                            <Box key={idx} className={classes.textListItem} my={1}>
                                <Typography variant="body1" align="left" gutterBottom>
                                    - {item}
                                </Typography>
                            </Box>
                        ))
                    )}
                    {body1 &&
                        <Typography variant="body1" align="left">
                            {renderText(body1)}
                        </Typography>
                    }
                </Grid>
            </Grid>
        </Box>
    );
}

export default Item;
