export const provinces = [
    {
        id: 'CA-AB',
        label: 'Alberta',
        code:'CA-AB',
    },
    {
        id: 'CA-BC',
        label: 'British Columbia',
        code:'CA-BC',
    },
    {
        id: 'CA-MB',
        label: 'Manitoba',
        code:'CA-MB',
    },
    {
        id: 'CA-NB',
        label: 'New Brunswick',
        code:'CA-NB',
    },
    {
        id: 'CA-NL',
        label: 'Newfoundland and Labrador',
        code:'CA-NL',
    },
    {
        id: 'CA-NS',
        label: 'Nova Scotia',
        code:'CA-NS',
    },
    {
        id: 'CA-NT',
        label: 'Northwest Territories',
        code:'CA-NT',
    },
    {
        id: 'CA-NU',
        label: 'Nunavut',
        code:'CA-NU',
    },
    {
        id: 'CA-ON',
        label: 'Ontario',
        code:'CA-ON',
    },
    {
        id: 'CA-PE',
        label: 'Prince Edward Island',
        code:'CA-PE',
    },
    {
        id: 'CA-QC',
        label: 'Quebec',
        code:'CA-QC',
    },
    {
        id: 'CA-SK',
        label: 'Saskatchewan',
        code:'CA-SK',
    },
    {
        id: 'CA-YT',
        label: 'Yukon',
        code:'CA-YT',
    }
]

export const getProvinceFromId = (provinceId) => {
    const result = provinces.find(item => item.id === provinceId);
    return result;
}

export const getProvinceFromlabel = (province) => {
    const result = provinces.find(item => item.label === province);
    return result;
}

export const getProvinceFromIndex = (index) => {
    const result = provinces[index];
    return result;
}

export const getIndexFromProvinceId = (provinceId) => {
    const result = provinces.findIndex(item => item.id === provinceId);
    return result;
}