// src/utils/common.js
import { isPlainObject } from 'lodash/lang';

/**
 * Convert an object to a query string.
 * @param {object} obj - The object to convert.
 * @returns {string} - The query string.
 */
const objToQuery = obj => Object.entries(obj)
  .filter((entry) => typeof entry[1] !== 'undefined')
  .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
  .join('&');

/**
 * Normalize a URL by removing a trailing '?' if present.
 * @param {string} url - The URL to normalize.
 * @returns {string} - The normalized URL.
 */
const normalizeUrl = url => (url.slice(-1) === '?' ? url.slice(0, url.length - 1) : url);

/**
 * Build a full URL with query parameters.
 * @param {string} url - The base URL.
 * @param {object} query - The query parameters.
 * @returns {string} - The full URL with query parameters.
 */
export const buildUrl = (url, query) => normalizeUrl(`${process.env.REACT_APP_SERVER_BASE_URL}${url}?${objToQuery(query)}`);

/**
 * Convert a snake_case string to camelCase.
 * @param {string} v - The snake_case string.
 * @returns {string} - The camelCase string.
 */
export const toCamelCase = v => v.replace(/\B_[a-z0-9]/g, m => m[1].toUpperCase());

/**
 * Recursively convert all keys in a snake_case object to camelCase.
 * @param {object|array} data - The snake_case data.
 * @returns {object|array} - The camelCase data.
 */
export const snakeToCamel = data => {
  if (Array.isArray(data)) {
    return data.map(item => snakeToCamel(item));
  } else if (isPlainObject(data)) {
    const ret = {};
    for (let [k, v] of Object.entries(data)) {
      ret[toCamelCase(k)] = snakeToCamel(v);
    }
    return ret;
  }
  return data;
};

/**
 * Convert a camelCase string to snake_case.
 * @param {string} v - The camelCase string.
 * @returns {string} - The snake_case string.
 */
export const toSnakeCase = v => v.replace(/\B([a-z0-9])[A-Z]/g, m => m[0] + '_' + m[1].toLowerCase());

/**
 * Recursively convert all keys in a camelCase object to snake_case.
 * @param {object|array} data - The camelCase data.
 * @returns {object|array} - The snake_case data.
 */
export const camelToSnake = data => {
  if (Array.isArray(data)) {
    return data.map(item => camelToSnake(item));
  } else if (isPlainObject(data)) {
    const ret = {};
    for (let [k, v] of Object.entries(data)) {
      ret[toSnakeCase(k)] = camelToSnake(v);
    }
    return ret;
  }
  return data;
};

/**
 * Exclude null or undefined values from an object.
 * @param {object} data - The object to process.
 * @returns {object} - The object without null or undefined values.
 */
export const excludeObjNullValue = data => {
  const ret = {};
  for (let [k, v] of Object.entries(data)) {
    if (v !== null && v !== undefined) {
      ret[k] = v;
    }
  }
  return ret;
};
