import React from "react";
import CustomSelect from "../../components/CustomSelect";
import i18n from "../../i18n"; // Import your i18n configuration

const LanguageSelect = ({
  language,
  handleLanguageChange,
  languageOptions,
}) => {
  return (
    <CustomSelect
      name="selectLanguage"
      value={language}
      onChange={handleLanguageChange} // Pass the handler directly
      options={languageOptions}
      hideNoneOption={true}
    />
  );
};

export default LanguageSelect;