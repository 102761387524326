import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// import Backend from 'i18next-http-backend';
import LanguageDetector from "i18next-browser-languagedetector";
import { surveyLocalization } from "survey-core";

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
import homeEN from "./locales/en/homeEN.json";
import homeFR from "./locales/fr/homeFR.json";
import commonEN from "./locales/en/commonEN.json";
import commonFR from "./locales/fr/commonFR.json";
import errorEN from "./locales/en/errorEN.json";
import errorFR from "./locales/fr/errorFR.json";
import programInfoEn from "./locales/en/programInfoEN.json";
import programInfoFR from "./locales/fr/programInfoFR.json";
import intlWeldersEN from "./locales/en/intlWeldersEN.json";
import intlWeldersFR from "./locales/fr/intlWeldersFR.json";
import provinceToProvinceEN from "./locales/en/provinceToProvinceEN.json";
import provinceToProvinceFR from "./locales/fr/provinceToProvinceFR.json";
import weldingTrainingEN from "./locales/en/weldingTrainingEN.json";
import weldingTrainingFR from "./locales/fr/weldingTrainingFR.json";
import assessmentEN from "./locales/en/assessmentEN.json";
import assessmentFR from "./locales/fr/assessmentFR.json";
import careersEN from "./locales/en/careersEN.json";
import careersFR from "./locales/fr/careersFR.json";
import faqEN from "./locales/en/faqEN.json";
import faqFR from "./locales/fr/faqFR.json";
import termsEN from "./locales/en/termsEN.json";
import termsFR from "./locales/fr/termsFR.json";
import aboutEN from "./locales/en/aboutEN.json";
import aboutFR from "./locales/fr/aboutFR.json";
import contactEN from "./locales/en/contactEN.json";
import contactFR from "./locales/fr/contactFR.json";
import privacyEN from "./locales/en/privacyEN.json";
import privacyFR from "./locales/fr/privacyFR.json";
import resultEN from "./locales/en/resultEN.json";
import resultFR from "./locales/fr/resultFR.json";
import startAssessmentEN from "./locales/en/startAssessmentEN.json";
import startAssessmentFR from "./locales/fr/startAssessmentFR.json";
import regulationEN from "./locales/en/regulationEN.json";
import regulationFR from "./locales/fr/regulationFR.json";
import dashboardEN from "./locales/en/dashboardEN.json";
import dashboardFR from "./locales/fr/dashboardFR.json";
import dashboardUK from "./locales/uk/dashboardUK.json";
import previousprogramsEN from "./locales/en/previousprogramsEN.json";
import previousprogramsFR from "./locales/fr/previousprogramsFR.json";
import previousprogramsUK from "./locales/uk/previousprogramsUK.json";

import myAccountEN from "./locales/en/myAccountEN.json";
import myAccountFR from "./locales/fr/myAccountFR.json";
import myAccountUK from "./locales/uk/myAccountUK.json";

import homeUK from "./locales/uk/homeUK.json";
import commonUK from "./locales/uk/commonUK.json";
import errorUK from "./locales/uk/errorUK.json";
import programInfoUK from "./locales/uk/programInfoUK.json";
import intlWeldersUK from "./locales/uk/intlWeldersUK.json";
import provinceToProvinceUK from "./locales/uk/provinceToProvinceUK.json";
import weldingTrainingUK from "./locales/uk/weldingTrainingUK.json";
import assessmentUK from "./locales/uk/assessmentUK.json";
import careersUK from "./locales/uk/careersUK.json";
import faqUK from "./locales/uk/faqUK.json";
import termsUK from "./locales/uk/termsUK.json";
import aboutUK from "./locales/uk/aboutUK.json";
import contactUK from "./locales/uk/contactUK.json";
import privacyUK from "./locales/uk/privacyUK.json";
import resultUK from "./locales/uk/resultUK.json";
import startAssessmentUK from "./locales/uk/startAssessmentUK.json";
import regulationUK from "./locales/uk/regulationUK.json";

// const DETECTION_OPTIONS = {
//     order: ['localStorage', 'navigator'],
//     caches: ['localStorage']
// }

const DETECTION_OPTIONS = {
  order: ["querystring", "localStorage", "navigator"],
  lookupQuerystring: "selectLanguage", // Adjust to match your querystring parameter name
  caches: ["localStorage"],
};

const resources = {
  en: {
    home: homeEN,
    common: commonEN,
    error: errorEN,
    programInfo: programInfoEn,
    intlWelders: intlWeldersEN,
    provinceToProvince: provinceToProvinceEN,
    weldingTraining: weldingTrainingEN,
    assessment: assessmentEN,
    careers: careersEN,
    faq: faqEN,
    terms: termsEN,
    about: aboutEN,
    contact: contactEN,
    privacy: privacyEN,
    result: resultEN,
    startAssessment: startAssessmentEN,
    regulation: regulationEN,
    dashboard: dashboardEN,
    myAccount: myAccountEN,
    previousprograms: previousprogramsEN,
  },
  fr: {
    home: homeFR,
    common: commonFR,
    error: errorFR,
    programInfo: programInfoFR,
    intlWelders: intlWeldersFR,
    provinceToProvince: provinceToProvinceFR,
    weldingTraining: weldingTrainingFR,
    assessment: assessmentFR,
    careers: careersFR,
    faq: faqFR,
    terms: termsFR,
    about: aboutFR,
    contact: contactFR,
    privacy: privacyFR,
    result: resultFR,
    startAssessment: startAssessmentFR,
    dashboard: dashboardFR,
    regulation: regulationFR,
    myAccount: myAccountFR,
    previousprograms: previousprogramsFR,
  },
  ua: {
    home: homeUK,
    common: commonUK,
    error: errorUK,
    programInfo: programInfoUK,
    intlWelders: intlWeldersUK,
    provinceToProvince: provinceToProvinceUK,
    weldingTraining: weldingTrainingUK,
    assessment: assessmentUK,
    careers: careersUK,
    faq: faqUK,
    terms: termsUK,
    about: aboutUK,
    contact: contactUK,
    privacy: privacyUK,
    result: resultUK,
    startAssessment: startAssessmentUK,
    regulation: regulationUK,
    previousprograms: previousprogramsUK,
    dashboard: dashboardUK,
    myAccount: myAccountUK,
  },
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  //   .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    // lng:"en",
    detection: DETECTION_OPTIONS,
    supportedLngs: ["en", "fr", "ua"],
    fallbackLng: "en",
    debug: false,
    load: "languageOnly",
    nonExplicitSupportedLngs: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

surveyLocalization.currentLocale = i18n.language;

i18n.on("languageChanged", (lng) => {
  surveyLocalization.currentLocale = lng;
});

export default i18n;
