import React from 'react'
import { Box } from '@mui/material';
import ReferenceSection from './ReferenceSection';
import ContactSection from './ContactSection';
import Overview from './Overview';
import CofQ from './CofQ';

const MapResult = ({mapType, province}) => {
    if(province) {
        if (mapType === 'References') {
            return <Box mb={3} px="12px"><ReferenceSection province={province}/></Box>
        } else if (mapType === 'Contacts'){
            return  <Box mb={3} px="12px"><ContactSection province={province} /></Box>
        } else if (mapType === 'Certification') {
            return   <Box mb={3} px="12px"><CofQ province={province}/></Box>
        } else if (mapType === 'Overview') {
            return  <Box mb={3} px="12px"><Overview province={province}/></Box>
        }else {
            return null;
        }
    } else {
        return null;
    }  
}

export default MapResult
