import React from 'react';
import { Typography, Box, Button, FormControlLabel, Checkbox, DialogActions } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';

const useStyles = makeStyles((theme) => ({
    buttonRoot: {
        textTransform: 'none',
        borderRadius: '0.8rem',
        padding: '4px 24px',
    },
}));

const CookieConsent = ({ setOpen }) => {
    const classes = useStyles();
    const { t } = useTranslation('common');
    const [preferences, setPreferences] = React.useState({
        essential: true,
        analytics: false,
        marketing: false,
    });

    React.useEffect(() => {
        const savedPreferences = Cookies.get('cookie-consent');
        if (savedPreferences) {
            setPreferences(JSON.parse(savedPreferences));
            setOpen(false);
        }
    }, [setOpen]);

    const handleChange = (event) => {
        setPreferences({
            ...preferences,
            [event.target.name]: event.target.checked,
        });
    };

    const handleConsent = () => {
        Cookies.set('cookie-consent', JSON.stringify(preferences), { expires: 90 });
        setOpen(false);
    };

    return (
        <Box>
            <Typography variant="heading" id="cookie-consent-dialog" gutterBottom>{t('cookieModal.title')}</Typography>
            <Typography variant="body2" gutterBottom>
                {t('cookieModal.text')}
            </Typography>
            <FormControlLabel
                control={<Checkbox checked={preferences.essential} name="essential" onChange={handleChange} disabled />}
                label={t('cookieModal.essential')} sx={{ '.MuiSvgIcon-root': { fill: 'black' } }}
            />
            <FormControlLabel
                control={<Checkbox checked={preferences.analytics} name="analytics" onChange={handleChange} />}
                label={t('cookieModal.analytics')} sx={{ '.MuiSvgIcon-root': { fill: 'black' } }}
            />
            <FormControlLabel
                control={<Checkbox checked={preferences.marketing} name="marketing" onChange={handleChange} />}
                label={t('cookieModal.marketing')} sx={{ '.MuiSvgIcon-root': { fill: 'black' } }}
            />
            <DialogActions>
                <Button 
                    color="primary" 
                    size="medium" 
                    variant="contained" 
                    disableElevation
                    className={classes.buttonRoot}
                    onClick={handleConsent}
                >
                    {t('cookieModal.buttonText')}
                </Button>
            </DialogActions>
        </Box>
    );
}

export default CookieConsent;
