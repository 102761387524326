import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Checkbox,
  FormGroup,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  OutlinedInput,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm, Form } from "./../../components/useForm";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Countries from "./countries";
import axios from "axios";
import { useAuth } from "../../components/useAuth";

const initialValues = {
  weldingExperience: "",
  totalExperience: "",
  experienceInCanada: 0,
  experienceInOtherCountries: 0,
  formalEducation: "",
  additionalCertifications: "",
  employmentStatus: "",
  assessmentReason: "",
  notifyPrograms: false,
  selectedCountry: [],
  weldingformalTraining: "",
};

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    textTransform: "none",
    borderRadius: "0.8rem",
    padding: "4px 24px",
  },
}));

const SetupProfile = ({ onClose }) => {
  const { token } = useAuth();
  const { t } = useTranslation("common");
  const classes = useStyles();
  const [formError, setFormError] = useState("");
  const navigate = useNavigate();
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const validate = () => {
    let temp = {};
    temp.weldingExperience = values.weldingExperience
      ? ""
      : t("setupProfile.errors.required");
    temp.employmentStatus = values.employmentStatus
      ? ""
      : t("setupProfile.errors.required");
    temp.assessmentReason = values.assessmentReason
      ? ""
      : t("setupProfile.errors.required");

    setErrors({
      ...temp,
    });

    return Object.keys(temp).every((key) => temp[key] === "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormError("");

    if (validate()) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_BASE_URL}/update-profile`,
          values,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("Profile updated successfully:", response.data);
        onClose(); // Close the profile setup component
        navigate("/dashboard");
      } catch (error) {
        console.error("Error updating profile:", error);
        setFormError(t("setupProfile.errors.updateFailed"));
      }
    } else {
      console.log("Validation failed");
    }
  };

  useEffect(() => {
    const totalExperience =
      (parseInt(values.experienceInCanada, 10) || 0) +
      (parseInt(values.experienceInOtherCountries, 10) || 0);
    setValues((prevValues) => ({
      ...prevValues,
      totalExperience: totalExperience,
    }));
  }, [values.experienceInCanada, values.experienceInOtherCountries, setValues]);

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        {formError && (
          <Box my={3} p={1} bgcolor="secondary.light">
            <Typography variant="subtitle2" color="error">
              {formError}
            </Typography>
          </Box>
        )}
        <Grid container>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                color="text"
                sx={{ color: "text.primary" }}
              >
                {t("loginForm.fields.profile.previous_welding_experience")}
              </FormLabel>
              <RadioGroup
                row
                name="weldingExperience"
                value={values.weldingExperience || ""}
                onChange={handleInputChange}
              >
                <FormControlLabel
                  value="yes"
                  control={
                    <Radio color="secondary" sx={{ color: "text.primary" }} />
                  }
                  label={t("yes")}
                />
                <FormControlLabel
                  value="no"
                  control={
                    <Radio color="secondary" sx={{ color: "text.primary" }} />
                  }
                  label={t("no")}
                />
              </RadioGroup>
              {errors.weldingExperience && (
                <Typography variant="body2" color="error">
                  {errors.weldingExperience}
                </Typography>
              )}
            </FormControl>
          </Grid>
          {values.weldingExperience === "yes" && (
            <>
              <Grid item xs={12}>
                <FormLabel
                  sx={{ color: "text.primary", mt: 2, mb: 2 }}
                  color="secondary"
                >
                  {t("loginForm.fields.profile.total_welding_experience")}:{" "}
                  <strong>{values.totalExperience} {t("setupProfile.years")}</strong>
                </FormLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel sx={{ color: "text.primary" }} color="secondary">
                  {t("setupProfile.experienceInCanada")}
                </FormLabel>
                <TextField
                  label={t("setupProfile.yearsInCanada")}
                  variant="outlined"
                  name="experienceInCanada"
                  value={values.experienceInCanada || 0}
                  onChange={handleInputChange}
                  type="number"
                  inputProps={{ min: 0, max: 50 }}
                  color="secondary"
                  sx={{ "& .MuiInputLabel-root": { color: "tertiary.dark" } }}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel sx={{ color: "text.primary" }} color="secondary">
                  {t("setupProfile.experienceInOtherCountries")}
                </InputLabel>
                <TextField
                  label={t("setupProfile.yearsInOtherCountries")}
                  variant="outlined"
                  name="experienceInOtherCountries"
                  value={values.experienceInOtherCountries || 0}
                  onChange={handleInputChange}
                  type="number"
                  inputProps={{ min: 0, max: 50 }}
                  color="secondary"
                  sx={{ "& .MuiInputLabel-root": { color: "tertiary.dark" } }}
                  required
                />
              </Grid>
              {values.experienceInOtherCountries > "0" && (
                <Grid item xs={12}>
                  <FormControl sx={{ m: 1 }} variant="outlined">
                    <InputLabel
                      id="country-select-label"
                      sx={{ color: "tertiary.dark" }}
                      required
                      color="secondary"
                    >
                      {t("setupProfile.chooseCountries")}
                    </InputLabel>
                    <Select
                      label={t("setupProfile.chooseCountries")}
                      name="selectedCountry"
                      value={values.selectedCountry || []}
                      error={errors.selectedCountry}
                      labelId="country-select-label"
                      id="country-select"
                      onChange={handleSelectChange}
                      multiple
                      input={
                        <OutlinedInput label={t("setupProfile.chooseCountries")} />
                      }
                      renderValue={(selected) => selected.join(", ")}
                      color="secondary"
                      inputProps={{
                        MenuProps: {
                          PaperProps: {
                            sx: {
                              "& .Mui-selected": {
                                backgroundColor: "rgba(211, 211, 211, 0.6)",
                              },
                              "& .Mui-selected:hover": {
                                backgroundColor: "rgba(14, 137, 188, 0.3)",
                              },
                            },
                          },
                        },
                      }}
                    >
                      {Countries.map((country) => (
                        <MenuItem key={country.code} value={country.label}>
                          <Checkbox
                            sx={{ color: "text.primary" }}
                            color="secondary"
                            checked={
                              values.selectedCountry
                                ? values.selectedCountry.indexOf(
                                    country.label
                                  ) > -1
                                : false
                            }
                          />
                          <ListItemText
                            color="secondary"
                            primary={country.label}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </>
          )}
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                sx={{ color: "text.primary" }}
                color="text"
              >
                {t("setupProfile.formalEducation")}
              </FormLabel>
              <RadioGroup
                row
                name="weldingformalTraining"
                value={values.weldingformalTraining || ""}
                onChange={handleInputChange}
              >
                <FormControlLabel
                  value="yes"
                  control={
                    <Radio color="secondary" sx={{ color: "text.primary" }} />
                  }
                  label={t("yes")}
                />
                <FormControlLabel
                  value="no"
                  control={
                    <Radio color="secondary" sx={{ color: "text.primary" }} />
                  }
                  label={t("no")}
                />
              </RadioGroup>
              {errors.weldingformalTraining && (
                <Typography variant="body2" color="error">
                  {errors.weldingformalTraining}
                </Typography>
              )}
            </FormControl>
          </Grid>
          {values.weldingformalTraining === "yes" && (
            <Grid item xs={12}>
              <TextField
                label={t("setupProfile.additionalCertifications")}
                variant="outlined"
                name="additionalCertifications"
                value={values.additionalCertifications}
                onChange={handleInputChange}
                color="secondary"
                sx={{ "& .MuiInputLabel-root": { color: "tertiary.dark" } }}
                multiline
                rows={4}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                color="text"
                sx={{ color: "text.primary" }}
              >
                {t("setupProfile.employmentStatus")}
              </FormLabel>
              <RadioGroup
                row
                name="employmentStatus"
                value={values.employmentStatus || ""}
                onChange={handleInputChange}
              >
                <FormControlLabel
                  value="fullTime"
                  control={
                    <Radio color="secondary" sx={{ color: "text.primary" }} />
                  }
                  label={t("setupProfile.employedFullTime")}
                />
                <FormControlLabel
                  value="partTime"
                  control={
                    <Radio color="secondary" sx={{ color: "text.primary" }} />
                  }
                  label={t("setupProfile.employedPartTime")}
                />
                <FormControlLabel
                  value="unemployed"
                  control={
                    <Radio color="secondary" sx={{ color: "text.primary" }} />
                  }
                  label={t("setupProfile.unemployed")}
                />
                <FormControlLabel
                  value="student"
                  control={
                    <Radio color="secondary" sx={{ color: "text.primary" }} />
                  }
                  label={t("setupProfile.student")}
                />
                <FormControlLabel
                  value="other"
                  control={
                    <Radio color="secondary" sx={{ color: "text.primary" }} />
                  }
                  label={t("setupProfile.other")}
                />
              </RadioGroup>
              {errors.employmentStatus && (
                <Typography variant="body2" color="error">
                  {errors.employmentStatus}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                color="text"
                sx={{ color: "text.primary" }}
              >
                {t("setupProfile.assessmentReason")}
              </FormLabel>
              <RadioGroup
                row
                name="assessmentReason"
                value={values.assessmentReason || ""}
                onChange={handleInputChange}
              >
                <FormControlLabel
                  value="newToCanada"
                  control={
                    <Radio color="secondary" sx={{ color: "text.primary" }} />
                  }
                  label={t("setupProfile.newToCanada")}
                />
                <FormControlLabel
                  value="unemployedLooking"
                  control={
                    <Radio color="secondary" sx={{ color: "text.primary" }} />
                  }
                  label={t("setupProfile.unemployedLooking")}
                />
                <FormControlLabel
                  value="improveExperience"
                  control={
                    <Radio color="secondary" sx={{ color: "text.primary" }} />
                  }
                  label={t("setupProfile.improveExperience")}
                />
                <FormControlLabel
                  value="learnWelding"
                  control={
                    <Radio color="secondary" sx={{ color: "text.primary" }} />
                  }
                  label={t("setupProfile.learnWelding")}
                />
              </RadioGroup>
              {errors.assessmentReason && (
                <Typography variant="body2" color="error">
                  {errors.assessmentReason}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="notifyPrograms"
                    value={values.notifyPrograms}
                    onChange={handleInputChange}
                    color="secondary"
                    sx={{ color: "text.primary" }}
                  />
                }
                label={t("setupProfile.notifyPrograms")}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sx={{ mt: 3 }}>
            <Button
              color="primary"
              size="large"
              variant="contained"
              disableElevation
              className={classes.buttonRoot}
              type="submit"
              sx={{ fontSize: "1em", fontWeight: "bold" }}
            >
              {t("setupProfile.submitButton")}
            </Button>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};

export default SetupProfile;
