import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

const RedSeal = ({ mdCols = 3 }) => {
    const { t } = useTranslation('regulation');
    
    // Ensure mdCols is a number
    const cols = typeof mdCols === 'number' ? mdCols : 3;

    return (
        <Grid item xs={12} md={cols}>
            <Box p={1} maxWidth="210px" mx="auto" borderRadius="1rem" overflow="hidden" bgcolor="secondary.light">
                <Typography variant="body1" align="center" fontWeight="fontWeightBold" p={1}>
                    {t('pathways.redSeal')}
                </Typography>
                <Box p={1} border={1} bgcolor="common.white">
                    <Typography variant="body1" align="center">
                        <b>{t('pathways.certOfQualWelder')}</b>
                    </Typography>
                </Box>
            </Box>
        </Grid>
    );
};

export default RedSeal;
