import React from 'react';
import { Link } from '@mui/material';

export const renderText = txt => {
  const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;  
  const MAILTO_REGEX = /\S+@\S+\.\S+/;
  return txt
    .split(" ")
    .map((part, index) => {
      if (URL_REGEX.test(part)) {
        return (
          <Link
            key={index}
            style={{textDecoration: 'underline'}}
            color="secondary"
            href={part}
            target="_blank"
            underline="hover"
          >
            {part}{" "}
          </Link>
        );
      } else if (MAILTO_REGEX.test(part)) {
        return (
          <Link
            key={index}
            style={{textDecoration: 'underline'}}
            color="secondary"
            href={`mailto:${part}`}
            underline="hover"
          >
            {part}
          </Link>
        );
      } else {
        return part + " ";
      }
    });
}

export const renderMailTo = txt => {
  const re = /\S+@\S+\.\S+/;
  return txt
    .split(" ")
    .map(part => 
        re.test(part) ? <Link
          style={{textDecoration: 'underline'}}
          color="secondary"
          href={`mailto:${part}`}
          underline="hover">{part}</Link>: part + " "
    );
}