import React, {useState} from "react";
import { Grid, Typography, Container, Box, Table, TableRow, TableCell, Collapse, IconButton, TableBody
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {Link} from 'react-router-dom';
import {renderMailTo} from '../components/renderText';
import {Helmet} from "react-helmet";
import {useTranslation, Trans} from "react-i18next";
const useStyles = makeStyles((theme) => ({
    tableContainer: {
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: '1rem',
        overflow:'hidden'
        //paddingBottom: '12px',
    },
    table: {
        tableLayout: 'fixed',
        '& thead th': {
            wordWap: 'break-word',
            fontWeight: '600',
             color: theme.palette.secondary.main,
    
        },
        '& tbody td': {
            wordWrap: 'break-word',
            fontWeight: '300'
        },
        '& tbody tr:hover': {
       
        },
        '& .MuiTableCell-root':{
            borderBottom: 'none',
        },
        '& .MuiTableHead-root': {
            borderBottom: `1px solid ${theme.palette.secondary.main}`
        },
        '& .MuiTableRow-root': {
            borderBottom: `1px solid ${theme.palette.secondary.main}`
        },
        '& .MuiTableRow-root:nth-last-child(1)': {
            borderBottom: 'none'
        }
    },
    container: {
        // minHeight: '100vh',
        padding: theme.spacing(3),
        marginTop: theme.spacing(3) 
    },
    link: {
        textDecoration: 'underline',
        color: theme.palette.secondary.main
    }

}))

const useRowStyles = makeStyles((theme) => ({

    innerRow: {
        padding: theme.spacing(1, 0),
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
        backgroundColor: theme.palette.secondary.light,
    },
    wrapperInner: {
        '& .MuiGrid-root:nth-last-child(1)': {
            borderBottom: 'none'
        }
    }
  }));
function Row({item}) {
   const { title, body='', listTitle='', list=[] } = item;
   const [open, setOpen] = useState(false);
   const classes = useRowStyles();
   
   return <>
       
       <TableRow style={!open ? { borderBottom: 'none' }: {}}>
           <TableCell  colSpan={9} >
               <Typography align="left" variant="body1" color="textPrimary">
                   {title}
               </Typography>
           </TableCell>
           <TableCell align="right" colSpan={3} >
               <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                   {open ? <KeyboardArrowUpIcon color="secondary"/> : <KeyboardArrowDownIcon color="secondary"/>}
               </IconButton>
           </TableCell>
       </TableRow>
       <TableRow >
           <TableCell style={{ padding:0}} colSpan={12} >
               <Collapse in={open} timeout="auto" unmountOnExit classes={{wrapperInner: classes.wrapperInner}}> 
                       <Grid container justifyContent="space-between" alignItems="center" className={classes.innerRow}>
                           <Grid item xs={12}>
                               {
                                   body &&
                                   <Box px={2} my={1}>
                                       <Typography variant="body1" align="left">
                                           {renderMailTo(body)}
                                       </Typography>
                                   </Box>
                               }
                               {
                                   listTitle &&
                                   <Box px={2} my={1}>
                                       <Typography variant="body1" align="left">
                                           <b>{listTitle}</b>
                                       </Typography>
                                   </Box>
                               }
                               {
                                   list.length > 0 &&
                                   <Box px={2} my={1}>
                                   <ul>
                                       {
                                           list.map((item, idx) => 
                                           <li key={idx}><Typography variant="body1" align="left">{item}</Typography></li>
                                           )
                                       }
                                   </ul>
                                   </Box>
                               }
                               
                           </Grid>
                       </Grid>
               </Collapse>
           </TableCell>
       </TableRow>
   </>;

}



const Faq = () => {
    const classes = useStyles();
    const {t} = useTranslation('faq');
    return (
        <>
            <Helmet>
                <title>
                    {t('metaTitle')}
                </title>
                <meta name="description" content={t('metaDesc')} />
            </Helmet>
            <Container maxWidth="xl" className={classes.container}>
                <Box my={4}>
                    <Typography variant="h1" align="center" gutterBottom>
                        {t('title')}
                    </Typography>
                </Box>
                <Box my={4}>
                    <div className={classes.tableContainer}>
                        <Table className={classes.table}>
                            <TableBody>
                            {
                                t('items', {returnObjects:true}).map((item, idx) => 
                                    <Row item={item} key={idx}/>
                                )
                            }
                            </TableBody>
                        </Table>
                    </div>
                </Box>
                <Box my={4}>
                    <Typography color="secondary" variant="body1">
                            <b>
                                <Trans i18nKey="faq:bottomText">
                                Didn't see the question you want to ask? Go to our <Link className={classes.link} to="/contact-us">contact us</Link> page to email your questions.
                                </Trans>
                                
                            </b>
                    </Typography>
                </Box>
                
            </Container>
        </>
    );
};

export default Faq;