import React from 'react'
import { 
    Typography,Box,Button
} from '@mui/material';
import { makeStyles } from "@mui/styles";

import GetAppIcon from '@mui/icons-material/GetApp';
import {useTranslation} from "react-i18next";
const useStyles = makeStyles((theme) => ({
    list: {
        listStyle: 'lower-latin'
    }
}));
const NU = ({data}) => {
    const {downloadLink, title, text1, text2, listHeader1, list1, text3, listHeader2, list2, text4, downloadText} = data;
    const classes = useStyles();
    const { t } = useTranslation('regulation');
    return (
        <>
            <Box my={3}>
                <Box mb={3}>
                    <Typography variant="body1" align="left" gutterBottom>
                        <b>{title}</b>
                    </Typography>
                    <Typography variant="body1" align="left" gutterBottom>
                    {text1}
                    </Typography>
                </Box>
                <Box mb={3}>
                    <Typography variant="body1">
                        {text2}
                    </Typography>
                </Box>
                
                <Box mb={3}>
                    <Typography variant="body1" gutterBottom>
                    {listHeader1}
                    </Typography>
                    <ol className={classes.list}>
                        {
                            list1.map((item, idx) => (
                                <li key={idx}>
                                    <Typography variant="body1" gutterBottom>
                                    {item}
                                    </Typography>
                                </li>
                            ))
                        }
                    </ol>
                </Box>

                <Box mb={3}>
                    <Typography variant="body1">
                    {text3}
                    </Typography>
                </Box>

                <Box mb={3}>
                    <Typography variant="body1" gutterBottom>
                    {listHeader2}
                    </Typography>
                    <ol className={classes.list}>
                        {
                            list2.map((item, idx) => (
                                <li key={idx}>
                                    <Typography variant="body1" gutterBottom>
                                        {item}
                                    </Typography>
                                </li>
                            ))
                        }
                    </ol>
                </Box>

                <Box mb={3}>
                    <Typography variant="body1">
                    {text4}
                    </Typography>
                </Box>

                <Box my={3}>
                    <Typography variant="body1" gutterBottom>
                        {downloadText}
                    </Typography>
                    <Button
                        startIcon={<GetAppIcon />} 
                        color="primary" 
                        size="large"
                        variant="contained" 
                        disableElevation
                        href={downloadLink}
                        target="_blank"
                    >
                        <Typography variant="h5">
                        {t('section6.downloadText')}
                        </Typography>
                    </Button>
                </Box>

            </Box>
        </>
    )
}

export default NU
