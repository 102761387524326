import React, { useState } from "react";
import { NavLink as Link, useLocation, useNavigate } from "react-router-dom";
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Box,
  Button,
  //Typography,
  //Menu,
  //MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import { useAuth } from "../useAuth";
import iconCwbGroup from "../../assets/icons/icon-cwb-group.png";
import iconArrowDown from "../../assets/icons/icon-arrow-down.png";
import iconArrowUp from "../../assets/icons/icon-arrow-up.png";
//import AccountCircle from "@mui/icons-material/AccountCircle";
import { useTranslation } from "react-i18next";
import LanguageSelect from "./LanguageSelect";

const MobileMenu = (props) => {
  let currentLocation = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const { setOpenPopup, language, languageOptions, handleLanguageChange } =
    props;
  const [showNavItems, setShowNavItems] = useState(false);
  const [expandWelder, setExpandWelder] = useState(false);
  const [expandCareer, setExpandCareer] = useState(false);
  const [anchorElProfile, setAnchorElProfile] = React.useState(null);
  const { user, signout, token } = useAuth();
  const { t } = useTranslation("common");

  const handleOnClick = () => setShowNavItems(!showNavItems);
  const handleClose = () => setShowNavItems(false);

  // const handleMenu = (event) => {
  //   setAnchorElProfile(event.currentTarget);
  // };
  // const handleprofileClose = () => {
  //   setAnchorElProfile(null);
  // };

  return (
    <div className={classes.root}>
      <Box display="grid" gridTemplateColumns={"1fr 1fr 1fr"}>
        <IconButton
          className={classes.menuButton}
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleOnClick}
          size="large"
          sx={{ justifyContent: "flex-start" }}
        >
          <MenuIcon />
        </IconButton>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link to="/" onClick={handleClose} style={{ paddingTop: "5px" }}>
            <img className={classes.logo} src={iconCwbGroup} alt="CWB Logo" />
          </Link>
        </div>
      </Box>

      {showNavItems && (
        <div className={classes.menu}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
            }}
          >
            <List className={classes.list}>
              {/* <ListItem
                button
                component={Link}
                to="/"
                onClick={handleClose}
                className={classes.listItem}
              >
                <HomeIcon fontSize="large" />
              </ListItem>

              <ListItem
                button
                component={Link}
                to="/welder-competency-credential-assessment-program-information"
                onClick={handleClose}
                className={classes.listItem}
              >
                <ListItemText
                  primary={t("topMenu.header1.title")}
                  className={`${classes.listItemText}`}
                />
              </ListItem> */}

              <ListItem
                button
                alignItems="center"
                onClick={() => setExpandWelder(!expandWelder)}
                className={classes.listItem}
              >
                <ListItemText
                  primary={t("topMenu.header2.title")}
                  className={`${classes.listItemText}`}
                />
                {expandWelder ? (
                  <IconButton size="small">
                    <img
                      className={classes.iconArrow}
                      src={iconArrowUp}
                      alt="arrow up"
                    />
                  </IconButton>
                ) : (
                  <IconButton size="small">
                    <img
                      className={classes.iconArrow}
                      src={iconArrowDown}
                      alt="arrow down"
                    />
                  </IconButton>
                )}
              </ListItem>
              <Collapse
                in={expandWelder}
                unmountOnExit
                className={classes.collapse}
              >
                <List
                  component="div"
                  disablePadding
                  className={classes.expandedList}
                >
                  <ListItem
                    button
                    className={`${classes.nested} ${classes.expandedListItem}`}
                    component={Link}
                    to="/international-welder-requirements-work-canada"
                    onClick={handleClose}
                  >
                    <ListItemText
                      primary={t("topMenu.header2.subHeader1")}
                      className={`${classes.listItemText}`}
                    />
                  </ListItem>

                  <ListItem
                    button
                    className={`${classes.nested} ${classes.expandedListItem}`}
                    component={Link}
                    to="/welder-requirements-across-canada"
                    onClick={handleClose}
                  >
                    <ListItemText
                      primary={t("topMenu.header2.subHeader2")}
                      className={`${classes.listItemText}`}
                    />
                  </ListItem>

                  {/* <ListItem
                    button
                    className={`${classes.nested} ${classes.expandedListItem}`}
                    component={Link}
                    to="/welding-training-canada"
                    onClick={handleClose}
                  >
                    <ListItemText
                      primary={t("topMenu.header2.subHeader3")}
                      className={`${classes.listItemText}`}
                    />
                  </ListItem> */}
                </List>
              </Collapse>

              <ListItem
                button
                component={Link}
                to="/welders-certifications-requirements-canada"
                onClick={handleClose}
                className={classes.listItem}
              >
                <ListItemText
                  primary={t("topMenu.header3.title")}
                  className={`${classes.listItemText}`}
                />
              </ListItem>

              {/* <ListItem
                button
                component={Link}
                to="/welder-competency-credential-assessment-information"
                onClick={handleClose}
                className={classes.listItem}
              >
                <ListItemText
                  primary={t("topMenu.header4.title")}
                  className={`${classes.listItemText}`}
                />
              </ListItem> */}

              <ListItem
                button
                alignItems="center"
                onClick={() => setExpandCareer(!expandCareer)}
                className={classes.listItem}
              >
                <ListItemText
                  primary={t("topMenu.header5.title")}
                  className={`${classes.listItemText}`}
                />
                {expandCareer ? (
                  <IconButton size="small">
                    <img
                      className={classes.iconArrow}
                      src={iconArrowUp}
                      alt="arrow up"
                    />
                  </IconButton>
                ) : (
                  <IconButton size="small">
                    <img
                      className={classes.iconArrow}
                      src={iconArrowDown}
                      alt="arrow down"
                    />
                  </IconButton>
                )}
              </ListItem>
              <Collapse
                in={expandCareer}
                unmountOnExit
                className={classes.collapse}
              >
                <List
                  component="div"
                  disablePadding
                  className={classes.expandedList}
                >
                  <ListItem
                    button
                    className={`${classes.nested} ${classes.expandedListItem}`}
                    component={Link}
                    to="/search-welding-jobs-canada"
                    onClick={handleClose}
                  >
                    <ListItemText
                      primary={t("topMenu.header5.subHeader1")}
                      className={`${classes.listItemText}`}
                    />
                  </ListItem>
                </List>
              </Collapse>

              <ListItem
                button
                component={Link}
                to="/previous-programs"
                onClick={handleClose}
                className={classes.listItem}
              >
                <ListItemText
                  primary={t("topMenu.header6.title")}
                  className={`${classes.listItemText}`}
                />
              </ListItem>

              <ListItem onClick={handleClose} className={classes.listItem}>
                {token ? (
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    className={classes.buttonRoot}
                    onClick={signout}
                  >
                    <b>{t("topMenu.logoutBtn")}</b>
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    className={classes.buttonRoot}
                    onClick={() => {
                      setOpenPopup(true);
                      navigate("/signup", {
                        state: { background: currentLocation },
                      });
                    }}
                  >
                    <b>{t("topMenu.signupBtn")}</b>
                  </Button>
                )}
              </ListItem>

              <ListItem className={classes.listItem}>
                <LanguageSelect
                  language={language}
                  handleLanguageChange={handleLanguageChange}
                  languageOptions={languageOptions}
                />
              </ListItem>
            </List>
          </Box>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  collapse: {
    width: "100%",
  },
  list: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  listItem: {
    width: "auto",
    padding: theme.spacing(2, 0),
  },
  listItemText: {
    "& span": {
      fontSize: "24px",
      fontWeight: "bold",
    },
  },
  expandedList: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "white",
  },
  expandedListItem: {
    width: "auto",
    padding: theme.spacing(1, 0),
  },
  menu: {
    backgroundColor: "#F2F2F2",
    position: "absolute",
    width: "100%",
    padding: "15px",
    overflow: "auto",
    height: "100vh",
    top: "100%",
    left: "0",
    zIndex: 999,
    boxShadow: "0 3px 10px rgb(0 0 0 / 20%)",
  },
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: "white",
    color: "black",
  },
  menuButton: {
    "&:focus": {
      outline: "none",
    },
  },
  title: {
    flexGrow: 1,
  },
  link: {
    color: "black",
    textDecoration: "none",
    margin: theme.spacing(2, 1.5),
    padding: theme.spacing(2, 2),
    "&:hover": {
      textDecoration: "none",
      color: "black",
    },
  },
  activeLink: {
    borderBottom: "0.4rem solid black",
  },
  logo: {
    height: "50px",
    width: "50px",
  },
  iconArrow: {
    width: "20px",
    height: "20px",
  },
  buttonRoot: {
    textTransform: "none",
    borderRadius: "0.8rem",
    padding: "4px 8px",
    margin: theme.spacing(0, 1.5),
    fontSize: "24px",
  },
  language: {
    color: theme.palette.secondary.main,
    fontSize: "20px",
  },
}));

export default MobileMenu;
